import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    FullPart,
    OtsFullPart,
    PartEmissionData,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { EmissionsView, formatEmission } from './EmissionsView';
import { findMinMaxEmissions } from './findMinMaxEmissions';

/**
 *
 * @returns a JSX.Element with the carbon footprint of a given part which can be a single value or a range.
 *   If quantity is given, the return value will be multiplied by it.
 */
export const PartEmissionsView = ({ part, quantity }: { part: FullPart; quantity?: number }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return <OtsPartEmissionsView emissionsData={part.emissions_data} quantity={quantity} />;
    }
    if (isGenericFullPart(part)) {
        return <OtsFullPartMatchesEmissionsView matches={part.matches} quantity={quantity} />;
    }
    if (isOtsComponentFull(part)) {
        const matches: OtsFullPart[] = Array.from(part.matches).flatMap((match) => {
            if (match.part.type === 'Generic') {
                return match.part.data.matches;
            }
            if (match.part.type === 'OffTheShelf') {
                return match.part.data;
            }
            return [];
        });
        return <OtsFullPartMatchesEmissionsView matches={matches} quantity={quantity} />;
    }
    if (isCustomFullPart(part)) {
        return <>-</>;
    }
    if (isCustomComponentFull(part)) {
        return <>-</>;
    }
    assertUnreachable(part);
};

const OtsPartEmissionsView = ({ emissionsData, quantity }: { emissionsData: PartEmissionData; quantity?: number }) => {
    const emissiontType = emissionsData.type;
    if (emissiontType === 'NotAvailable') {
        return <Trans>N/A</Trans>;
    }
    if (emissiontType === 'NotFetched' || emissiontType === 'Requested') {
        // The data should be visible but we don't have it yet, so indicate that it's been requested
        return <Trans>Requested</Trans>;
    }
    if (emissiontType === 'Available') {
        const partEmissions = emissionsData.data.product_phase_gwp_in_kg_co2e;
        const totalEmissions = quantity ? Number(partEmissions) * quantity : partEmissions;

        return <>{formatEmission(totalEmissions)}</>;
    }
    if (emissiontType === 'NotEnabled') {
        return <>-</>;
    }
    assertUnreachable(emissiontType);
};

const OtsFullPartMatchesEmissionsView = ({ matches, quantity }: { matches: OtsFullPart[]; quantity?: number }) => {
    if (matches.length === 0) {
        return <>-</>;
    }
    if (matches.length === 1) {
        return <OtsPartEmissionsView emissionsData={matches[0].emissions_data} quantity={quantity} />;
    }

    const { min: minPartEmissions, max: maxPartEmissions } = findMinMaxEmissions({ parts: matches });
    if (!minPartEmissions || !maxPartEmissions) {
        return <>-</>;
    }
    const minTotalEmissions = quantity ? Number(minPartEmissions) * quantity : minPartEmissions;
    const maxTotalEmissions = quantity ? Number(maxPartEmissions) * quantity : maxPartEmissions;
    return <EmissionsView min={minTotalEmissions} max={maxTotalEmissions} />;
};

import { uniqBy } from '@luminovo/commons';
import { NegotiationLineItem, NegotiationPart } from '../hooks/queries';

export function getNegotiationLineItemParts(lineItem: NegotiationLineItem): NegotiationPart[] {
    return uniqBy(
        [
            ...lineItem.part_option_component,
            ...lineItem.part_option_off_the_shelf,
            ...lineItem.part_option_custom,
            ...lineItem.part_option_generic,
            ...lineItem.part_option_component_custom,
        ],
        (p) => ('id' in p ? p.id : p.ipn_id),
    );
}

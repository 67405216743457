import { Trans, t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { formatLongDateTime, formatRelativeTime, isPresent, pick, transEnum } from '@luminovo/commons';
import {
    Flexbox,
    Link,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    createColumnHelper,
    generateFilterRequest,
    getOptionCount,
    useTanStackTablePagination,
    useTanStackTableState,
} from '@luminovo/design-system';
import {
    AssemblyIndustry,
    AssemblyOverviewAggregationDTO,
    AssemblyOverviewDTO,
    AssemblyOverviewFilterRequest,
    AssemblyOverviewFilterRequestRuntype,
    BareIpnIdentifier,
    CustomerDTO,
    http,
} from '@luminovo/http-client';
import { assemblyIndustryTranslations } from '@luminovo/manufacturing-core';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDevFeatureFlags } from '../../devFeatureFlags';
import { useCustomers } from '../../resources/customer/customerHandler';
import { httpQueryKey } from '../../resources/http/httpQueryKey';
import { useHasCustomers } from '../../utils/featureFlags';
import { route } from '../../utils/routes';
import { ButtonsForMocks } from '../SupplierManagement/components/mocks/buttons';

type AssemblyOverviewTableSharedContext = {
    customers: CustomerDTO[];
    aggregations?: AssemblyOverviewAggregationDTO[];
};

export default function AssemblyOverviewTable() {
    const { hasCustomers } = useHasCustomers();
    const { data: customers = [] } = useCustomers();
    const history = useHistory();

    const { tableState } = useTanStackTableState({
        columnsKey: 'assembly-overview-table',
        enableColumnOrdering: true,
        enableColumnHiding: true,
    });

    const columns = React.useMemo(() => {
        return hasCustomers ? [customerColumn, ...defaultColumns] : defaultColumns;
    }, [hasCustomers]);

    const {
        data: infinitData,
        fetchNextPage,
        isFetchingNextPage,
    } = useAssemblyOverview(generateFilterRequest(tableState, columns, AssemblyOverviewFilterRequestRuntype));

    const { data, aggregations, totalCount } = React.useMemo(() => {
        return {
            data: infinitData?.pages.flatMap((data) => data.page),
            aggregations: infinitData?.pages[0]?.aggregations,
            totalCount: infinitData?.pages[0]?.total_count,
        };
    }, [infinitData]);

    const sharedContext: AssemblyOverviewTableSharedContext = {
        customers,
        aggregations,
    };

    const { table } = useTanStackTablePagination<AssemblyOverviewDTO, AssemblyOverviewTableSharedContext>({
        columns,
        data,
        tableState,
        fetchNextPage,
        isFetchingNextPage,
        totalCount,
        sharedContext,
        onRowClick: (row) => {
            const assembly = row.original;
            if (assembly.rfq) {
                return history.push(
                    route(
                        '/assemblies/:assemblyId/dashboard',
                        { assemblyId: assembly.id },
                        { rfqId: assembly.rfq, tab: undefined, isMonitoringOpen: undefined },
                    ),
                );
            }
            history.push(route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id }));
        },
    });

    return (
        <TanStackTable
            table={table}
            size={'medium'}
            enableMenuBar={{ globalSearch: false }}
            MenuBarTitle={MenuBarTitle}
        />
    );
}

function MenuBarTitle() {
    const {
        featureFlags: { showImportDemandButtons },
    } = useDevFeatureFlags();
    return (
        <Flexbox gap={'8px'}>
            <Text variant="h3">{t`Assemblies`}</Text>
            {showImportDemandButtons && <ButtonsForMocks />}
            <Link href={'mailto:support@luminovo.com'} attention={'high'} target="_blank" rel="noopener noreferrer">
                <Trans>Missing anything? Send us feedback!</Trans>
            </Link>
        </Flexbox>
    );
}

function useAssemblyOverview(filters: AssemblyOverviewFilterRequest[]) {
    const customFilter: AssemblyOverviewFilterRequest = {
        field: 'Custom',
        operator: 'equalsAny',
        parameter: ['TopLevelAssemblies', 'AssembliesWithDemand', 'ImportedAssemblies'],
    };

    const requestBody = {
        page_size: 100,
        filters: [...filters, customFilter],
        page_params: undefined,
    };

    return useInfiniteQuery({
        queryKey: httpQueryKey('POST /assemblies/paginated', { requestBody }),
        queryFn: async ({ pageParam }) => {
            return await http(
                'POST /assemblies/paginated',
                {
                    requestBody: {
                        ...requestBody,
                        page_params: pageParam,
                    },
                },
                getToken(),
            );
        },
        getNextPageParam: (data) => {
            if (!isPresent(data.page_params)) {
                return undefined;
            }
            return data.page_params;
        },
    });
}

function formatIpnIdentifier(ipn: BareIpnIdentifier | null, options: { ifAbsent: string } = { ifAbsent: '-' }) {
    if (!isPresent(ipn)) {
        return options.ifAbsent;
    }

    if (ipn.revision) {
        return `${ipn.value} • ${ipn.revision}`;
    }
    return ipn.value;
}

const columnHelper = createColumnHelper<AssemblyOverviewDTO, AssemblyOverviewTableSharedContext>();

const defaultColumns = [
    columnHelper.text('designator', {
        id: 'Name',
        size: 160,
        enableSorting: false,
        label: () => t`Name`,
        cell: (item) => item.getValue(),
    }),

    columnHelper.text((row) => formatIpnIdentifier(row.ipn), {
        id: 'Ipn',
        size: 160,
        label: () => t`IPN`,
        enableSorting: false,
        cell: (item) => item.getValue(),
    }),

    columnHelper.enum('industry', {
        id: 'Industry',
        size: 160,
        label: () => t`Industry`,
        getOptionLabel: (option) => transEnum(option, assemblyIndustryTranslations),
        options: Object.values(AssemblyIndustry),
        getOptionCount: (option, { aggregations }) => getOptionCount(option, aggregations, 'Industry'),
        enableSorting: false,
        cell: (item) => transEnum(item.getValue(), assemblyIndustryTranslations),
    }),
    columnHelper.enum('origin.type', {
        id: 'Origin',
        size: 160,
        label: () => t`Origin`,
        getOptionLabel: (option) => {
            switch (option) {
                // TODO: remove Imported in separate MR
                case 'Imported':
                    return t`API import`;
                case 'ApiImport':
                    return t`API import`;
                case 'BomImport':
                    return t`BOM import`;
                case 'Manual':
                    return t`Manually created`;
            }
        },
        // TODO: remove Imported in separate MR
        options: ['Imported', 'Manual'],
        getOptionCount: (option, { aggregations }) =>
            getOptionCount(option, aggregations, 'Origin', (o) => ({ type: o })),
        enableSorting: false,
        filterValueTransform: (value) => value.map((v) => ({ type: v })),
        cell: (item) => {
            switch (item.getValue()) {
                case 'Imported':
                    return <Tag color={'primary'} label={t`API import`} attention="low" />;
                case 'ApiImport':
                    return <Tag color={'primary'} label={t`API import`} attention="low" />;
                case 'Manual':
                    return <Tag color={'neutral'} label={t`Manually created`} attention="low" />;
            }
        },
    }),
    columnHelper.date('created_at', {
        size: 160,
        label: () => t`Creation date`,
        cell: (item) => {
            return (
                <Tooltip title={formatLongDateTime(item.getValue())}>
                    <Text variant="inherit">{formatRelativeTime(item.getValue())}</Text>
                </Tooltip>
            );
        },
        enableSorting: false,
        enableColumnFilter: false,
    }),
];

const customerColumn = columnHelper.enum('customer', {
    id: 'Customer',
    size: 160,
    label: () => t`Customer`,
    options: ({ sharedContext }) =>
        sharedContext.customers.map(
            (c) => pick(c, ['id', 'name', 'customer_number']) as AssemblyOverviewDTO['customer'],
        ),
    getOptionLabel: (option) => option?.name ?? '-',
    getOptionCount: (option, { aggregations }) => getOptionCount(option, aggregations, 'Customer', (o) => o?.id),
    getOptionKey: (option) => option?.id ?? '-',
    cell: (item) => item.getValue()?.name ?? '-',
    filterValueTransform: (value) => value.map((v) => v?.id),
    enableSorting: false,
});

import { t, Trans } from '@lingui/macro';
import {
    formatDecimal,
    formatLongDateTime,
    formatRelativeTime,
    isPresent,
    transEnum,
    useMemoCompare,
} from '@luminovo/commons';
import {
    chainComparators,
    Chip,
    colorSystem,
    compareByNumber,
    createColumnHelper,
    FieldNumeric,
    Tag,
    TanStackTable,
    Text,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    MonetaryValueBackend,
    OtsFullPart,
    Packaging,
    PriceType,
    RfqContext,
    SolutionTag,
    StandardPartMarketOfferDTO,
    StandardPartOfferWithSolutionsDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import {
    AvailabilityChip,
    extractAdjustedUnitPrice,
    extractMoqFromOffer,
    extractMpqFromOffer,
    extractSolutionTagColor,
    formatOfferOrigin,
    formatPackaging,
    formatSupplierAndStockLocationDTO,
    isOffTheShelfPartOffer,
    isStandardPartMarketOffer,
    LabelPart,
    leadTimeDaysExtractor,
    parseSolution,
    Solution,
    SolutionErrorTags,
    SolutionNotificationTags,
    solutionTagTranslations,
    SolutionWarningTags,
    SupplierAndStockLocationChip,
} from '@luminovo/sourcing-core';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { priceTypeTranslations } from '../../../../../resources/offer/i18n';
import { convertRfqContext, useOtsPartBulk } from '../../../../../resources/part/partHandler';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
} from '../../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import useDebounce from '../../../../../useDebounce';
import { formatMonetaryValue } from '../../../../../utils/formatMonetaryValue';
import { OriginCell } from '../../../../SolutionManager/components/columns';
import { useOfferDrawer } from '../../../../SolutionManager/components/OfferDrawer';

export const QUANTITY_FOR_AVAILABILITY_INFO = 1;
export const QUANTITY_FOR_UNIT_PRICE = 10_000_000;

export type MarketOfferPartType = { type: 'OffTheShelf'; id: string } | { type: 'Ipn'; ipn: string };

export function extractStock(item: MarketOfferTableData): number {
    return item.offer.available_prices.stock ?? -Infinity;
}

export function extractUnitPrice(item: MarketOfferTableData): number {
    return Number(item.unitPrice?.amount);
}
export interface MarketOfferTableData {
    part: OtsFullPart;
    offer: StandardPartMarketOfferDTO;
    supplier: SupplierAndStockLocationDTO;
    isPreferred: boolean;
    isApproved: boolean;
    requiredQuantity: number | null;
    requiredQuantitySolution: Solution | undefined;
    firstPurchaseOption: Solution['firstPurchaseOption'];
    availability: Solution['availability'];
    solutionTags: Solution['solutionTags'];
    unitPrice: Solution['unitPrice'];
    unitPriceOriginal: MonetaryValueBackend | null;
    totalPrice: Solution['totalPrice'];
    factoryLeadTime: number | null;
}

type MarketOfferTableSharedContext = {
    requiredQuantity: number | null;
    setRequiredQuantity: (value: number | null) => void;
};

function createMarketOfferTableData({
    parts,
    offersWithSolutions,
    requiredQuantity,
    preferredSupplier,
    approvedSupplier,
}: {
    parts: OtsFullPart[];
    offersWithSolutions: StandardPartOfferWithSolutionsDTO[];
    requiredQuantity: number | null;
    preferredSupplier: SupplierAndStockLocationDTO[];
    approvedSupplier: SupplierAndStockLocationDTO[];
}): MarketOfferTableData[] {
    return offersWithSolutions.flatMap(({ offer, solutions }): MarketOfferTableData[] => {
        // removes inventory offers
        if (!isStandardPartMarketOffer(offer)) {
            return [];
        }

        const part = parts.find((part) => part.id === offer.linked_part.id);

        if (!isPresent(part)) {
            return [];
        }

        const supplier = offer.linked_location;

        const isPreferred = preferredSupplier.some(({ id }) => id === supplier.id);
        const isApproved = approvedSupplier.some(({ id }) => id === supplier.id);

        const findAndParseFastestSolution = (quantity: number) => {
            const solution = solutions.find((item) => item.quantity === quantity)?.fastest;
            return solution ? parseSolution(solution) : undefined;
        };

        const lowestQuantitySolution = findAndParseFastestSolution(QUANTITY_FOR_AVAILABILITY_INFO);
        const requiredQuantitySolution = findAndParseFastestSolution(requiredQuantity ?? NaN);
        const highestQuantitySolution = findAndParseFastestSolution(QUANTITY_FOR_UNIT_PRICE);
        const requiredOrLowestSolution = requiredQuantitySolution ?? lowestQuantitySolution;
        const requiredOrHighestSolution = requiredQuantitySolution ?? highestQuantitySolution;

        if (!requiredOrLowestSolution || !requiredOrHighestSolution) {
            return [];
        }

        const firstPurchaseOption = requiredOrHighestSolution.firstPurchaseOption;
        const solutionTags = requiredOrLowestSolution.solutionTags;
        const availability = requiredOrLowestSolution.availability;
        const unitPrice = requiredOrHighestSolution.unitPrice;
        const unitPriceOriginal = requiredOrHighestSolution.firstPurchaseOption.unit_price_original;
        const totalPrice = requiredQuantitySolution?.totalPrice || null;

        if (solutionTags.some(({ tag }) => tag === SolutionTag.Expired)) {
            return [];
        }

        if (solutionTags.some(({ tag }) => tag === SolutionTag.Outdated)) {
            return [];
        }

        return [
            {
                part,
                offer,
                supplier,
                isPreferred,
                isApproved,
                requiredQuantity,
                requiredQuantitySolution,
                firstPurchaseOption,
                solutionTags,
                availability,
                unitPrice,
                unitPriceOriginal,
                totalPrice,
                factoryLeadTime: offer.available_prices.factory_lead_time_days,
            },
        ];
    });
}

/**
 * In case the `requiredQuantity` is not defined. We use the `unitPrice` from the highest price break, and the `availability`
 * information from an solution with a purchase quantity of one. We are aware that this combination of unit price and availability is not correct.
 * However, a default value for the required quantity is not practical from a UX point of view.
 */
export function useMarketOfferTableItems({
    marketOfferPart,
    requiredQuantity,
    rfqContext,
}: {
    marketOfferPart: MarketOfferPartType;
    requiredQuantity: number | null;
    rfqContext: RfqContext;
}) {
    const { data: offersWithSolutions } = useHttpQuery(
        'POST /offers/off-the-shelf/with-solutions',
        {
            requestBody: {
                part: marketOfferPart.type === 'OffTheShelf' ? marketOfferPart.id : marketOfferPart.ipn,
                quantities: isPresent(requiredQuantity)
                    ? [requiredQuantity]
                    : [QUANTITY_FOR_AVAILABILITY_INFO, QUANTITY_FOR_UNIT_PRICE],
                ...convertRfqContext(rfqContext),
            },
        },
        { select: (res) => res.data },
    );

    const { data: parts } = useOtsPartBulk(
        offersWithSolutions
            ?.filter(({ offer }) => isStandardPartMarketOffer(offer) && isOffTheShelfPartOffer(offer))
            .map(({ offer }) => offer.linked_part.id),
        rfqContext,
    );
    const { data: preferredSupplier } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSupplier } = useGlobalApprovedSupplierAndStockLocations();

    const isLoading = !parts || !offersWithSolutions || !preferredSupplier || !approvedSupplier;

    const data = useMemoCompare(
        () => {
            if (isLoading) {
                return undefined;
            }

            const items = createMarketOfferTableData({
                parts,
                offersWithSolutions,
                requiredQuantity,
                preferredSupplier,
                approvedSupplier,
            });
            return items.sort(
                chainComparators(
                    compareByNumber((x: MarketOfferTableData) => (x.isPreferred || x.isApproved ? 0 : 1)),
                    compareByNumber((x: MarketOfferTableData) => leadTimeDaysExtractor(x.availability)),
                    compareByNumber((x: MarketOfferTableData) => extractUnitPrice(x)),
                    compareByNumber((x: MarketOfferTableData) => -extractStock(x)),
                    (a, b) => a.offer.id.localeCompare(b.offer.id),
                ),
            );
        },
        {
            isLoading,
            parts,
            offersWithSolutions,
            requiredQuantity,
            preferredSupplier,
            approvedSupplier,
        },
    );

    return { data, isLoading };
}

function ActionButton({ sharedContext }: { sharedContext: MarketOfferTableSharedContext }) {
    const { requiredQuantity, setRequiredQuantity } = sharedContext;

    return (
        <FieldNumeric
            size="small"
            style={{ width: 134 }}
            value={requiredQuantity ?? null}
            onChange={(value) => {
                if (isPresent(value) && value < 1) {
                    return setRequiredQuantity(1);
                }
                if (isPresent(value) && value > QUANTITY_FOR_UNIT_PRICE) {
                    return setRequiredQuantity(QUANTITY_FOR_UNIT_PRICE);
                }
                setRequiredQuantity(value);
            }}
            placeholder={t`Required quantity`}
        />
    );
}

export function MarketOfferTable({ marketOfferPart }: { marketOfferPart: MarketOfferPartType }): JSX.Element {
    const { openOfferDrawer } = useOfferDrawer();
    const [requiredQuantity, setRequiredQuantity] = React.useState<number | null>(null);

    const { data } = useMarketOfferTableItems({
        marketOfferPart,
        requiredQuantity: useDebounce(requiredQuantity, 500),
        rfqContext: { type: 'OutsideRfQ' },
    });

    const columns = React.useMemo(() => {
        switch (marketOfferPart.type) {
            case 'OffTheShelf':
                return defaultColumns;
            case 'Ipn':
                return [columnPart, ...defaultColumns];
        }
    }, [marketOfferPart.type]);

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableExcelExport: true,
        sharedContext: {
            requiredQuantity,
            setRequiredQuantity,
        },
        initialState: {
            columnFilters: [
                {
                    id: 'solutionTags',
                    value: [SolutionTag.SupplierPreferred, SolutionTag.SupplierApproved],
                },
            ],
        },
        enableSaveAsDefault: {
            storage: 'local',
            key: `market-offer-table-${marketOfferPart.type}`,
        },
        onRowClick: (row) => {
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'OutsideRfQ' },
                solution: row.original.requiredQuantitySolution,
                fallbackSolutionTags: row.original.solutionTags,
            });
        },
    });

    return <TanStackTable table={table} ActionButton={ActionButton} />;
}

const columnHelper = createColumnHelper<MarketOfferTableData>();

const columnPart = columnHelper.text('part', {
    id: 'part',
    label: () => t`Part`,
    size: 180,
    cell: ({ row }) => <LabelPart part={row.original.part} />,
});

const defaultColumns = [
    columnHelper.text((row) => formatSupplierAndStockLocationDTO(row.supplier), {
        id: 'supplier',
        label: () => t`Supplier`,
        size: 150,
        cell: ({ row }) => (
            <SupplierAndStockLocationChip
                isApproved={row.original.isApproved}
                isPreferred={row.original.isPreferred}
                supplier={row.original.supplier}
            />
        ),
    }),

    columnHelper.text('offer.supplier_part_number', {
        id: 'sku',
        label: () => t`SKU`,
        size: 100,
    }),

    columnHelper.enum((row) => row.offer.packaging, {
        id: 'packaging',
        label: () => t`Packaging`,
        size: 90,
        options: [...Object.values(Packaging), null],
        getOptionLabel: (option) => formatPackaging(option),
        cell: (item) => {
            if (!isPresent(item.getValue())) {
                return (
                    <Text variant="body-small" color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return <Tag color={'neutral'} attention={'low'} label={formatPackaging(item.getValue())} />;
        },
    }),
    columnHelper.number((row) => leadTimeDaysExtractor(row.availability), {
        id: 'leadTime',
        label: () => t`Lead time`,
        size: 90,
        align: 'center',
        cell: ({ row }) => <AvailabilityChip solution={row.original} />,
    }),

    columnHelper.number('offer.available_prices.stock', {
        id: 'stock',
        label: () => t`Stock`,
        size: 80,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
        quickFilters: [
            {
                label: () => t`In stock`,
                value: [1, null],
            },
        ],
    }),

    columnHelper.monetaryValue('unitPrice', {
        id: 'unitPrice',
        label: () => t`Unit price`,
        size: 90,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),

    columnHelper.monetaryValue('totalPrice.original_total_price', {
        id: 'totalPrice',
        label: () => t`Total price`,
        size: 100,
        cell: ({ row, getValue }) => {
            if (!isPresent(row.original.requiredQuantity)) {
                return (
                    <Tooltip title={t`No required quantity`}>
                        <div>
                            <Text>-</Text>
                        </div>
                    </Tooltip>
                );
            }
            return formatMonetaryValue(getValue());
        },
    }),

    columnHelper.enum((row) => row.offer.origin.origin, {
        id: 'origin',
        size: 120,
        label: () => t`Origin`,
        getOptionLabel: (origin) => formatOfferOrigin({ origin }) ?? t`Unknown`,
        cell: ({ row }) => <OriginCell key={Math.random()} offer={row.original.offer} />,
    }),

    columnHelper.date('offer.creation_date', {
        id: 'updated',
        size: 100,
        label: () => t`Updated`,
        cell: (item) => (
            <Tooltip title={formatLongDateTime(item.getValue())}>
                <Text variant="body-small" color={colorSystem.neutral[6]}>
                    {formatRelativeTime(item.getValue())}
                </Text>
            </Tooltip>
        ),
    }),
    columnHelper.number((row) => extractMoqFromOffer(row.unitPriceOriginal, row.offer), {
        id: 'moq',
        label: () => t`MOQ`,
        size: 60,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number((row) => extractMpqFromOffer(row.unitPriceOriginal, row.offer), {
        id: 'mpq',
        label: () => t`MPQ`,
        size: 60,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.enum('offer.price_type', {
        id: 'priceType',
        label: () => t`Price type`,
        size: 90,
        initialVisibility: false,
        options: Object.values(PriceType),
        getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
        cell: (item) => (
            <Tag color="neutral" attention="low" label={transEnum(item.getValue(), priceTypeTranslations)} />
        ),
    }),
    columnHelper.enum((row) => extractAdjustedUnitPrice({ solution: row }, 'scaled', 'original')?.currency, {
        id: 'currency',
        label: () => t`Currency`,
        size: 90,
        initialVisibility: false,
        getOptionLabel: (opt) => opt ?? t`Unknown`,
        cell: (item) => <Tag color={'neutral'} attention={'low'} label={item.getValue() ?? t`Unknown`} />,
    }),
    columnHelper.monetaryValue((row) => extractAdjustedUnitPrice({ solution: row }, 'scaled', 'original'), {
        id: 'unitPriceOriginalCurrency',
        label: () => t`Unit price (original currency)`,
        size: 130,
        initialVisibility: false,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),

    columnHelper.array((row) => row.solutionTags.map((t) => t.tag), {
        id: 'solutionTags',
        label: () => t`Solution tags`,
        size: 100,
        enableHiding: false,
        initialVisibility: false,
        options: ({ facetedValues }) =>
            [SolutionTag.SupplierPreferred, SolutionTag.SupplierApproved].concat(
                [...SolutionErrorTags, ...SolutionWarningTags, ...SolutionNotificationTags].filter((tag) =>
                    facetedValues.includes(tag),
                ),
            ),
        renderOption: (tag) => (
            <Chip
                color={extractSolutionTagColor({ solutionTag: tag })}
                label={transEnum(tag, solutionTagTranslations)}
            />
        ),
        getOptionLabel: (tag) => transEnum(tag, solutionTagTranslations),
        cell: () => {
            throw new Error('Not implemented');
        },
        quickFilters: [
            {
                label: () => t`Only approved suppliers`,
                value: [SolutionTag.SupplierPreferred, SolutionTag.SupplierApproved],
            },
        ],
    }),
];

/* eslint-disable camelcase */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { CurrencyRuntype, MonetaryValueBackendRuntype, OfferOriginEnum, QuantityUnitDTORuntype } from '../backendTypes';
import { SiteRuntype } from '../organizationSettings/InventorySiteBackendTypes';
import { StandardPartDTORuntype } from '../part';
import { PcbPanelSpecificationRuntype } from '../pcb';
import { SolutionDTORuntype } from '../solution';
import { PriceTypeRuntype } from '../sourcingScenario/solutionConfigurationBackendTypes';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { PackagingRuntype } from './Packaging';
import { PricePointAvailabilityRuntype } from './availablityBackendTypes';

export interface OrbweaverOrigin extends r.Static<typeof OrbweaverOriginRuntype> {}
const OrbweaverOriginRuntype = r.Record({
    origin: r.Literal(OfferOriginEnum.Orbweaver),
    id: r.String,
    datasource_name: r.String,
});

const ManualOriginRuntype = r.Record({
    origin: r.Literal(OfferOriginEnum.Manual),
});

const WuerthOriginRuntype = r.Record({
    origin: r.Literal(OfferOriginEnum.Wuerth),
});

const BetaLayoutOriginRuntype = r.Record({
    origin: r.Literal(OfferOriginEnum.BetaLayout),
});

export type AllOrigins = r.Static<typeof AllOriginRuntype>;
export const AllOriginRuntype = r.Union(
    r.Record({
        origin: runtypeFromEnum(OfferOriginEnum).withConstraint(
            (x) =>
                ![
                    OfferOriginEnum.Manual,
                    OfferOriginEnum.Orbweaver,
                    OfferOriginEnum.Wuerth,
                    OfferOriginEnum.BetaLayout,
                ].includes(x),
        ),
        id: r.String.optional(),
    }),
    OrbweaverOriginRuntype,
    ManualOriginRuntype,
    WuerthOriginRuntype,
    BetaLayoutOriginRuntype,
);

export type PriceBreakDTO = r.Static<typeof PriceBreakRuntype>;
const PriceBreakRuntype = r.Record({
    moq: r.Number,
    unit_price: r.Record({
        amount: r.String,
        currency: CurrencyRuntype,
    }),
    mpq: r.Number,
    lead_time_days: r.Number.nullable(),
});

export type OnOrderDTO = r.Static<typeof OnOrderRuntype>;
const OnOrderRuntype = r.Record({
    quantity: r.Number,
    date: r.String.nullable(),
});

export interface PriceBreaksDTO extends r.Static<typeof PriceBreaksDTORuntype> {}
export const PriceBreaksDTORuntype = r.Record({
    factory_quantity: r.Number.nullable(),
    factory_lead_time_days: r.Number.nullable(),
    on_order: r.Array(OnOrderRuntype),
    stock: r.Number.nullable(),
    total_stock: r.Number.nullable(),
    price_breaks: r.Array(PriceBreakRuntype),
});

export enum Prices {
    Breaks = 'Breaks',
    Points = 'Points',
}

export interface PricePointsDTO extends r.Static<typeof PricePointsDTORuntype> {}
const PricePointsDTORuntype = r.Record({
    quantity: r.Number,
    unit_price: MonetaryValueBackendRuntype,
    availability: PricePointAvailabilityRuntype,
});

export interface UnitOfMeasurementDTO extends r.Static<typeof UnitOfMeasurementDTORuntype> {}
const UnitOfMeasurementDTORuntype = r.Record({
    quantity_unit: QuantityUnitDTORuntype,
});

/*
The following types represents a hierarchy of part offer data types. 
Utility functions for working with those types are defined in isStandardPartOffer.ts

+-----------------------------------------------------------------------+
|                        StandardPartOfferDTO                           |
+-----------------------------------------------------------------------+

+---------------------------------------++----------------------------+
|             StandardPartMarketOfferDTO                              |
|               +-- OffTheShelfMarketOfferDTO                         |
|               +-- InternalPartNumberMarketOfferDTO                  |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             StandardPartInventoryOfferDTO                           |
|               +-- OffTheShelfInventoryOfferDTO                      |
|               +-- InternalPartNumberInventoryOfferDTO               | 
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             OffTheShelfOfferDTO                                     |
|               +-- OffTheShelfMarketOfferDTO                         |
|               +-- OffTheShelfInventoryOfferDTO                      |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             InternalPartNumberOfferDTO                              |
|               +-- InternalPartNumberMarketOfferDTO                  |
|               +-- InternalPartNumberInventoryOfferDTO               |
+---------------------------------------++----------------------------+

+---------------------------------------++----------------------------+
|             BaseStandardPartOfferDTO                                |
+---------------------------------------++----------------------------+
*/

const BaseStandardPartOfferDTORuntype = r.Record({
    id: r.String,
    origin: AllOriginRuntype,
    price_type: PriceTypeRuntype,
    customer: r.Null.Or(r.String),
    rfq: r.Null.Or(r.String),
    notes: r.Null.Or(r.String),
    creation_date: r.String,
    valid_until: r.Null.Or(r.String),
    offer_number: r.Null.Or(r.String),
    available_prices: PriceBreaksDTORuntype,
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    packaging: r.Null.Or(PackagingRuntype),
    offer_url: r.Null.Or(r.String),
    ncnr: r.Null.Or(r.Boolean),
    attachment: r.String.optional().nullable(),
});

export type OffTheShelfMarketOfferDTO = r.Static<typeof OffTheShelfMarketOfferDTORuntype>;
const OffTheShelfMarketOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: r.Record({
        type: r.Literal('OffTheShelf'),
        id: r.String,
    }),
    linked_location: SupplierAndStockLocationDTORuntype.extend({
        type: r.Literal('SupplierAndStockLocation'),
    }),
    supplier_part_number: r.String,
});

export type InternalPartNumberMarketOfferDTO = r.Static<typeof InternalPartNumberMarketOfferDTORuntype>;
const InternalPartNumberMarketOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: r.Record({
        type: r.Literal('InternalPartNumber'),
        id: r.String,
    }),
    linked_location: SupplierAndStockLocationDTORuntype.extend({
        type: r.Literal('SupplierAndStockLocation'),
    }),
    supplier_part_number: r.String,
});

export type OffTheShelfInventoryOfferDTO = r.Static<typeof StandardPartInventoryOfferDTORuntype>;
const OffTheShelfInventoryOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: r.Record({
        type: r.Literal('OffTheShelf'),
        id: r.String,
    }),
    linked_location: SiteRuntype.extend({
        type: r.Literal('InventorySite'),
    }),
    supplier_part_number: r.String.nullable(),
});

export type InternalPartNumberInventoryOfferDTO = r.Static<typeof StandardPartInventoryOfferDTORuntype>;
const InternalPartNumberInventoryOfferDTORuntype = BaseStandardPartOfferDTORuntype.extend({
    linked_part: r.Record({
        type: r.Literal('InternalPartNumber'),
        id: r.String,
    }),
    linked_location: SiteRuntype.extend({
        type: r.Literal('InventorySite'),
    }),
    supplier_part_number: r.String.nullable(),
});

export type LinkedLocation = StandardPartOfferDTO['linked_location'];

export type StandardPartMarketOfferDTO = r.Static<typeof StandardPartMarketOfferDTORuntype>;
export const StandardPartMarketOfferDTORuntype = r.Union(
    OffTheShelfMarketOfferDTORuntype,
    InternalPartNumberMarketOfferDTORuntype,
);

export type StandardPartInventoryOfferDTO = r.Static<typeof StandardPartInventoryOfferDTORuntype>;
export const StandardPartInventoryOfferDTORuntype = r.Union(
    OffTheShelfInventoryOfferDTORuntype,
    InternalPartNumberInventoryOfferDTORuntype,
);

export type OffTheShelfOfferDTO = r.Static<typeof OffTheShelfOfferDTORuntype>;
const OffTheShelfOfferDTORuntype = r.Union(OffTheShelfMarketOfferDTORuntype, OffTheShelfInventoryOfferDTORuntype);

export type InternalPartNumberOfferDTO = r.Static<typeof InternalPartNumberOfferDTORuntype>;
export const InternalPartNumberOfferDTORuntype = r.Union(
    InternalPartNumberMarketOfferDTORuntype,
    InternalPartNumberInventoryOfferDTORuntype,
);

export type StandardPartOfferDTO = r.Static<typeof StandardPartOfferDTORuntype>;
export const StandardPartOfferDTORuntype = r.Union(
    StandardPartMarketOfferDTORuntype,
    StandardPartInventoryOfferDTORuntype,
);

const AvailabilityInputDTORuntype = r.Record({
    stock: r.Number.nullable(),
    factory_lead_time_days: r.Number.nullable(),
    factory_quantity: r.Number.nullable(),
    on_order: r.Array(OnOrderRuntype),
});

export interface PriceBreakInputDTO extends r.Static<typeof PriceBreakInputDTORuntype> {}
const PriceBreakInputDTORuntype = r.Record({
    moq: r.Number,
    unit_price: r.Number.nullable(),
    mpq: r.Number,
    lead_time_days: r.Optional(r.Number.nullable()),
});

export interface StandardPartOfferInputDTO extends r.Static<typeof StandardPartOfferInputDTORuntype> {}
export const StandardPartOfferInputDTORuntype = r.Record({
    linked_part: r.Union(
        r.Record({
            type: r.Literal('OffTheShelf'),
            id: r.String,
        }),
        r.Record({
            type: r.Literal('InternalPartNumber'),
            id: r.String,
        }),
    ),
    supplier_and_stock_location: r.String,
    supplier_part_number: r.String,
    price_type: PriceTypeRuntype,
    customer: r.Null.Or(r.String),
    rfq: r.Null.Or(r.String),
    notes: r.Null.Or(r.String),
    valid_until: r.Null.Or(r.String),
    currency: CurrencyRuntype,
    availability_input: r.Null.Or(AvailabilityInputDTORuntype),
    price_break_inputs: r.Array(PriceBreakInputDTORuntype),
    packaging: r.Null.Or(PackagingRuntype),
    unit_of_measurement: QuantityUnitDTORuntype,
    offer_number: r.Null.Or(r.String),
    offer_url: r.Null.Or(r.String).optional(),
    ncnr: r.Null.Or(r.Boolean),
});

export interface StandardPartOfferUpdateDTO extends r.Static<typeof StandardPartOfferUpdateDTORuntype> {}
export const StandardPartOfferUpdateDTORuntype = StandardPartOfferInputDTORuntype.pick(
    'supplier_part_number',
    'supplier_and_stock_location',
    'price_type',
    'customer',
    'rfq',
    'notes',
    'valid_until',
    'currency',
    'availability_input',
    'price_break_inputs',
    'packaging',
    'unit_of_measurement',
    'offer_number',
    'ncnr',
);

export enum ValidFor {
    EveryCustomer = 'ever-customer',
    ThisCustomer = 'this-customer',
    ThisRfQ = 'this-rfq',
}

const OneTimeCostPriceDTORuntype = r.Record({
    amount: r.String,
    currency: CurrencyRuntype,
});

export interface OneTimeCostDTO extends r.Static<typeof OneTimeCostDTORuntype> {}
export const OneTimeCostDTORuntype = r.Record({
    description: r.Null.Or(r.String),
    price: OneTimeCostPriceDTORuntype,
});

export enum CustomPriceType {
    ListPrice = 'ListPrice',
    ContractPrice = 'ContractPrice',
    QuotePrice = 'QuotePrice',
    CustomerNegotiatedPrice = 'CustomerNegotiatedPrice',
}
export const CustomPriceTypeRuntype = runtypeFromEnum(CustomPriceType);

export type CustomLinkedPartDTO = r.Static<typeof CustomLinkedPartDTORuntype>;
export const CustomLinkedPartDTORuntype = r.Union(
    r.Record({
        type: r.Literal('CustomPart'),
        id: r.String,
    }),
    r.Record({
        type: r.Literal('CustomComponent'),
        id: r.String,
    }),
);

const BaseCustomPartOfferDTORuntype = r.Record({
    id: r.String,
    linked_location: SupplierAndStockLocationDTORuntype.extend({
        type: r.Literal('SupplierAndStockLocation'),
    }),
    origin: AllOriginRuntype,
    creation_date: r.String,
    has_order_files: r.Boolean,
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_points: r.Array(PricePointsDTORuntype),
    valid_until: r.Null.Or(r.String),
    one_time_costs: r.Array(OneTimeCostDTORuntype),
    notes: r.Null.Or(r.String),
    offer_number: r.Null.Or(r.String),
    offer_url: r.Null.Or(r.String),
    price_type: CustomPriceTypeRuntype.nullable(),
    specification: PcbPanelSpecificationRuntype.nullable(),
});

export interface CustomPartOfferDTO extends r.Static<typeof CustomPartOfferDTORuntype> {}
export const CustomPartOfferDTORuntype = BaseCustomPartOfferDTORuntype.extend({
    linked_part: r.Record({
        type: r.Literal('CustomPart'),
        id: r.String,
    }),
});

export interface CustomComponentOfferDTO extends r.Static<typeof CustomComponentOfferDTORuntype> {}
export const CustomComponentOfferDTORuntype = BaseCustomPartOfferDTORuntype.extend({
    linked_part: r.Record({
        type: r.Literal('CustomComponent'),
        id: r.String,
    }),
});

export type CustomOptionOfferDTO = r.Static<typeof CustomOptionOfferDTORuntype>;
export const CustomOptionOfferDTORuntype = r.Union(CustomPartOfferDTORuntype, CustomComponentOfferDTORuntype);

export interface CustomPricePointInputDTO extends r.Static<typeof CustomPricePointInputDTORuntype> {}
const CustomPricePointInputDTORuntype = r.Record({
    quantity: r.Number,
    amount: r.String,
    lead_time_days: r.Undefined.Or(r.Number),
});

export interface OneTimeCostInputDTO extends r.Static<typeof OneTimeCostInputDTORuntype> {}
const OneTimeCostInputDTORuntype = r.Record({
    description: r.Undefined.Or(r.String),
    amount: r.String,
});

export interface CustomPartOfferInputDTO extends r.Static<typeof CustomPartOfferInputDTORuntype> {}
export const CustomPartOfferInputDTORuntype = r.Record({
    linked_part: CustomLinkedPartDTORuntype,
    supplier_and_stock_location: r.String,
    unit_of_measurement: QuantityUnitDTORuntype,
    currency: CurrencyRuntype,
    price_points: r.Array(CustomPricePointInputDTORuntype),
    valid_until: r.Undefined.Or(r.String),
    one_time_costs: r.Array(OneTimeCostInputDTORuntype),
    price_type: r.Undefined.Or(CustomPriceTypeRuntype),
    offer_number: r.Undefined.Or(r.String),
    notes: r.Undefined.Or(r.String),
    sourcing_scenario_id: r.Undefined.Or(r.String),
});

export interface CustomPartOfferUpdateDTO extends r.Static<typeof CustomPartOfferUpdateDTORuntype> {}
export const CustomPartOfferUpdateDTORuntype = r.Record({
    supplier_and_stock_location: r.String,
    unit_of_measurement: QuantityUnitDTORuntype,
    currency: CurrencyRuntype,
    price_points: r.Array(CustomPricePointInputDTORuntype),
    valid_until: r.Null.Or(r.String),
    one_time_costs: r.Array(OneTimeCostInputDTORuntype),
    notes: r.Null.Or(r.String),
    price_type: r.Undefined.Or(CustomPriceTypeRuntype),
});
/* eslint-enable camelcase */

const BoundingRegion = r.Record({
    pageNumber: r.Number,
    polygon: r.Array(r.Number),
});

const ObjectTypeCurrencyRuntype = r.Record({
    type: r.Literal('currency'),
    valueCurrency: r
        .Record({
            amount: r.Number,
        })
        .optional(),
    boundingRegions: r.Array(BoundingRegion),
    confidence: r.Number,
    content: r.String,
});

const ObjectTypeNumberRuntype = r.Record({
    type: r.Literal('number'),
    valueNumber: r.Number,
    boundingRegions: r.Array(BoundingRegion),
    confidence: r.Number,
    content: r.String,
});

const ObjectTypeStringRuntype = r.Record({
    type: r.Literal('string'),
    valueString: r.String,
    boundingRegions: r.Array(BoundingRegion),
    confidence: r.Number,
    content: r.String,
});

const ObjectTypeDateRuntype = r.Record({
    type: r.Literal('date'),
    valueDate: r.String,
    boundingRegions: r.Array(BoundingRegion),
    confidence: r.Number,
    content: r.String,
});

export interface ObjectTypeObject extends r.Static<typeof ObjectTypeObjectRuntype> {}
const ObjectTypeObjectRuntype = r.Record({
    type: r.Literal('object'),
    valueObject: r
        .Record({
            UnitPrice: ObjectTypeCurrencyRuntype.optional(),
            ProductCode: ObjectTypeStringRuntype.optional(),
            Unit: ObjectTypeStringRuntype.optional(),
            Quantity: ObjectTypeNumberRuntype.optional(),
            Amount: ObjectTypeCurrencyRuntype.optional(),
        })
        .optional(), // very annoyingly so there's been occasions where Azure just skips this
    boundingRegions: r.Array(BoundingRegion),
    confidence: r.Number,
    content: r.String,
});

const ObjectTypeArrayRuntype = r.Record({
    type: r.Literal('array'),
    valueArray: r.Array(ObjectTypeObjectRuntype),
});

export const PartOfferUpdateStatusRuntype = r.Union(
    r.Literal('InactiveAPI'),
    r.Record({
        InProgress: r.Record({ progress_id: r.String }).optional(),
        RateLimited: r.Record({ seconds_to_expiry: r.Number }).optional(),
        RecentlyUpdated: r.Record({ last_part_status: r.String }).optional(),
    }),
);

export interface AzureExtractionResult extends r.Static<typeof AzureExtractionResultRuntype> {}
export const AzureExtractionResultRuntype = r.Record({
    analyzeResult: r.Record({
        pages: r.Array(
            r.Record({
                pageNumber: r.Number,
                width: r.Number,
                height: r.Number,
                lines: r.Array(
                    r.Record({
                        polygon: r.Array(r.Number),
                        content: r.String,
                    }),
                ),
                words: r.Array(
                    r.Record({
                        polygon: r.Array(r.Number),
                        content: r.String,
                    }),
                ),
            }),
        ),
        tables: r.Array(
            r.Record({
                cells: r.Array(
                    r.Record({
                        kind: r.Literal('columnHeader').optional(),
                        columnIndex: r.Number,
                        rowIndex: r.Number,
                        columnSpan: r.Number.optional(),
                        content: r.String,
                        boundingRegions: r.Array(BoundingRegion),
                    }),
                ),
            }),
        ),
        paragraphs: r.Array(
            r.Record({
                content: r.String,
                boundingRegions: r.Array(BoundingRegion),
            }),
        ),
        keyValuePairs: r.Array(
            r.Record({
                key: r.Record({
                    content: r.String,
                    boundingRegions: r.Array(BoundingRegion),
                }),
                value: r
                    .Record({
                        content: r.String,
                        boundingRegions: r.Array(BoundingRegion),
                    })
                    .optional(),
                confidence: r.Number,
            }),
        ),
        documents: r.Array(
            r.Record({
                docType: r.Literal('invoice'),
                boundingRegions: r.Array(BoundingRegion),
                fields: r.Record({
                    AmountDue: r
                        .Record({
                            content: r.String,
                        })
                        .optional(),
                    DueDate: ObjectTypeDateRuntype.optional(),
                    InvoiceDate: ObjectTypeDateRuntype.optional(),
                    InvoiceId: ObjectTypeStringRuntype.optional(),
                    InvoiceTotal: r
                        .Record({
                            content: r.String,
                        })
                        .optional(),
                    Items: ObjectTypeArrayRuntype.optional(),
                    ServiceEndDate: ObjectTypeDateRuntype.optional(),
                    SubTotal: r
                        .Record({
                            content: r.String,
                        })
                        .optional(),
                }),
            }),
        ),
    }),
});

export type PdfAnalyzeResponse = r.Static<typeof PdfAnalyzeResponseRuntype>;
export const PdfAnalyzeResponseRuntype = r.Intersect(
    AzureExtractionResultRuntype.fields.analyzeResult,
    r.Record({
        attachment: r.String.optional(),
    }),
);

const QuoteImportEventTypeEnumRuntype = r.Union(r.Literal('Pdf'), r.Literal('Excel'), r.Literal('SupplierPortal'));

export const QuoteImportEventRuntype = r.Record({
    type: QuoteImportEventTypeEnumRuntype,
});

export interface StandardPartOfferBulkInputDTO extends r.Static<typeof StandardPartOfferBulkInputDTORuntype> {}
export const StandardPartOfferBulkInputDTORuntype = r.Record({
    requested_part: StandardPartDTORuntype,
    linked_part: StandardPartDTORuntype,
    supplier_part_number: r.String.optional(),
    supplier_and_stock_location: r.String,
    price_type: PriceTypeRuntype,
    customer: r.String.nullable(),
    rfq_id: r.String.nullable(),
    notes: r.String.nullable(),
    valid_until: r.String.nullable(),
    currency: CurrencyRuntype,
    availability_input: AvailabilityInputDTORuntype.nullable(),
    price_break_inputs: r.Array(PriceBreakInputDTORuntype),
    packaging: PackagingRuntype.nullable(),
    unit_of_measurement: QuantityUnitDTORuntype,
    offer_number: r.String.nullable(),
    attachment: r.String.nullable(),
    ncnr: r.Boolean.nullable(),
});

export interface StandardPartOfferWithSolutionsDTO extends r.Static<typeof StandardPartOfferWithSolutionsDTORuntype> {}
export const StandardPartOfferWithSolutionsDTORuntype = r.Record({
    offer: StandardPartOfferDTORuntype,
    solutions: r.Array(
        r.Record({
            quantity: r.Number,
            fastest: SolutionDTORuntype.nullable(),
            best_price: SolutionDTORuntype.nullable(),
        }),
    ),
});

export interface QuoteImporterResponse extends r.Static<typeof QuoteImporterResponseRuntype> {}
export const QuoteImporterResponseRuntype = r.Record({
    quote_tracking_id: r.String,
    ids: r.Array(r.String),
    design_items: r.Array(r.String),
});

export interface ImportOffersRequestBody extends r.Static<typeof ImportOffersRequestBodyRuntype> {}
export const ImportOffersRequestBodyRuntype = r.Record({
    part: r.Union(
        r.Record({
            internal_part_number: r.String,
        }),
        r.Record({
            manufacturer_part_number: r.String,
            manufacturer: r.String,
        }),
    ),
    availability: r
        .Record({
            available_stock: r.Number,
            total_stock: r.Number.optional(),
            lead_time: r.Number.optional(),
        })
        .optional(),
    notes: r.String.optional(),
    packaging: PackagingRuntype.optional(),
    price_type: PriceTypeRuntype.optional(),
    unit_of_measurement: QuantityUnitDTORuntype.optional(),
    valid_until: r.String.optional(),
    supplier: r.Union(
        r.Record({
            type: r.Literal('Internal'),
        }),
        r.Record({
            type: r.Literal('External'),
            supplier_number: r.String,
            supplier: r.String.optional(),
        }),
    ),
    prices: r.Array(
        r.Record({
            moq: r.Number.optional(),
            mpq: r.Number.optional(),
            unit_price: MonetaryValueBackendRuntype,
        }),
    ),
});

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import {
    AvailabilityRuntype,
    CustomOptionOfferDTORuntype,
    StandardPartInventoryOfferDTORuntype,
    StandardPartOfferDTORuntype,
} from '../offer';
import { CustomFullPartRuntype, OtsFullPartRuntype } from '../part';
import { SolutionConfigurationFullPartSourcingInformationResponseRuntype } from '../sourcingScenario';
import { SolutionPreferenceDTORuntype } from '../sourcingScenario/solutionConfigurationBackendTypes';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { RfqStatus } from './rfqBackendTypes';

export enum SnapshotTagType {
    Manual = 'Manual',
    Note = 'Note',
    RfqCreated = 'RfqCreated',
    RfqStatusChanged = 'RfqStatusChanged',
    SourcingScenarioName = 'SourcingScenarioName',
    SourcingScenarioId = 'SourcingScenarioId',
    CostedBomExported = 'CostedBomExported',
}

export type SnapshotTagDTO = r.Static<typeof SnapshotTagRuntype>;
export const SnapshotTagRuntype = r.Union(
    r.Record({
        type: r.Literal(SnapshotTagType.Manual),
    }),
    r.Record({
        type: r.Literal(SnapshotTagType.Note),
        value: r.String,
    }),
    r.Record({
        type: r.Literal(SnapshotTagType.RfqCreated),
    }),
    r.Record({
        type: r.Literal(SnapshotTagType.RfqStatusChanged),
        value: runtypeFromEnum(RfqStatus),
    }),
    r.Record({
        type: r.Literal(SnapshotTagType.SourcingScenarioName),
        value: r.String,
    }),
    r.Record({
        type: r.Literal(SnapshotTagType.SourcingScenarioId),
        value: r.String,
    }),
    r.Record({
        type: r.Literal(SnapshotTagType.CostedBomExported),
    }),
);

export interface SnapshotMetadataItemDTO extends r.Static<typeof SnapshotMetadataItemRuntype> {}
export const SnapshotMetadataItemRuntype = r.Record({
    id: r.String,
    created_at: r.String,
    tags: r.Array(SnapshotTagRuntype),
});

export interface SnapshotDTO extends r.Static<typeof SnapshotRuntype> {}
export const SnapshotRuntype = r.Record({
    off_the_shelf_offers: r.Record({
        items: r.Array(StandardPartOfferDTORuntype),
    }),
    inventory_offers: r.Record({
        items: r.Array(StandardPartInventoryOfferDTORuntype),
    }),
    custom_part_offers: r.Record({
        items: r.Array(CustomOptionOfferDTORuntype),
    }),
    ots_parts: r.Array(OtsFullPartRuntype),
    custom_parts: r.Array(CustomFullPartRuntype),
    suppliers: r.Array(SupplierAndStockLocationDTORuntype),
    sourcing_scenarios: r.Array(
        r.Record({
            id: r.String,
            name: r.String,
            assembly_quantities: r.Record({
                items: r.Array(
                    r.Record({
                        quantity: r.Number,
                        assembly: r.String,
                    }),
                ),
            }),
            aggregated: r.Record({
                total_availability: AvailabilityRuntype.nullable(),
                total_excess_material: MonetaryValueBackendRuntype.nullable(),
                total_unit_price: MonetaryValueBackendRuntype.nullable(),
                total_price: MonetaryValueBackendRuntype.nullable(),
                total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
                total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
                status_count: r.Record({
                    number_of_ok: r.Number,
                    number_of_warning: r.Number,
                    number_of_error: r.Number,
                }),
            }),
            solution_preference: SolutionPreferenceDTORuntype,
            solution_configurations: r.Array(SolutionConfigurationFullPartSourcingInformationResponseRuntype),
        }),
    ),
    currency_settings: r.Record({
        currency: CurrencyRuntype,
        exchange_rates: r.Dictionary(r.String, CurrencyRuntype),
    }),
});

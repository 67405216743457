import * as r from 'runtypes';
import { QuantityUnitDTORuntype } from '../backendTypes';
import {
    DemandResponseParticipantRuntype,
    DemandResponseRuntype,
    GrossDemandErrorRuntype,
} from './plannedOrderBackendTypes';

const DemandTimeframeTypeRuntype = r.Union(r.Literal('yearly'), r.Literal('monthly'));
export type DemandTimeframeTypeDTO = r.Static<typeof DemandTimeframeTypeRuntype>;

const DemandTimeframeRuntype = r.Union(
    r.Record({
        type: r.Literal('yearly'),
        year: r.Number,
    }),
    r.Record({
        type: r.Literal('monthly'),
        year: r.Number,
        month: r.Number,
    }),
);
export type DemandTimeframeDTO = r.Static<typeof DemandTimeframeRuntype>;

const AggregatedDemandRuntype = r.Record({
    timeframe: DemandTimeframeRuntype,
    gross_quantity: QuantityUnitDTORuntype,
    net_quantity: QuantityUnitDTORuntype,
});
export type AggregatedDemandDTO = r.Static<typeof AggregatedDemandRuntype>;

const SupplierDemandSummaryRuntype = r.Record({
    supplier: DemandResponseParticipantRuntype,
    total_gross_demand: QuantityUnitDTORuntype,
    total_net_demand: QuantityUnitDTORuntype,
    aggregated_demands: r.Array(AggregatedDemandRuntype),
});
export type SupplierDemandSummaryDTO = r.Static<typeof SupplierDemandSummaryRuntype>;

const RecipientDemandSummaryRuntype = r.Record({
    recipient: DemandResponseParticipantRuntype,
    total_gross_demand: QuantityUnitDTORuntype,
    total_net_demand: QuantityUnitDTORuntype,
    aggregated_demands: r.Array(AggregatedDemandRuntype),
});
export type RecipientDemandSummaryDTO = r.Static<typeof RecipientDemandSummaryRuntype>;

const DemandSummaryRuntype = r.Union(
    r.Record({
        participant_role: r.Literal('supplier'),
        total_gross_demand: QuantityUnitDTORuntype,
        total_net_demand: QuantityUnitDTORuntype,
        supplier_demands: r.Array(SupplierDemandSummaryRuntype),
        start_date: r.String,
        end_date: r.String,
    }),
    r.Record({
        participant_role: r.Literal('recipient'),
        total_gross_demand: QuantityUnitDTORuntype,
        total_net_demand: QuantityUnitDTORuntype,
        recipient_demands: r.Array(RecipientDemandSummaryRuntype),
        start_date: r.String,
        end_date: r.String,
    }),
);
export type DemandSummaryDTO = r.Static<typeof DemandSummaryRuntype>;

export const DemandSummaryResponseRuntype = r.Record({
    summary: DemandSummaryRuntype,
    demands: r.Array(DemandResponseRuntype),
    errors: r.Array(GrossDemandErrorRuntype),
});
export type DemandsSummaryResponseDTO = r.Static<typeof DemandSummaryResponseRuntype>;

const DemandParticipantRoleRuntype = r.Union(r.Literal('supplier'), r.Literal('recipient'));
export type DemandParticipantRoleDTO = r.Static<typeof DemandParticipantRoleRuntype>;

const DemandTypeRuntype = r.Union(r.Literal('gross'), r.Literal('net'));
export type DemandTypeDTO = r.Static<typeof DemandTypeRuntype>;

export const DemandSummaryRequestRuntype = r.Record({
    ipns: r.Array(r.String).optional(),
    supplier_numbers: r.Array(r.String).optional(),
    contexts: r.Array(r.String).optional(),
    participant_role: DemandParticipantRoleRuntype,
    timeframe_type: DemandTimeframeTypeRuntype,
    start_date: r.String,
    end_date: r.String,
});

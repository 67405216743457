import { t } from '@lingui/macro';
import { isPresent } from '../typingUtils';
import { Currency, FormattingType, formatCurrency } from './formatCurrency';

export type MonetaryValue = {
    amount: string;
    currency: Currency;
};
type ExchangedMonetaryValueBackend = {
    converted_mv: MonetaryValue;
    original_mv: MonetaryValue;
};

export function formatMonetaryValue(
    monetaryValue: MonetaryValue | ExchangedMonetaryValueBackend | null | undefined,
    formattingType: FormattingType = 'default',
    options?: { ifAbsent?: string; alwaysShowSign?: boolean },
): string {
    if (!monetaryValue) {
        return options?.ifAbsent ?? t`Unknown`;
    }

    const { amount, currency } = 'converted_mv' in monetaryValue ? monetaryValue.converted_mv : monetaryValue;
    const signum = sign(amount, options?.alwaysShowSign);

    return `${signum}${formatCurrency(amount, currency, formattingType)}`;
}

function sign(amount: string, alwaysShowSign: boolean = false): string {
    if (!alwaysShowSign) {
        return '';
    }

    const num = Number(amount);
    if (num > 0) {
        return '+';
    }
    return '';
}

export function extractAmountFromMonetaryValue(
    monetaryValue: MonetaryValue | null | undefined,
    options?: { ifAbsent?: number },
): number {
    if (!isPresent(monetaryValue)) {
        return options?.ifAbsent ?? 0;
    }

    return Number(monetaryValue?.amount);
}

import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { RegionsEnum, SupplierAndStockLocationDTO, SupplierDTO } from '@luminovo/http-client';

export function formatSupplierDTO(supplier?: SupplierDTO): string {
    if (!supplier) {
        return t`Unknown`;
    }
    return supplier.name;
}

export function formatSupplierAndStockLocationDTO(sasl?: SupplierAndStockLocationDTO): string {
    if (!sasl) {
        return t`Unknown`;
    }
    return formatSupplierNameWithStockLocation(sasl.supplier.name, sasl.stock_location);
}

export function formatSupplierNameWithStockLocation(name: string, stockLocation: RegionsEnum): string {
    if (stockLocation === RegionsEnum.Unknown) {
        return name;
    }

    // First try the short 3 letter code but if not available go for full name
    const stockLocationText = formatRegionEnumAsISO3166Alpha3(stockLocation) || formatRegionAsName(stockLocation);
    return `${name} · ${stockLocationText}`;
}

export function formatRegionAsName(region: RegionsEnum): string {
    switch (region) {
        case RegionsEnum.Unknown:
            return t`Unknown`;
        case RegionsEnum.World:
            return t`World`;
        case RegionsEnum.Africa:
            return t`Africa`;
        case RegionsEnum.NorthernAfrica:
            return t`Northern Africa`;
        case RegionsEnum.SubSaharanAfrica:
            return t`Sub-Saharan Africa`;
        case RegionsEnum.WesternAfrica:
            return t`Western Africa`;
        case RegionsEnum.EasternAfrica:
            return t`Eastern Africa`;
        case RegionsEnum.MiddleAfrica:
            return t`Middle Africa`;
        case RegionsEnum.SouthernAfrica:
            return t`Southern Africa`;
        case RegionsEnum.Americas:
            return t`Americas`;
        case RegionsEnum.NorthernAmerica:
            return t`Northern America`;
        case RegionsEnum.LatinAmericaAndTheCaribbean:
            return t`Latin America and the Caribbean`;
        case RegionsEnum.SouthAmerica:
            return t`South America`;
        case RegionsEnum.CentralAmerica:
            return t`Central America`;
        case RegionsEnum.Caribbean:
            return t`Caribbean`;
        case RegionsEnum.Asia:
            return t`Asia`;
        case RegionsEnum.EasternAsia:
            return t`Eastern Asia`;
        case RegionsEnum.SouthernAsia:
            return t`Southern Asia`;
        case RegionsEnum.SouthEasternAsia:
            return t`South-eastern Asia`;
        case RegionsEnum.CentralAsia:
            return t`Central Asia`;
        case RegionsEnum.WesternAsia:
            return t`Western Asia`;
        case RegionsEnum.Europe:
            return t`Europe`;
        case RegionsEnum.SouthernEurope:
            return t`Southern Europe`;
        case RegionsEnum.EasternEurope:
            return t`Eastern Europe`;
        case RegionsEnum.NorthernEurope:
            return t`Northern Europe`;
        case RegionsEnum.ChannelIslands:
            return t`Channel Islands`;
        case RegionsEnum.WesternEurope:
            return t`Western Europe`;
        case RegionsEnum.Oceania:
            return t`Oceania`;
        case RegionsEnum.AustraliaAndNewZealand:
            return t`Australia and New Zealand`;
        case RegionsEnum.Melanesia:
            return t`Melanesia`;
        case RegionsEnum.Micronesia:
            return t`Micronesia`;
        case RegionsEnum.Polynesia:
            return t`Polynesia`;
        case RegionsEnum.Algeria:
            return t`Algeria`;
        case RegionsEnum.Egypt:
            return t`Egypt`;
        case RegionsEnum.Libya:
            return t`Libya`;
        case RegionsEnum.Morocco:
            return t`Morocco`;
        case RegionsEnum.Sudan:
            return t`Sudan`;
        case RegionsEnum.Tunisia:
            return t`Tunisia`;
        case RegionsEnum.WesternSahara:
            return t`Western Sahara`;
        case RegionsEnum.BritishIndianOceanTerritory:
            return t`British Indian Ocean Territory`;
        case RegionsEnum.Burundi:
            return t`Burundi`;
        case RegionsEnum.Comoros:
            return t`Comoros`;
        case RegionsEnum.Djibouti:
            return t`Djibouti`;
        case RegionsEnum.Eritrea:
            return t`Eritrea`;
        case RegionsEnum.Ethiopia:
            return t`Ethiopia`;
        case RegionsEnum.FrenchSouthernTerritories:
            return t`French Southern Territories`;
        case RegionsEnum.Kenya:
            return t`Kenya`;
        case RegionsEnum.Madagascar:
            return t`Madagascar`;
        case RegionsEnum.Malawi:
            return t`Malawi`;
        case RegionsEnum.Mauritius:
            return t`Mauritius`;
        case RegionsEnum.Mayotte:
            return t`Mayotte`;
        case RegionsEnum.Mozambique:
            return t`Mozambique`;
        case RegionsEnum.Reunion:
            return t`Réunion`;
        case RegionsEnum.Rwanda:
            return t`Rwanda`;
        case RegionsEnum.Seychelles:
            return t`Seychelles`;
        case RegionsEnum.Somalia:
            return t`Somalia`;
        case RegionsEnum.SouthSudan:
            return t`South Sudan`;
        case RegionsEnum.Uganda:
            return t`Uganda`;
        case RegionsEnum.UnitedRepublicOfTanzania:
            return t`United Republic of Tanzania`;
        case RegionsEnum.Zambia:
            return t`Zambia`;
        case RegionsEnum.Zimbabwe:
            return t`Zimbabwe`;
        case RegionsEnum.Angola:
            return t`Angola`;
        case RegionsEnum.Cameroon:
            return t`Cameroon`;
        case RegionsEnum.CentralAfricanRepublic:
            return t`Central African Republic`;
        case RegionsEnum.Chad:
            return t`Chad`;
        case RegionsEnum.Congo:
            return t`Congo`;
        case RegionsEnum.DemocraticRepublicOfTheCongo:
            return t`Democratic Republic of the Congo`;
        case RegionsEnum.EquatorialGuinea:
            return t`Equatorial Guinea`;
        case RegionsEnum.Gabon:
            return t`Gabon`;
        case RegionsEnum.SaoTomeAndPrincipe:
            return t`Sao Tome and Principe`;
        case RegionsEnum.Botswana:
            return t`Botswana`;
        case RegionsEnum.Eswatini:
            return t`Eswatini`;
        case RegionsEnum.Lesotho:
            return t`Lesotho`;
        case RegionsEnum.Namibia:
            return t`Namibia`;
        case RegionsEnum.SouthAfrica:
            return t`South Africa`;
        case RegionsEnum.Benin:
            return t`Benin`;
        case RegionsEnum.BurkinaFaso:
            return t`Burkina Faso`;
        case RegionsEnum.CaboVerde:
            return t`Cabo Verde`;
        case RegionsEnum.CoteDIvore:
            return t`Côte d’Ivoire`;
        case RegionsEnum.Gambia:
            return t`Gambia`;
        case RegionsEnum.Ghana:
            return t`Ghana`;
        case RegionsEnum.Guinea:
            return t`Guinea`;
        case RegionsEnum.GuineaBissau:
            return t`Guinea-Bissau`;
        case RegionsEnum.Liberia:
            return t`Liberia`;
        case RegionsEnum.Mali:
            return t`Mali`;
        case RegionsEnum.Mauritania:
            return t`Mauritania`;
        case RegionsEnum.Niger:
            return t`Niger`;
        case RegionsEnum.Nigeria:
            return t`Nigeria`;
        case RegionsEnum.SaintHelena:
            return t`Saint Helena`;
        case RegionsEnum.Senegal:
            return t`Senegal`;
        case RegionsEnum.SierraLeone:
            return t`Sierra Leone`;
        case RegionsEnum.Togo:
            return t`Togo`;
        case RegionsEnum.Anguilla:
            return t`Anguilla`;
        case RegionsEnum.AntiguaAndBarbuda:
            return t`Antigua and Barbuda`;
        case RegionsEnum.Aruba:
            return t`Aruba`;
        case RegionsEnum.Bahamas:
            return t`Bahamas`;
        case RegionsEnum.Barbados:
            return t`Barbados`;
        case RegionsEnum.BonaireAndSintEustatiusAndSaba:
            return t`Bonaire, Sint Eustatius and Saba`;
        case RegionsEnum.BritishVirginIslands:
            return t`British Virgin Islands`;
        case RegionsEnum.CaymanIslands:
            return t`Cayman Islands`;
        case RegionsEnum.Cuba:
            return t`Cuba`;
        case RegionsEnum.Curacao:
            return t`Curaçao`;
        case RegionsEnum.Dominica:
            return t`Dominica`;
        case RegionsEnum.DominicanRepublic:
            return t`Dominican Republic`;
        case RegionsEnum.Grenada:
            return t`Grenada`;
        case RegionsEnum.Guadeloupe:
            return t`Guadeloupe`;
        case RegionsEnum.Haiti:
            return t`Haiti`;
        case RegionsEnum.Jamaica:
            return t`Jamaica`;
        case RegionsEnum.Martinique:
            return t`Martinique`;
        case RegionsEnum.Montserrat:
            return t`Montserrat`;
        case RegionsEnum.PuertoRico:
            return t`Puerto Rico`;
        case RegionsEnum.SaintBarthelemy:
            return t`Saint Barthélemy`;
        case RegionsEnum.SaintKittsAndNevis:
            return t`Saint Kitts and Nevis`;
        case RegionsEnum.SaintLucia:
            return t`Saint Lucia`;
        case RegionsEnum.SaintMartin:
            return t`Saint Martin (French Part)`;
        case RegionsEnum.SaintVincentAndTheGrenadines:
            return t`Saint Vincent and the Grenadines`;
        case RegionsEnum.SintMaarten:
            return t`Sint Maarten (Dutch part)`;
        case RegionsEnum.TrinidadAndTobago:
            return t`Trinidad and Tobago`;
        case RegionsEnum.TurksAndCaicosIslands:
            return t`Turks and Caicos Islands`;
        case RegionsEnum.UnitedStatesVirginIslands:
            return t`United States Virgin Islands`;
        case RegionsEnum.Belize:
            return t`Belize`;
        case RegionsEnum.CostaRica:
            return t`Costa Rica`;
        case RegionsEnum.ElSalvador:
            return t`El Salvador`;
        case RegionsEnum.Guatemala:
            return t`Guatemala`;
        case RegionsEnum.Honduras:
            return t`Honduras`;
        case RegionsEnum.Mexico:
            return t`Mexico`;
        case RegionsEnum.Nicaragua:
            return t`Nicaragua`;
        case RegionsEnum.Panama:
            return t`Panama`;
        case RegionsEnum.Argentina:
            return t`Argentina`;
        case RegionsEnum.Bolivia:
            return t`Bolivia (Plurinational State of)`;
        case RegionsEnum.BouvetIsland:
            return t`Bouvet Island`;
        case RegionsEnum.Brazil:
            return t`Brazil`;
        case RegionsEnum.Chile:
            return t`Chile`;
        case RegionsEnum.Colombia:
            return t`Colombia`;
        case RegionsEnum.Ecuador:
            return t`Ecuador`;
        case RegionsEnum.FalklandIslands:
            return t`Falkland Islands (Malvinas)`;
        case RegionsEnum.FrenchGuiana:
            return t`French Guiana`;
        case RegionsEnum.Guyana:
            return t`Guyana`;
        case RegionsEnum.Paraguay:
            return t`Paraguay`;
        case RegionsEnum.Peru:
            return t`Peru`;
        case RegionsEnum.SouthGeorgiaAndTheSouthSandwichIslands:
            return t`South Georgia and the South Sandwich Islands`;
        case RegionsEnum.Suriname:
            return t`Suriname`;
        case RegionsEnum.Uruguay:
            return t`Uruguay`;
        case RegionsEnum.Venezuela:
            return t`Venezuela (Bolivarian Republic of)`;
        case RegionsEnum.Bermuda:
            return t`Bermuda`;
        case RegionsEnum.Canada:
            return t`Canada`;
        case RegionsEnum.Greenland:
            return t`Greenland`;
        case RegionsEnum.SaintPierreAndMiquelon:
            return t`Saint Pierre and Miquelon`;
        case RegionsEnum.UnitedStatesOfAmerica:
            return t`United States of America`;
        case RegionsEnum.Antarctica:
            return t`Antarctica`;
        case RegionsEnum.Kazakhstan:
            return t`Kazakhstan`;
        case RegionsEnum.Kyrgyzstan:
            return t`Kyrgyzstan`;
        case RegionsEnum.Tajikistan:
            return t`Tajikistan`;
        case RegionsEnum.Turkmenistan:
            return t`Turkmenistan`;
        case RegionsEnum.Uzbekistan:
            return t`Uzbekistan`;
        case RegionsEnum.China:
            return t`China`;
        case RegionsEnum.Taiwan:
            return t`Taiwan`;
        case RegionsEnum.HongKong:
            return t`China, Hong Kong Special Administrative Region`;
        case RegionsEnum.Macao:
            return t`China, Macao Special Administrative Region`;
        case RegionsEnum.DemocraticPeoplesRepublicOfKorea:
            return "Democratic People's Republic of Korea";
        case RegionsEnum.Japan:
            return t`Japan`;
        case RegionsEnum.Mongolia:
            return t`Mongolia`;
        case RegionsEnum.RepublicOfKorea:
            return t`Republic of Korea`;
        case RegionsEnum.BruneiDarussalam:
            return t`Brunei Darussalam`;
        case RegionsEnum.Cambodia:
            return t`Cambodia`;
        case RegionsEnum.Indonesia:
            return t`Indonesia`;
        case RegionsEnum.LaoPeoplesDemocraticRepublic:
            return "Lao People's Democratic Republic";
        case RegionsEnum.Malaysia:
            return t`Malaysia`;
        case RegionsEnum.Myanmar:
            return t`Myanmar`;
        case RegionsEnum.Philippines:
            return t`Philippines`;
        case RegionsEnum.Singapore:
            return t`Singapore`;
        case RegionsEnum.Thailand:
            return t`Thailand`;
        case RegionsEnum.TimorLeste:
            return t`Timor-Leste`;
        case RegionsEnum.VietNam:
            return t`Viet Nam`;
        case RegionsEnum.Afghanistan:
            return t`Afghanistan`;
        case RegionsEnum.Bangladesh:
            return t`Bangladesh`;
        case RegionsEnum.Bhutan:
            return t`Bhutan`;
        case RegionsEnum.India:
            return t`India`;
        case RegionsEnum.Iran:
            return t`Iran (Islamic Republic of)`;
        case RegionsEnum.Maldives:
            return t`Maldives`;
        case RegionsEnum.Nepal:
            return t`Nepal`;
        case RegionsEnum.Pakistan:
            return t`Pakistan`;
        case RegionsEnum.SriLanka:
            return t`Sri Lanka`;
        case RegionsEnum.Armenia:
            return t`Armenia`;
        case RegionsEnum.Azerbaijan:
            return t`Azerbaijan`;
        case RegionsEnum.Bahrain:
            return t`Bahrain`;
        case RegionsEnum.Cyprus:
            return t`Cyprus`;
        case RegionsEnum.Georgia:
            return t`Georgia`;
        case RegionsEnum.Iraq:
            return t`Iraq`;
        case RegionsEnum.Israel:
            return t`Israel`;
        case RegionsEnum.Jordan:
            return t`Jordan`;
        case RegionsEnum.Kuwait:
            return t`Kuwait`;
        case RegionsEnum.Lebanon:
            return t`Lebanon`;
        case RegionsEnum.Oman:
            return t`Oman`;
        case RegionsEnum.Qatar:
            return t`Qatar`;
        case RegionsEnum.SaudiArabia:
            return t`Saudi Arabia`;
        case RegionsEnum.StateOfPalestine:
            return t`State of Palestine`;
        case RegionsEnum.SyrianArabRepublic:
            return t`Syrian Arab Republic`;
        case RegionsEnum.Turkey:
            return t`Turkey`;
        case RegionsEnum.UnitedArabEmirates:
            return t`United Arab Emirates`;
        case RegionsEnum.Yemen:
            return t`Yemen`;
        case RegionsEnum.Belarus:
            return t`Belarus`;
        case RegionsEnum.Bulgaria:
            return t`Bulgaria`;
        case RegionsEnum.Czechia:
            return t`Czechia`;
        case RegionsEnum.Hungary:
            return t`Hungary`;
        case RegionsEnum.Poland:
            return t`Poland`;
        case RegionsEnum.RepublicOfMoldova:
            return t`Republic of Moldova`;
        case RegionsEnum.Romania:
            return t`Romania`;
        case RegionsEnum.RussianFederation:
            return t`Russian Federation`;
        case RegionsEnum.Slovakia:
            return t`Slovakia`;
        case RegionsEnum.Ukraine:
            return t`Ukraine`;
        case RegionsEnum.AlandIslands:
            return t`Åland Islands`;
        case RegionsEnum.Guernsey:
            return t`Guernsey`;
        case RegionsEnum.Jersey:
            return t`Jersey`;
        case RegionsEnum.Sark:
            return t`Sark`;
        case RegionsEnum.Denmark:
            return t`Denmark`;
        case RegionsEnum.Estonia:
            return t`Estonia`;
        case RegionsEnum.FaroeIslands:
            return t`Faroe Islands`;
        case RegionsEnum.Finland:
            return t`Finland`;
        case RegionsEnum.Iceland:
            return t`Iceland`;
        case RegionsEnum.Ireland:
            return t`Ireland`;
        case RegionsEnum.IsleOfMan:
            return t`Isle of Man`;
        case RegionsEnum.Latvia:
            return t`Latvia`;
        case RegionsEnum.Lithuania:
            return t`Lithuania`;
        case RegionsEnum.Norway:
            return t`Norway`;
        case RegionsEnum.SvalbardAndJanMayenIslands:
            return t`Svalbard and Jan Mayen Islands`;
        case RegionsEnum.Sweden:
            return t`Sweden`;
        case RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland:
            return t`United Kingdom of Great Britain and Northern Ireland`;
        case RegionsEnum.Albania:
            return t`Albania`;
        case RegionsEnum.Andorra:
            return t`Andorra`;
        case RegionsEnum.BosniaAndHerzegovina:
            return t`Bosnia and Herzegovina`;
        case RegionsEnum.Croatia:
            return t`Croatia`;
        case RegionsEnum.Gibraltar:
            return t`Gibraltar`;
        case RegionsEnum.Greece:
            return t`Greece`;
        case RegionsEnum.HolySee:
            return t`Holy See`;
        case RegionsEnum.Italy:
            return t`Italy`;
        case RegionsEnum.Malta:
            return t`Malta`;
        case RegionsEnum.Montenegro:
            return t`Montenegro`;
        case RegionsEnum.NorthMacedonia:
            return t`North Macedonia`;
        case RegionsEnum.Portugal:
            return t`Portugal`;
        case RegionsEnum.SanMarino:
            return t`San Marino`;
        case RegionsEnum.Serbia:
            return t`Serbia`;
        case RegionsEnum.Slovenia:
            return t`Slovenia`;
        case RegionsEnum.Spain:
            return t`Spain`;
        case RegionsEnum.Austria:
            return t`Austria`;
        case RegionsEnum.Belgium:
            return t`Belgium`;
        case RegionsEnum.France:
            return t`France`;
        case RegionsEnum.Germany:
            return t`Germany`;
        case RegionsEnum.Liechtenstein:
            return t`Liechtenstein`;
        case RegionsEnum.Luxembourg:
            return t`Luxembourg`;
        case RegionsEnum.Monaco:
            return t`Monaco`;
        case RegionsEnum.Netherlands:
            return t`Netherlands`;
        case RegionsEnum.Switzerland:
            return t`Switzerland`;
        case RegionsEnum.Australia:
            return t`Australia`;
        case RegionsEnum.ChristmasIsland:
            return t`Christmas Island`;
        case RegionsEnum.CocosKeelingIslands:
            return t`Cocos (Keeling) Islands`;
        case RegionsEnum.HeardIslandAndMcDonaldIslands:
            return t`Heard Island and McDonald Islands`;
        case RegionsEnum.NewZealand:
            return t`New Zealand`;
        case RegionsEnum.NorfolkIsland:
            return t`Norfolk Island`;
        case RegionsEnum.Fiji:
            return t`Fiji`;
        case RegionsEnum.NewCaledonia:
            return t`New Caledonia`;
        case RegionsEnum.PapuaNewGuinea:
            return t`Papua New Guinea`;
        case RegionsEnum.SolomonIslands:
            return t`Solomon Islands`;
        case RegionsEnum.Vanuatu:
            return t`Vanuatu`;
        case RegionsEnum.Guam:
            return t`Guam`;
        case RegionsEnum.Kiribati:
            return t`Kiribati`;
        case RegionsEnum.MarshallIslands:
            return t`Marshall Islands`;
        case RegionsEnum.FederatedStatesOfMicronesia:
            return t`Micronesia (Federated States of)`;
        case RegionsEnum.Nauru:
            return t`Nauru`;
        case RegionsEnum.NorthernMarianaIslands:
            return t`Northern Mariana Islands`;
        case RegionsEnum.Palau:
            return t`Palau`;
        case RegionsEnum.UnitedStatesMinorOutlyingIslands:
            return t`United States Minor Outlying Islands`;
        case RegionsEnum.AmericanSamoa:
            return t`American Samoa`;
        case RegionsEnum.CookIslands:
            return t`Cook Islands`;
        case RegionsEnum.FrenchPolynesia:
            return t`French Polynesia`;
        case RegionsEnum.Niue:
            return t`Niue`;
        case RegionsEnum.Pitcairn:
            return t`Pitcairn`;
        case RegionsEnum.Samoa:
            return t`Samoa`;
        case RegionsEnum.Tokelau:
            return t`Tokelau`;
        case RegionsEnum.Tonga:
            return t`Tonga`;
        case RegionsEnum.Tuvalu:
            return t`Tuvalu`;
        case RegionsEnum.WallisAndFutunaIslands:
            return t`Wallis and Futuna Islands`;
        default:
            assertUnreachable(region);
    }
}

// Return 3 letter code for the region if one exists
export function formatRegionEnumAsISO3166Alpha3(region: RegionsEnum): string | null {
    switch (region) {
        case RegionsEnum.Algeria:
            return 'DZA';
        case RegionsEnum.Egypt:
            return 'EGY';
        case RegionsEnum.Libya:
            return 'LBY';
        case RegionsEnum.Morocco:
            return 'MAR';
        case RegionsEnum.Sudan:
            return 'SDN';
        case RegionsEnum.Tunisia:
            return 'TUN';
        case RegionsEnum.WesternSahara:
            return 'ESH';
        case RegionsEnum.BritishIndianOceanTerritory:
            return 'IOT';
        case RegionsEnum.Burundi:
            return 'BDI';
        case RegionsEnum.Comoros:
            return 'COM';
        case RegionsEnum.Djibouti:
            return 'DJI';
        case RegionsEnum.Eritrea:
            return 'ERI';
        case RegionsEnum.Ethiopia:
            return 'ETH';
        case RegionsEnum.FrenchSouthernTerritories:
            return 'ATF';
        case RegionsEnum.Kenya:
            return 'KEN';
        case RegionsEnum.Madagascar:
            return 'MDG';
        case RegionsEnum.Malawi:
            return 'MWI';
        case RegionsEnum.Mauritius:
            return 'MUS';
        case RegionsEnum.Mayotte:
            return 'MYT';
        case RegionsEnum.Mozambique:
            return 'MOZ';
        case RegionsEnum.Reunion:
            return 'REU';
        case RegionsEnum.Rwanda:
            return 'RWA';
        case RegionsEnum.Seychelles:
            return 'SYC';
        case RegionsEnum.Somalia:
            return 'SOM';
        case RegionsEnum.SouthSudan:
            return 'SSD';
        case RegionsEnum.Uganda:
            return 'UGA';
        case RegionsEnum.UnitedRepublicOfTanzania:
            return 'TZA';
        case RegionsEnum.Zambia:
            return 'ZMB';
        case RegionsEnum.Zimbabwe:
            return 'ZWE';
        case RegionsEnum.Angola:
            return 'AGO';
        case RegionsEnum.Cameroon:
            return 'CMR';
        case RegionsEnum.CentralAfricanRepublic:
            return 'CAF';
        case RegionsEnum.Chad:
            return 'TCD';
        case RegionsEnum.Congo:
            return 'COG';
        case RegionsEnum.DemocraticRepublicOfTheCongo:
            return 'COD';
        case RegionsEnum.EquatorialGuinea:
            return 'GNQ';
        case RegionsEnum.Gabon:
            return 'GAB';
        case RegionsEnum.SaoTomeAndPrincipe:
            return 'STP';
        case RegionsEnum.Botswana:
            return 'BWA';
        case RegionsEnum.Eswatini:
            return 'SWZ';
        case RegionsEnum.Lesotho:
            return 'LSO';
        case RegionsEnum.Namibia:
            return 'NAM';
        case RegionsEnum.SouthAfrica:
            return 'ZAF';
        case RegionsEnum.Benin:
            return 'BEN';
        case RegionsEnum.BurkinaFaso:
            return 'BFA';
        case RegionsEnum.CaboVerde:
            return 'CPV';
        case RegionsEnum.CoteDIvore:
            return 'CIV';
        case RegionsEnum.Gambia:
            return 'GMB';
        case RegionsEnum.Ghana:
            return 'GHA';
        case RegionsEnum.Guinea:
            return 'GIN';
        case RegionsEnum.GuineaBissau:
            return 'GNB';
        case RegionsEnum.Liberia:
            return 'LBR';
        case RegionsEnum.Mali:
            return 'MLI';
        case RegionsEnum.Mauritania:
            return 'MRT';
        case RegionsEnum.Niger:
            return 'NER';
        case RegionsEnum.Nigeria:
            return 'NGA';
        case RegionsEnum.SaintHelena:
            return 'SHN';
        case RegionsEnum.Senegal:
            return 'SEN';
        case RegionsEnum.SierraLeone:
            return 'SLE';
        case RegionsEnum.Togo:
            return 'TGO';
        case RegionsEnum.Anguilla:
            return 'AIA';
        case RegionsEnum.AntiguaAndBarbuda:
            return 'ATG';
        case RegionsEnum.Aruba:
            return 'ABW';
        case RegionsEnum.Bahamas:
            return 'BHS';
        case RegionsEnum.Barbados:
            return 'BRB';
        case RegionsEnum.BonaireAndSintEustatiusAndSaba:
            return 'BES';
        case RegionsEnum.BritishVirginIslands:
            return 'VGB';
        case RegionsEnum.CaymanIslands:
            return 'CYM';
        case RegionsEnum.Cuba:
            return 'CUB';
        case RegionsEnum.Curacao:
            return 'CUW';
        case RegionsEnum.Dominica:
            return 'DMA';
        case RegionsEnum.DominicanRepublic:
            return 'DOM';
        case RegionsEnum.Grenada:
            return 'GRD';
        case RegionsEnum.Guadeloupe:
            return 'GLP';
        case RegionsEnum.Haiti:
            return 'HTI';
        case RegionsEnum.Jamaica:
            return 'JAM';
        case RegionsEnum.Martinique:
            return 'MTQ';
        case RegionsEnum.Montserrat:
            return 'MSR';
        case RegionsEnum.PuertoRico:
            return 'PRI';
        case RegionsEnum.SaintBarthelemy:
            return 'BLM';
        case RegionsEnum.SaintKittsAndNevis:
            return 'KNA';
        case RegionsEnum.SaintLucia:
            return 'LCA';
        case RegionsEnum.SaintMartin:
            return 'MAF';
        case RegionsEnum.SaintVincentAndTheGrenadines:
            return 'VCT';
        case RegionsEnum.SintMaarten:
            return 'SXM';
        case RegionsEnum.TrinidadAndTobago:
            return 'TTO';
        case RegionsEnum.TurksAndCaicosIslands:
            return 'TCA';
        case RegionsEnum.UnitedStatesVirginIslands:
            return 'VIR';
        case RegionsEnum.Belize:
            return 'BLZ';
        case RegionsEnum.CostaRica:
            return 'CRI';
        case RegionsEnum.ElSalvador:
            return 'SLV';
        case RegionsEnum.Guatemala:
            return 'GTM';
        case RegionsEnum.Honduras:
            return 'HND';
        case RegionsEnum.Mexico:
            return 'MEX';
        case RegionsEnum.Nicaragua:
            return 'NIC';
        case RegionsEnum.Panama:
            return 'PAN';
        case RegionsEnum.Argentina:
            return 'ARG';
        case RegionsEnum.Bolivia:
            return 'BOL';
        case RegionsEnum.BouvetIsland:
            return 'BVT';
        case RegionsEnum.Brazil:
            return 'BRA';
        case RegionsEnum.Chile:
            return 'CHL';
        case RegionsEnum.Colombia:
            return 'COL';
        case RegionsEnum.Ecuador:
            return 'ECU';
        case RegionsEnum.FalklandIslands:
            return 'FLK';
        case RegionsEnum.FrenchGuiana:
            return 'GUF';
        case RegionsEnum.Guyana:
            return 'GUY';
        case RegionsEnum.Paraguay:
            return 'PRY';
        case RegionsEnum.Peru:
            return 'PER';
        case RegionsEnum.SouthGeorgiaAndTheSouthSandwichIslands:
            return 'SGS';
        case RegionsEnum.Suriname:
            return 'SUR';
        case RegionsEnum.Uruguay:
            return 'URY';
        case RegionsEnum.Venezuela:
            return 'VEN';
        case RegionsEnum.Bermuda:
            return 'BMU';
        case RegionsEnum.Canada:
            return 'CAN';
        case RegionsEnum.Greenland:
            return 'GRL';
        case RegionsEnum.SaintPierreAndMiquelon:
            return 'SPM';
        case RegionsEnum.UnitedStatesOfAmerica:
            return 'USA';
        case RegionsEnum.Antarctica:
            return 'ATA';
        case RegionsEnum.Kazakhstan:
            return 'KAZ';
        case RegionsEnum.Kyrgyzstan:
            return 'KGZ';
        case RegionsEnum.Tajikistan:
            return 'TJK';
        case RegionsEnum.Turkmenistan:
            return 'TKM';
        case RegionsEnum.Uzbekistan:
            return 'UZB';
        case RegionsEnum.China:
            return 'CHN';
        case RegionsEnum.Taiwan:
            return 'TWN';
        case RegionsEnum.HongKong:
            return 'HKG';
        case RegionsEnum.Macao:
            return 'MAC';
        case RegionsEnum.DemocraticPeoplesRepublicOfKorea:
            return 'PRK';
        case RegionsEnum.Japan:
            return 'JPN';
        case RegionsEnum.Mongolia:
            return 'MNG';
        case RegionsEnum.RepublicOfKorea:
            return 'KOR';
        case RegionsEnum.BruneiDarussalam:
            return 'BRN';
        case RegionsEnum.Cambodia:
            return 'KHM';
        case RegionsEnum.Indonesia:
            return 'IDN';
        case RegionsEnum.LaoPeoplesDemocraticRepublic:
            return 'LAO';
        case RegionsEnum.Malaysia:
            return 'MYS';
        case RegionsEnum.Myanmar:
            return 'MMR';
        case RegionsEnum.Philippines:
            return 'PHL';
        case RegionsEnum.Singapore:
            return 'SGP';
        case RegionsEnum.Thailand:
            return 'THA';
        case RegionsEnum.TimorLeste:
            return 'TLS';
        case RegionsEnum.VietNam:
            return 'VNM';
        case RegionsEnum.Afghanistan:
            return 'AFG';
        case RegionsEnum.Bangladesh:
            return 'BGD';
        case RegionsEnum.Bhutan:
            return 'BTN';
        case RegionsEnum.India:
            return 'IND';
        case RegionsEnum.Iran:
            return 'IRN';
        case RegionsEnum.Maldives:
            return 'MDV';
        case RegionsEnum.Nepal:
            return 'NPL';
        case RegionsEnum.Pakistan:
            return 'PAK';
        case RegionsEnum.SriLanka:
            return 'LKA';
        case RegionsEnum.Armenia:
            return 'ARM';
        case RegionsEnum.Azerbaijan:
            return 'AZE';
        case RegionsEnum.Bahrain:
            return 'BHR';
        case RegionsEnum.Cyprus:
            return 'CYP';
        case RegionsEnum.Georgia:
            return 'GEO';
        case RegionsEnum.Iraq:
            return 'IRQ';
        case RegionsEnum.Israel:
            return 'ISR';
        case RegionsEnum.Jordan:
            return 'JOR';
        case RegionsEnum.Kuwait:
            return 'KWT';
        case RegionsEnum.Lebanon:
            return 'LBN';
        case RegionsEnum.Oman:
            return 'OMN';
        case RegionsEnum.Qatar:
            return 'QAT';
        case RegionsEnum.SaudiArabia:
            return 'SAU';
        case RegionsEnum.StateOfPalestine:
            return 'PSE';
        case RegionsEnum.SyrianArabRepublic:
            return 'SYR';
        case RegionsEnum.Turkey:
            return 'TUR';
        case RegionsEnum.UnitedArabEmirates:
            return 'ARE';
        case RegionsEnum.Yemen:
            return 'YEM';
        case RegionsEnum.Belarus:
            return 'BLR';
        case RegionsEnum.Bulgaria:
            return 'BGR';
        case RegionsEnum.Czechia:
            return 'CZE';
        case RegionsEnum.Hungary:
            return 'HUN';
        case RegionsEnum.Poland:
            return 'POL';
        case RegionsEnum.RepublicOfMoldova:
            return 'MDA';
        case RegionsEnum.Romania:
            return 'ROU';
        case RegionsEnum.RussianFederation:
            return 'RUS';
        case RegionsEnum.Slovakia:
            return 'SVK';
        case RegionsEnum.Ukraine:
            return 'UKR';
        case RegionsEnum.AlandIslands:
            return 'ALA';
        case RegionsEnum.Guernsey:
            return 'GGY';
        case RegionsEnum.Jersey:
            return 'JEY';
        case RegionsEnum.Denmark:
            return 'DNK';
        case RegionsEnum.Estonia:
            return 'EST';
        case RegionsEnum.FaroeIslands:
            return 'FRO';
        case RegionsEnum.Finland:
            return 'FIN';
        case RegionsEnum.Iceland:
            return 'ISL';
        case RegionsEnum.Ireland:
            return 'IRL';
        case RegionsEnum.IsleOfMan:
            return 'IMN';
        case RegionsEnum.Latvia:
            return 'LVA';
        case RegionsEnum.Lithuania:
            return 'LTU';
        case RegionsEnum.Norway:
            return 'NOR';
        case RegionsEnum.SvalbardAndJanMayenIslands:
            return 'SJM';
        case RegionsEnum.Sweden:
            return 'SWE';
        case RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland:
            return 'GBR';
        case RegionsEnum.Albania:
            return 'ALB';
        case RegionsEnum.Andorra:
            return 'AND';
        case RegionsEnum.BosniaAndHerzegovina:
            return 'BIH';
        case RegionsEnum.Croatia:
            return 'HRV';
        case RegionsEnum.Gibraltar:
            return 'GIB';
        case RegionsEnum.Greece:
            return 'GRC';
        case RegionsEnum.HolySee:
            return 'VAT';
        case RegionsEnum.Italy:
            return 'ITA';
        case RegionsEnum.Malta:
            return 'MLT';
        case RegionsEnum.Montenegro:
            return 'MNE';
        case RegionsEnum.NorthMacedonia:
            return 'MKD';
        case RegionsEnum.Portugal:
            return 'PRT';
        case RegionsEnum.SanMarino:
            return 'SMR';
        case RegionsEnum.Serbia:
            return 'SRB';
        case RegionsEnum.Slovenia:
            return 'SVN';
        case RegionsEnum.Spain:
            return 'ESP';
        case RegionsEnum.Austria:
            return 'AUT';
        case RegionsEnum.Belgium:
            return 'BEL';
        case RegionsEnum.France:
            return 'FRA';
        case RegionsEnum.Germany:
            return 'DEU';
        case RegionsEnum.Liechtenstein:
            return 'LIE';
        case RegionsEnum.Luxembourg:
            return 'LUX';
        case RegionsEnum.Monaco:
            return 'MCO';
        case RegionsEnum.Netherlands:
            return 'NLD';
        case RegionsEnum.Switzerland:
            return 'CHE';
        case RegionsEnum.Australia:
            return 'AUS';
        case RegionsEnum.ChristmasIsland:
            return 'CXR';
        case RegionsEnum.CocosKeelingIslands:
            return 'CCK';
        case RegionsEnum.HeardIslandAndMcDonaldIslands:
            return 'HMD';
        case RegionsEnum.NewZealand:
            return 'NZL';
        case RegionsEnum.NorfolkIsland:
            return 'NFK';
        case RegionsEnum.Fiji:
            return 'FJI';
        case RegionsEnum.NewCaledonia:
            return 'NCL';
        case RegionsEnum.PapuaNewGuinea:
            return 'PNG';
        case RegionsEnum.SolomonIslands:
            return 'SLB';
        case RegionsEnum.Vanuatu:
            return 'VUT';
        case RegionsEnum.Guam:
            return 'GUM';
        case RegionsEnum.Kiribati:
            return 'KIR';
        case RegionsEnum.MarshallIslands:
            return 'MHL';
        case RegionsEnum.FederatedStatesOfMicronesia:
            return 'FSM';
        case RegionsEnum.Nauru:
            return 'NRU';
        case RegionsEnum.NorthernMarianaIslands:
            return 'MNP';
        case RegionsEnum.Palau:
            return 'PLW';
        case RegionsEnum.UnitedStatesMinorOutlyingIslands:
            return 'UMI';
        case RegionsEnum.AmericanSamoa:
            return 'ASM';
        case RegionsEnum.CookIslands:
            return 'COK';
        case RegionsEnum.FrenchPolynesia:
            return 'PYF';
        case RegionsEnum.Niue:
            return 'NIU';
        case RegionsEnum.Pitcairn:
            return 'PCN';
        case RegionsEnum.Samoa:
            return 'WSM';
        case RegionsEnum.Tokelau:
            return 'TKL';
        case RegionsEnum.Tonga:
            return 'TON';
        case RegionsEnum.Tuvalu:
            return 'TUV';
        case RegionsEnum.WallisAndFutunaIslands:
            return 'WLF';
        default:
            return null;
    }
}

import { t } from '@lingui/macro';
import { getLocale } from '../i18n';
import { getOrCreateNumberFormatter } from './getOrCreateNumberFormat';

interface FormatPercentageOptions {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

const defaults: FormatPercentageOptions = {
    minimumFractionDigits: 1,
};

export function formatPercentage(number: number, opts: FormatPercentageOptions = defaults): string {
    if (isNaN(number)) {
        return t`Unknown`;
    }
    const intlOpts: Intl.NumberFormatOptions = {
        style: 'percent',
        ...opts,
    };
    return getOrCreateNumberFormatter(getLocale(), intlOpts).format(number);
}

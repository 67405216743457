import { t } from '@lingui/macro';
import { formatDays } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { IpnWithMatchesFullPart, LifecycleEnum } from '@luminovo/http-client';
import { findFastestFactoryLeadTime, LifecycleChip } from '@luminovo/sourcing-core';
import { styled } from '@mui/material';
import React from 'react';
import { Skeleton } from '../../../../components/Spinners';
import { useMarketOfferTableItems } from '../../PartDetailsPage/components/OffersTab/MarketOfferTable';

interface SupplyChainSummaryBoxesProps {
    lifecycle: LifecycleEnum;
    factoryLeadTime: string;
    externalStock: string;
    availableInventory: string;
}

export const SupplyChainIpnSummary = ({ ipn }: { ipn: IpnWithMatchesFullPart }): JSX.Element => {
    const { data: marketOfferData } = useMarketOfferTableItems({
        marketOfferPart: { type: 'Ipn', ipn: ipn.id },
        requiredQuantity: null,
        rfqContext: { type: 'OutsideRfQ' },
    });

    if (!marketOfferData) {
        return <Skeleton width="100%" height={200} />;
    }
    const fastestFactoryLeadTime = marketOfferData.length > 0 ? findFastestFactoryLeadTime(marketOfferData) : undefined;

    const availableInventory = t`Coming soon`;
    const externalStock = t`Coming soon`;

    return (
        <SupplyChainSummaryBoxes
            availableInventory={availableInventory}
            externalStock={externalStock}
            factoryLeadTime={fastestFactoryLeadTime ? formatDays(fastestFactoryLeadTime.factoryLeadTime) : 'N/A'}
            lifecycle={ipn.lifecycle_status}
        />
    );
};

const SupplyChainSummaryBoxes: React.FC<SupplyChainSummaryBoxesProps> = ({
    lifecycle,
    factoryLeadTime,
    externalStock,
    availableInventory,
}) => {
    return (
        <Container>
            <TopRow>
                <TopLeftBox>
                    <Text variant="body-small" color={colorSystem.neutral[7]}>
                        Lifecycle
                    </Text>
                    <LifecycleChip lifecycle={lifecycle} />
                </TopLeftBox>
                <TopRightBox>
                    <Text variant="body-small" color={colorSystem.neutral[7]}>
                        Std. factory lead time
                    </Text>
                    <Text variant="h4" color={colorSystem.neutral[9]}>
                        {factoryLeadTime}
                    </Text>
                </TopRightBox>
            </TopRow>
            <BottomRow>
                <BottomLeftBox>
                    <Text variant="body-small" color={colorSystem.neutral[7]}>
                        External stock
                    </Text>
                    <Text variant="h4" color={colorSystem.neutral[9]}>
                        {externalStock}
                    </Text>
                </BottomLeftBox>
                <BottomRightBox>
                    <Text variant="body-small" color={colorSystem.neutral[7]}>
                        Available inventory
                    </Text>
                    <Text variant="h4" color={colorSystem.neutral[9]}>
                        {availableInventory}
                    </Text>
                </BottomRightBox>
            </BottomRow>
        </Container>
    );
};

const Container = styled(Flexbox)({
    flexDirection: 'column',
    width: '100%',
});

const Row = styled(Flexbox)({
    width: '100%',
});

const TopRow = styled(Row)({
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    overflow: 'hidden',
});

const BottomRow = styled(Row)({
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    overflow: 'hidden',
});

const borderStyle = `1px solid ${colorSystem.neutral[2]}`;

const Box = styled(Flexbox)({
    flex: 1,
    flexDirection: 'column',
    gap: '8px',
    padding: '12px 16px',
    background: colorSystem.neutral[0],
});

const TopLeftBox = styled(Box)({
    borderTopLeftRadius: '8px',
    borderLeft: borderStyle,
    borderTop: borderStyle,
    borderBottom: borderStyle,
});

const TopRightBox = styled(Box)({
    borderTopRightRadius: '8px',
    border: borderStyle,
});

const BottomLeftBox = styled(Box)({
    borderBottomLeftRadius: '8px',
    borderLeft: borderStyle,
    borderBottom: borderStyle,
    borderRight: borderStyle,
});

const BottomRightBox = styled(Box)({
    borderBottomRightRadius: '8px',
    borderRight: borderStyle,
    borderBottom: borderStyle,
});

import { FullPart, isGenericFullPart, isOtsComponentFull, isOtsFullPart } from '@luminovo/http-client';
import { LifecycleChipWithTooltip } from './LifecycleChipWithTooltip';
import { OtsPartLifecycleStatusOriginsTooltip } from './OtsPartLifecycleStatusOriginsTooltip';

export const PartLifecycleView = ({ part, disabled = false }: { part: FullPart; disabled?: boolean }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <OtsPartLifecycleStatusOriginsTooltip partId={part.id} lifecycleStatus={part.lifecycle_status}>
                <LifecycleChipWithTooltip status={part.lifecycle_status} disabled={disabled} disabledTooltip={true} />
            </OtsPartLifecycleStatusOriginsTooltip>
        );
    }
    if (isOtsComponentFull(part) || isGenericFullPart(part)) {
        return <LifecycleChipWithTooltip status={part.lifecycle_status} disabled={disabled} />;
    }
    return <></>;
};

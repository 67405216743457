import { instantiateParser } from '../parsers/parsers';
import {
    ImportRecord,
    ImportStatus,
    ImporterCell,
    ImporterConfig,
    ImporterRow,
    ImporterTable,
    LinkStatus,
    ParseStatus,
    Position,
    Table,
} from '../types';
import { compareParseStatus } from './compareParseStatus';

/**
 * An implementation of the {@link ImporterTable} interface that is immutable.
 */
export class ImmutableImporterTable implements ImporterTable {
    /**
     * Cache for duplicates of unique columns.
     */
    private duplicates: Array<{ columnIndex: number; duplicates: number; text: string }>;

    constructor(
        private rows: ImporterRow[],
        private config: ImporterConfig,
    ) {
        // calculate duplicates and cache them for performance
        const firstRowCells = this.rows[0]?.cells ?? [];

        // Take all unique columns by inspecting the first row
        const uniqueColumns = firstRowCells.flatMap((cell, index) => (cell.field.unique ? [index] : []));

        // For each unique column, find all duplicates
        this.duplicates = uniqueColumns.flatMap((columnIndex) => {
            const uniqueValues = new Map<string, number>();

            for (let i = 0; i < this.rows.length; i++) {
                const row = this.rows[i];
                // skip rows that are not done
                if (row.status === 'skipped' || row.skip) {
                    continue;
                }
                const cell = this.getCell({ row: i, column: columnIndex });
                if (cell && cell.text.length > 0) {
                    const count = uniqueValues.get(cell.text) ?? 0;
                    uniqueValues.set(cell.text, count + 1);
                }
            }

            const entries = Array.from(uniqueValues.entries()).filter(([_, count]) => count > 1);
            return entries.map(([text, duplicates]) => ({ columnIndex, duplicates, text }));
        });
    }

    public static fromTable(table: Table, config: ImporterConfig): ImmutableImporterTable {
        const rows = table.map((row, index): ImporterRow => {
            const cells = config.fields.map((field): ImporterCell => {
                const cells = field.columnIndices.map((colIndex) => row[colIndex]);
                const cell = cells.join('\n');

                return {
                    field,
                    status: { status: 'pending' },
                    text: cell,
                };
            });

            // On an import: skip the row by default on these conditions:
            // - all cells are empty
            // - it's the first row (it's usually the header row)
            const skipRow = cells.every((cell) => cell.text.trim().length === 0) || index === 0;

            return new ImmutableRow(index, cells, skipRow, undefined);
        });

        return new ImmutableImporterTable(rows, config);
    }

    getRows(): ImporterRow[] {
        return this.rows;
    }

    updateRows(rows: ImporterRow[]): ImporterTable {
        const newRows = this.rows.map((row) => {
            const newRow = rows.find((r) => r.index === row.index);
            if (newRow) {
                return newRow;
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config);
    }

    async applyParsers(range: {
        row: { from: number; to: number };
        column: { from: number; to: number };
    }): Promise<ImporterTable> {
        const newRows: ImporterRow[] = [];
        for (const row of this.rows) {
            const newCells: ImporterCell[] = [];
            for (const cell of row.cells) {
                // check if the cell is in the range [from,to)
                const isInRowRange = row.index >= range.row.from && row.index < range.row.to;
                const isInColumnRange = true;
                const isInRange = isInRowRange && isInColumnRange;

                if (!isInRange) {
                    newCells.push(cell);
                    continue;
                }

                const parserFn = instantiateParser(cell.field.parser, cell.field);
                try {
                    const parsed = await parserFn([cell.text]);
                    newCells.push({
                        ...cell,
                        status: parsed,
                    });
                } catch (e) {
                    newCells.push({
                        ...cell,
                        status: { status: 'error', message: String(e) },
                    });
                }
            }
            const newRow = new ImmutableRow(row.index, newCells, row.skip, row.import);
            newRows.push(newRow);
        }
        return new ImmutableImporterTable(newRows, this.config);
    }

    excludeErrors(): ImporterTable {
        const newRows = this.rows.map((row) => {
            if (row.status === 'error') {
                return new ImmutableRow(row.index, row.cells, true, row.import);
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config);
    }

    excludeAll(exclude: boolean, indices: number[]): ImporterTable {
        const newRows = this.rows.map((row) => {
            if (indices.includes(row.index)) {
                return new ImmutableRow(row.index, row.cells, exclude, row.import);
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config);
    }

    skipRow(index: number): ImporterTable {
        const row = this.getRow(index);
        if (!row) {
            return this;
        }
        const newRow = new ImmutableRow(row.index, row.cells, !Boolean(row.skip), row.import);
        return this.updateRows([newRow]);
    }

    countIdenticalMatches(colIndex: number, text: string): number {
        let count = 0;

        for (let i = 0; i < this.rows.length; i++) {
            const cell = this.getCell({ row: i, column: colIndex });
            if (cell?.text === text) {
                count++;
            }
        }
        return count;
    }

    countIncluded(): number {
        return this.rows.filter((row) => row.status !== 'skipped').length;
    }

    filterByParseStatus(status: ParseStatus | null): ImporterRow[] {
        if (status === null) {
            return this.rows;
        }
        return this.rows.filter((row) => row.status === status);
    }

    filterByLinkStatus(status: LinkStatus | null): ImporterRow[] {
        if (status === null) {
            return this.rows;
        }
        return this.rows.filter((row) => (row.record?.action ?? 'skipped') === status);
    }

    getReadyForPreviewPercentage(): number {
        const total = this.rows.length;
        const done = this.rows.filter((row) => row.status !== 'pending').length;
        return 100 * (done / total);
    }

    isReadyForPreview(): boolean {
        // if there are duplicates, we can't proceed
        if (this.duplicates.length > 0) {
            return false;
        }
        return this.rows.every((row) => row.status === 'done' || row.status === 'skipped' || row.status === 'warning');
    }

    isReadyForImport(): boolean {
        return this.rows.some((row) => row.record && row.record.action !== 'skipped');
    }

    setCell(position: Position, newCell: ImporterCell): ImporterTable {
        const row = this.getRow(position.row);
        if (!row) {
            // do nothing if the row doesn't exist
            return this;
        }

        const newCells = row.cells.map((cell, index) => {
            if (index === position.column) {
                return newCell;
            }
            return cell;
        });
        const newRow = new ImmutableRow(row.index, newCells, row.skip, row.import);

        return this.updateRows([newRow]);
    }

    setMatching(position: Pick<Position, 'column'>, text: string, newCell: ImporterCell): ImporterTable {
        let newTable: ImporterTable = this;
        for (let i = 0; i < this.rows.length; i++) {
            const cell = newTable.getCell({ row: i, column: position.column });
            if (cell?.text === text) {
                newTable = newTable.setCell({ row: i, column: position.column }, { ...newCell });
            }
        }
        return newTable;
    }

    getUniqueColumnsWithDuplicates(): { columnIndex: number; duplicates: number; text: string }[] {
        return this.duplicates;
    }

    getCell(position: Position): ImporterCell | undefined {
        const row = this.getRow(position.row);
        if (!row) {
            return undefined;
        }
        return row.cells[position.column];
    }

    getRow(index: number): ImporterRow | undefined {
        return this.rows[index];
    }

    getSize(): number {
        return this.rows.length;
    }

    getParseStatusCount(): Record<ParseStatus, number> {
        const counts: Record<ParseStatus, number> = {
            pending: 0,
            done: 0,
            error: 0,
            skipped: 0,
            warning: 0,
        };
        for (const row of this.rows) {
            counts[row.status]++;
        }
        return counts;
    }

    getLinkStatusCount(): Record<LinkStatus, number> {
        const counts: Record<LinkStatus, number> = {
            skipped: 0,
            insert: 0,
            update: 0,
        };
        for (const row of this.rows) {
            if (row.status === 'error' || row.status === 'pending') {
                // skip
                continue;
            } else if (row.status === 'skipped' || !row.record) {
                counts['skipped']++;
            } else if (row.record.action === 'insert') {
                counts['insert']++;
            } else if (row.record.action === 'update') {
                counts['update']++;
            }
        }
        return counts;
    }

    getImportStatusCount(): Record<'done' | 'error' | 'skipped', number> {
        const counts: Record<'done' | 'error' | 'skipped', number> = {
            done: 0,
            error: 0,
            skipped: 0,
        };
        for (const row of this.rows) {
            if (!row.import) {
                counts['skipped']++;
            } else if (row.import.success === true) {
                counts['done']++;
            } else if (row.import.success === false) {
                counts['error']++;
            }
        }
        return counts;
    }

    getImportRecords(): ImportRecord<ImporterConfig>[] {
        return this.rows.flatMap((row) => {
            if (row.record && row.record.action !== 'skipped') {
                return [row.record];
            }
            return [];
        });
    }
}

class ImmutableRow implements ImporterRow {
    record?: ImportRecord<ImporterConfig> | undefined;
    status: ParseStatus;
    import?: ImportStatus | undefined;

    // eslint-disable-next-line max-params
    constructor(
        public index: number,
        public cells: ImporterCell[],
        public skip: boolean = false,
        importStatus: ImportStatus | undefined,
    ) {
        if (cells.length === 0) {
            throw new Error('Row must have at least one cell');
        }
        this.status = skip
            ? 'skipped'
            : cells.map((cell) => cell.status.status).sort(compareParseStatus)[0] ?? 'skipped';
        this.import = importStatus;
        this.record = this.status === 'done' || this.status === 'warning' ? this.createRecord() : undefined;
    }

    private createRecord(): ImportRecord<ImporterConfig> {
        let action: LinkStatus = 'insert';
        const record: Record<string, unknown> = {};
        for (const cell of this.cells) {
            if ('value' in cell.status) {
                record[cell.field.id] = cell.status.value.id;

                if (cell.status.value.existing === true) {
                    action = 'update';
                }
            } else {
                // if any cell is not done, we can't set the record
                return {
                    action: 'skipped',
                    data: {},
                };
            }
        }
        return {
            action,
            data: record as any,
        };
    }
}

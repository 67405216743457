/* eslint-disable camelcase */
import { UseQueryResult } from '@tanstack/react-query';
import { useSynth } from '../../../components/contexts/SynthQLContext';
import { QuoteRequest, quoteRequest } from './queries';

export function useQuoteRequest({ quoteRequestId }: { quoteRequestId?: number }): UseQueryResult<QuoteRequest> {
    const query = quoteRequest()
        .where({
            id: quoteRequestId,
        })
        .firstOrThrow();

    return useSynth({ query, enabled: !!quoteRequestId });
}

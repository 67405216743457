interface HasFactoryLeadTime {
    factoryLeadTime: number | null;
}
export const findFastestFactoryLeadTime = <T extends HasFactoryLeadTime>(marketOfferData: T[]): T | undefined => {
    if (marketOfferData.length === 0) return undefined;
    if (marketOfferData.every((offer) => offer.factoryLeadTime === null)) return marketOfferData[0];

    return marketOfferData.reduce((fastest, current) => {
        if (fastest.factoryLeadTime === null) return current;
        if (current.factoryLeadTime === null) return fastest;
        return current.factoryLeadTime < fastest.factoryLeadTime ? current : fastest;
    });
};

import { Plural, t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { assertUnreachable, Currency, formatRelativeTime, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    DestructiveTertiaryIconButton,
    FieldCheckboxControlled,
    FieldController,
    FieldDateControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldSelectCreatableControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Message,
    SecondaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import {
    OnOrderDTO,
    Option,
    Packaging,
    PriceType,
    QuantityUnit,
    QuantityUnitType,
    RegionsEnum,
    RfqContext,
    StandardPartOfferInputDTO,
    SupplierAndStockLocationDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
    ValidFor,
} from '@luminovo/http-client';
import {
    FieldPackaging,
    formatSupplierAndStockLocationDTO,
    formatValidFor,
    QuantityUnitRecordPlural,
    QuantityUnitRecordSingular,
} from '@luminovo/sourcing-core';
import { Add, Close } from '@mui/icons-material';
import { Box, Divider, InputAdornment, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { transEnum } from '../../../components/localization/TransEnum';
import { PartCard } from '../../../components/partSpecificationCards/PartCard';
import { LoadingText } from '../../../components/Spinners';
import { inputCurrenciesPublicTranslations } from '../../../resources/currencyInputTypes';
import { priceTypeTranslations } from '../../../resources/offer/i18n';
import { useIpn, useOtsFullPart } from '../../../resources/part/partHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../../utils/routes';
import { useDialogAddSupplierAndStockLocation } from '../../SupplierManagement/components/SupplierDialogs/AddSupplierDialog';

export type PriceBreakInput = {
    moq: number;
    unitPrice: number | null;
    mpq: number;
    leadTime?: number | null;
};

export type StandardPartOfferFormValues = {
    linkedPart: StandardPartOfferInputDTO['linked_part'];
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    supplierPartNumber: string;
    packaging?: Option<Packaging>;
    quantity: number;
    quantityUnit: QuantityUnitType;
    validUntilDate?: string;
    rfqId: Option<string>;
    customerId: Option<string>;
    priceType: PriceType;
    currency: Currency;
    stock?: Option<number>;
    factoryQuantity?: Option<number>;
    factoryLeadTime?: Option<number>;
    onOrder: OnOrderDTO[];
    priceBreaks: PriceBreakInput[];
    notes?: Option<string>;
    offerNumber?: Option<string>;
    validFor: ValidFor;
    ncnr?: Option<boolean>;
};

export function StandardPartOfferForm({
    onSubmit,
    defaultValues,
    rfqContext,
    disabledQuantityUnit,
    rfqId,
    sourcingScenarioId,
}: {
    onSubmit: (form: StandardPartOfferFormValues) => Promise<void>;
    defaultValues: StandardPartOfferFormValues;
    rfqContext: RfqContext;
    disabledQuantityUnit: boolean;
    rfqId?: string;
    sourcingScenarioId?: string;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`Offer information`}>
                    <FormItemStandardPart rfqContext={rfqContext} />
                    <FormItemSupplier rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />
                    <FormItemSku />
                    <FormItemPackaging />
                    <FormItemPriceType />
                    <FormItemUnitOfMeasurement disabledQuantityUnit={disabledQuantityUnit} />
                </FormSection>
                <Divider />
                <FormSection title={t`Availability`}>
                    <FormItemStock />
                    <FormItemFactoryQuantity />
                    <FormItemFactoryLeadTime />
                    <FormItemOnOrder />
                </FormSection>
                <Divider />
                <FormSection title={t`Price breaks`}>
                    <FormItemCurrency />
                    <FormItemPriceBreaks />
                </FormSection>
                <Divider />
                <FormSection title={t`Offer validity`}>
                    <FormItemOfferNumber />
                    <FormItemValidUntilDate />
                    <FormItemValidForCustomerOrProject />
                </FormSection>
                <Divider />
                <FormSection title={t`Additional information`}>
                    <FormItemNcnr />
                    <FormItemNotes />
                </FormSection>
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end" marginTop={4}>
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function CancelButton() {
    const history = useHistory();
    const handleClose = React.useCallback(() => {
        history.goBack();
    }, [history]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemStandardPart({ rfqContext }: { rfqContext: RfqContext }) {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const linkedPart = useWatch({ control, name: 'linkedPart' });

    if (linkedPart.type === 'OffTheShelf') {
        return <FormItemOffTheShelfPart partId={linkedPart.id} rfqContext={rfqContext} />;
    }

    if (linkedPart.type === 'InternalPartNumber') {
        return <FormItemInternalPartNumberPart ipnId={linkedPart.id} rfqContext={rfqContext} />;
    }

    assertUnreachable(linkedPart);
}

function FormItemOffTheShelfPart({ partId, rfqContext }: { partId: string; rfqContext: RfqContext }) {
    const { data: part } = useOtsFullPart({ partOptionId: partId, rfqContext });

    return (
        <FormItem label={t`Part`}>
            <PartCard part={part} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemInternalPartNumberPart({ ipnId, rfqContext }: { ipnId: string; rfqContext: RfqContext }) {
    const { data: part } = useIpn(ipnId, rfqContext);

    return (
        <FormItem label={t`IPN`}>
            <PartCard part={part} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemSupplier({ rfqId, sourcingScenarioId }: { rfqId?: string; sourcingScenarioId?: string }) {
    const { control, setValue } = useFormContext<StandardPartOfferFormValues>();

    const hasCreateSupplierPermission = useHasPermission(['create:supplier']);
    const { data: supplierAndStockLocations = [], isLoading } = useNonExcludedSupplierAndStockLocations();
    const { openDialog } = useDialogAddSupplierAndStockLocation({
        onSuccessCallback: (s) => {
            setValue('supplierAndStockLocation', s, { shouldValidate: true });
        },
        disableSupplierPreferenceField: true,
    });

    if (isLoading) {
        return <LoadingText />;
    }

    return (
        <FormItem
            label={t`Supplier`}
            required
            actions={
                isPresent(rfqId) && isPresent(sourcingScenarioId) ? (
                    <TertiaryButton
                        size={'small'}
                        onClick={() => {
                            window.open(
                                route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`, {
                                    rfqId,
                                    sourcingScenarioId,
                                }),
                                '_blank',
                                'noopener noreferrer',
                            );
                        }}
                    >
                        Edit supplier preferences
                    </TertiaryButton>
                ) : undefined
            }
        >
            <FieldSelectCreatableControlled
                control={control}
                name="supplierAndStockLocation"
                required
                FieldProps={{
                    options: supplierAndStockLocations,
                    getOptionKey: (option) => option.id,
                    getOptionLabel: (option) => formatSupplierAndStockLocationDTO(option),
                    renderOption: (option) => {
                        return (
                            <Flexbox flexDirection={'column'} gap={4} maxWidth={'100%'}>
                                <Text variant={'body'}>{formatSupplierAndStockLocationDTO(option)}</Text>
                                <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                                    {option.supplier_number}
                                </Text>
                            </Flexbox>
                        );
                    },
                    action: {
                        label: t`Add new supplier`,
                        disabled: !hasCreateSupplierPermission,
                        onClick: (newValue) => {
                            openDialog({
                                name: newValue,
                                stockLocation: RegionsEnum.Unknown,
                                supplierNumber: '',
                                supplierPreference: SupplierPreference.NotApproved,
                                supplierType: SupplierType.Distributor,
                                supplierPartType: SupplierPartType.OffTheShelf,
                            });
                        },
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemSku() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Supplier part number`} required>
            <FieldTextControlled
                name="supplierPartNumber"
                required
                control={control}
                FieldProps={{
                    placeholder: t`Supplier part number`,
                }}
            />
        </FormItem>
    );
}

function FormItemPackaging() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Packaging`}>
            <FieldController control={control} name="packaging" Field={FieldPackaging} />
        </FormItem>
    );
}

function FormItemPriceType() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Type`} required>
            <FieldSelectControlled
                control={control}
                name="priceType"
                required
                FieldProps={{
                    options: Object.values(PriceType).filter(
                        (x) => x !== PriceType.PurchasePrice && x !== PriceType.StandardPrice,
                    ),
                    getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemUnitOfMeasurement({ disabledQuantityUnit }: { disabledQuantityUnit: boolean }) {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const [editMode, setEditMode] = React.useState(false);

    return (
        <FormItem
            label={t`Unit of measurement`}
            required
            description={
                <Trans>
                    All other quantities are multiplied by the unit of measurement to obtain the total quantity. For
                    example, if the unit of measurement is “10 meters” and you have “2” in stock, then we calculate this
                    as 2 * 10 meters = 20 meters in stock
                </Trans>
            }
            actions={
                <TertiaryButton
                    size="small"
                    onClick={() => setEditMode((disabledQuantityUnit) => !disabledQuantityUnit)}
                >
                    <Trans>Edit unit of measurement</Trans>
                </TertiaryButton>
            }
        >
            {editMode && (
                <Message
                    size={'small'}
                    variant={'yellow'}
                    attention={'low'}
                    message={t`Modifying the unit of measurement will affect both quantity and stock`}
                />
            )}
            <Box display={'grid'} gridTemplateColumns="1fr 120px" columnGap={'8px'}>
                <FieldNumericControlled
                    control={control}
                    name="quantity"
                    required={true}
                    min={1}
                    FieldProps={{
                        helperText: disabledQuantityUnit
                            ? t`The design item does not support a different unit of measurement.`
                            : undefined,
                        disabled: !editMode,
                    }}
                />
                <FieldSelectControlled
                    control={control}
                    name="quantityUnit"
                    FieldProps={{
                        options: Object.values(QuantityUnit),
                        getOptionLabel: (option) => transEnum(option, QuantityUnitRecordSingular),
                        disabled: disabledQuantityUnit || !editMode,
                    }}
                />
            </Box>
        </FormItem>
    );
}

function FormItemFactoryLeadTime() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const factoryLeadTimeInDays = useWatch({ control, name: 'factoryLeadTime' });
    const weeks = Math.floor((factoryLeadTimeInDays ?? 0) / 7);
    const remainingDays = (factoryLeadTimeInDays ?? 0) % 7;

    return (
        <FormItem
            label={t`Standard factory lead time`}
            description={t`The estimated time between placing an order, and the manufacturer completing the order. Leave empty if unknown.`}
        >
            <FieldNumericControlled
                control={control}
                name="factoryLeadTime"
                min={1}
                isInteger
                FieldProps={{
                    disabled: !isPresent(unitOfMeasurement),
                    placeholder: t`Standard factory lead time`,
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography>
                                    <Trans>Days</Trans>
                                </Typography>
                            </InputAdornment>
                        ),
                    },
                }}
            />
            {weeks > 0 && (
                <Typography>
                    <Plural value={weeks} one={`# week`} other={`# weeks`} />{' '}
                    {remainingDays > 0 && <Plural value={remainingDays} one={`and # day`} other={`and # days`} />}
                </Typography>
            )}
        </FormItem>
    );
}

function FormItemCurrency() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Currency`} required>
            <FieldSelectControlled
                control={control}
                name="currency"
                required
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemStock() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    return (
        <FormItem label={t`Stock`} description={t`Enter the quantity available in stock.`}>
            <FieldNumericControlled
                control={control}
                name="stock"
                min={0}
                isInteger
                FieldProps={{
                    placeholder: t`Stock`,
                    InputProps: {
                        endAdornment:
                            disabled || unitOfMeasurement === 1 ? undefined : (
                                <InputAdornment position="end">
                                    {`× ${unitOfMeasurement} ${transEnum(unit, QuantityUnitRecordPlural)}`}
                                </InputAdornment>
                            ),
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemFactoryQuantity() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    return (
        <FormItem label={t`Factory quantity`}>
            <FieldNumericControlled
                control={control}
                name="factoryQuantity"
                min={0}
                isInteger
                FieldProps={{
                    disabled: disabled,
                    placeholder: t`Factory quantity`,
                    InputProps: {
                        endAdornment:
                            disabled || unitOfMeasurement === 1 ? undefined : (
                                <InputAdornment position="end">
                                    {`× ${unitOfMeasurement} ${transEnum(unit, QuantityUnitRecordPlural)}`}
                                </InputAdornment>
                            ),
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemOnOrder() {
    const { control } = useFormContext<StandardPartOfferFormValues>();

    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);
    const { fields, append, remove } = useFieldArray({ control, name: 'onOrder' });

    return (
        <FormItem label={t`On order`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns="1fr 1fr auto"
                rowGap={'16px'}
                columnGap={'16px'}
            >
                {fields.length > 0 && (
                    <>
                        <Typography color="textSecondary">
                            <Trans>Quantity</Trans>*
                        </Typography>
                        <Typography color="textSecondary">
                            <Trans>Date</Trans>
                        </Typography>
                        <span />
                    </>
                )}
                {fields.map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FieldNumericControlled
                                control={control}
                                name={`onOrder.${index}.quantity`}
                                min={1}
                                required
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldDateControlled control={control} name={`onOrder.${index}.date`} />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            quantity: 0,
                            date: null,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add on order quantity</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemPriceBreaks() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    const { fields, append, remove } = useFieldArray({ control, name: 'priceBreaks' });

    // we hide the lead time column for now (unless it's already set)
    // we will likely remove it completely in the future
    const isLeadTimePresent = fields.some((x) => isPresent(x.leadTime));

    return (
        <FormItem label={t`Price breaks`}>
            <Box
                display={'grid'}
                alignItems="top"
                gridTemplateColumns={isLeadTimePresent ? '1fr 1fr 1fr 1fr auto' : '1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>MOQ</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Unit price</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>MPQ</Trans>*
                </Typography>
                {isLeadTimePresent && (
                    <Typography color="textSecondary">
                        <Trans>Lead time</Trans>
                    </Typography>
                )}
                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.moq`}
                                min={1}
                                required
                                FieldProps={{
                                    placeholder: t`MOQ`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.unitPrice`}
                                min={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.mpq`}
                                min={1}
                                required
                                FieldProps={{
                                    placeholder: t`MPQ`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            {isLeadTimePresent && (
                                <FieldNumericControlled
                                    control={control}
                                    name={`priceBreaks.${index}.leadTime`}
                                    min={1}
                                    FieldProps={{
                                        InputProps: {
                                            placeholder: t`Optional`,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Trans>Days</Trans>
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                            )}
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            moq: fields.map((x) => x.moq).reduce((a, b) => Math.max(a, b), 1),
                            mpq: fields.map((x) => x.mpq).reduce((a, b) => Math.max(a, b), 1),
                            unitPrice: 0,
                            leadTime: undefined,
                        });
                    }}
                    size="medium"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Trans>Add price break</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemValidUntilDate() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const validUntilDate = useWatch({ control, name: 'validUntilDate' });

    return (
        <FormItem label={t`Valid until`}>
            <FieldDateControlled control={control} name={'validUntilDate'} />
            {validUntilDate && <Typography>{formatRelativeTime(validUntilDate)}.</Typography>}
        </FormItem>
    );
}

function FormItemValidForCustomerOrProject() {
    const { control } = useFormContext<StandardPartOfferFormValues>();

    const { customerId, rfqId } = useWatch({ control });

    return (
        <FormItem label={t`Valid for`}>
            <FieldSelectControlled
                control={control}
                name="validFor"
                required
                FieldProps={{
                    disabled: !customerId || !rfqId,
                    placeholder: t`Valid for`,
                    getOptionLabel: formatValidFor,
                    options: Object.values(ValidFor),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemOfferNumber() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Offer number`} description={t`The unique identifier for the offer in your system.`}>
            <FieldTextControlled control={control} name="offerNumber" />
        </FormItem>
    );
}

function FormItemNcnr() {
    const { control } = useFormContext<StandardPartOfferFormValues>();

    return (
        <Flexbox alignItems="center" gap={8}>
            <FieldCheckboxControlled name="ncnr" control={control} />
            <Text>
                <Trans>NCNR</Trans>
            </Text>
        </Flexbox>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled control={control} name="notes" FieldProps={{ multiline: true, minRows: 2 }} />
        </FormItem>
    );
}

import { t } from '@lingui/macro';
import {
    assertUnreachable,
    formatInteger,
    formatToLongDate,
    formatToMonth,
    isPresent,
    lastDayOfMonth,
} from '@luminovo/commons';
import { TanStackTable, Tooltip, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import { ColumnDefWithSharedContext } from '@luminovo/design-system/src/components/TanStackTable/type';
import { DemandSummaryDTO, DemandTypeDTO } from '@luminovo/http-client';
import { TableCell } from '@mui/material';
import { NoDemandsMessage } from '../../components/NoDemandsMessage';
import { getParticipantNumber } from './getParticipantNumber';

type Row = string[];
type Table = Array<Row>;

interface TableFields {
    type: 'string';
    id: string;
    label?: string;
    columnIndex: number;
    tooltip?: string;
}

export function transformDemandSummaryToTable({
    demandSummary,
    demandType,
}: {
    demandSummary: DemandSummaryDTO | undefined;
    demandType: DemandTypeDTO;
}): {
    tableFields: TableFields[];
    table: Table;
} {
    if (!isPresent(demandSummary)) {
        return { tableFields: [], table: [] };
    }

    let participantDemands = [];
    let label = '';

    switch (demandSummary.participant_role) {
        case 'supplier':
            participantDemands = demandSummary.supplier_demands;
            label = t`Supplier`;
            break;
        case 'recipient':
            participantDemands = demandSummary.recipient_demands;
            label = t`Recipient`;
            break;
        default:
            assertUnreachable(demandSummary);
    }

    if (participantDemands.length === 0) {
        return { tableFields: [], table: [] };
    }

    const timeframes = participantDemands[0].aggregated_demands.map((item) => item.timeframe);

    const tableFields: TableFields[] = [
        {
            id: 'Participant',
            columnIndex: 0,
            type: 'string',
            label,
        },
        ...timeframes.map((timeframe, index) => {
            let { id, dateStart, dateEnd } = (() => {
                switch (timeframe.type) {
                    case 'yearly':
                        return {
                            id: timeframe.year.toString(),
                            dateStart: new Date(`${timeframe.year}-01-01`),
                            dateEnd: new Date(`${timeframe.year}-12-31`),
                        };
                    case 'monthly':
                        let dateStart = new Date(`${timeframe.year}-${timeframe.month.toString().padStart(2, '0')}-01`);
                        return {
                            id: formatToMonth(dateStart),
                            dateStart,
                            dateEnd: lastDayOfMonth(dateStart),
                        };
                    default:
                        assertUnreachable(timeframe);
                }
            })();
            dateStart = new Date(Math.max(dateStart.getTime(), new Date(demandSummary.start_date).getTime()));
            dateEnd = new Date(Math.min(dateEnd.getTime(), new Date(demandSummary.end_date).getTime()));
            return {
                id,
                columnIndex: index + 1,
                type: 'string' as const,
                tooltip: `${formatToLongDate(dateStart)} - ${formatToLongDate(dateEnd)}`,
            };
        }),
    ];

    const table: Table = participantDemands.map((participantDemand) => [
        getParticipantNumber(participantDemand),
        ...participantDemand.aggregated_demands.map((item) =>
            formatInteger(demandType === 'gross' ? item.gross_quantity.quantity : item.net_quantity.quantity),
        ),
    ]);

    return { tableFields, table };
}

const columnHelper = createColumnHelper<Row>();

export function DemandSummaryTableDynamic({
    demandSummary,
    demandType,
}: {
    demandSummary: DemandSummaryDTO;
    demandType: DemandTypeDTO;
}): JSX.Element {
    const { table, tableFields } = transformDemandSummaryToTable({
        demandSummary,
        demandType,
    });

    let columns: ColumnDefWithSharedContext<Row, string, unknown>[] = [];

    if (table.length > 0) {
        columns = Object.keys(table[0]).map((_key, colIndex) => {
            const selected = tableFields.find((option) => option.columnIndex === colIndex) ?? null;
            const label = selected ? selected.label ?? selected.id : `Column #${colIndex + 1}`;
            const tooltip = selected?.tooltip;
            const header = (
                <TableCell style={{ verticalAlign: 'middle', paddingLeft: 0 }} variant="head">
                    {label}
                </TableCell>
            );
            return columnHelper.text((row) => row[colIndex] ?? '', {
                id: String(colIndex),
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: true,
                enableMultiSort: false,
                label: () => label,
                header: function Header() {
                    if (isPresent(tooltip)) {
                        return (
                            <Tooltip title={tooltip} placement="top">
                                {header}
                            </Tooltip>
                        );
                    }
                    return header;
                },
                size: 100,
            });
        });
    }

    const tanstackTable = useTanStackTable({
        columns,
        data: table,
        enablePersistentGlobalFilter: true,
        enableColumnOrdering: false,
        enablePersistentColumnFilters: false,
    });

    return (
        <TanStackTable
            size="medium"
            enableMenuBar={false}
            table={tanstackTable.table}
            EmptyPlaceholder={() => <NoDemandsMessage />}
        />
    );
}

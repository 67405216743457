import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { I18nProvider } from '@lingui/react';
import { CenteredLayout, DefaultSnackbarProviderProps } from '@luminovo/design-system';
import { CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Route, Router, Switch } from 'react-router';
import AuthenticatedApp from './AuthenticatedApp';
import { IntercomWidget } from './IntercomWidget';
import { VersionUpdatedNotifier } from './components/VersionUpdatedNotifier';
import { AppQueryClientProvider } from './components/contexts/AppQueryClientProvider';
import { Auth0TokenContextProvider } from './components/contexts/Auth0TokenContext';
import { Auth0UserContextProvider } from './components/contexts/Auth0UserContext';
import { CurrentUserDetailsContextProvider } from './components/contexts/CurrentUserDetailsContext';
import { ModalContextProvider } from './components/contexts/ModalContext';
import { SynthQLContextProvider } from './components/contexts/SynthQLContext';
import { TrpcProvider } from './components/contexts/TrpcProvider';
import { ErrorFallback } from './components/errorHandlers/ErrorBoundary';
import { AUTH0_AUTHORIZATION_PARAMS } from './const';
import { DevFeatureFlagsProvider } from './devFeatureFlags';
import CustomerSignUpPage from './pages/auth/signup/customer';
import SignUpPage from './pages/auth/signup/invite';
import { themeLuminovo } from './themes';
import history from './utils/applicationHistory';
import { initializeI18n } from './utils/internationalisation';
import { route } from './utils/routes';

// Auth0 parameters are passed as environment variables and are placed in window
// https://jakobzanker.de/blog/inject-environment-variables-into-a-react-app-docker-on-runtime/
declare global {
    interface Window {
        AUTH0_AUTHORITY: string;
        AUTH0_CLIENT_ID: string;
        MAGIC_BELL_API_KEY: string;
        SENTRY_DSN: string;
        SENTRY_TRACE_SAMPLE_RATE: string;
        SENTRY_RELEASE: string;
        SEGMENT_WRITE_KEY: string;
        INTERCOM_APP_ID: string;
        EPIBATOR_SHA: string;
        LUMINOVO_VERSION: string;

        // this is added by a script in the index.html
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Intercom: any;
    }
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState?: AppState) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    history.replace((appState && appState.returnTo) || window.location.pathname);
};

const i18n = initializeI18n();

const App: React.FunctionComponent = (): JSX.Element => {
    return (
        <React.StrictMode>
            <I18nProvider i18n={i18n}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themeLuminovo}>
                        <CssBaseline />
                        <Router history={history}>
                            <SnackbarProvider {...DefaultSnackbarProviderProps}>
                                <AppQueryClientProvider>
                                    <TrpcProvider>
                                        <Auth0Provider
                                            domain={window.AUTH0_AUTHORITY}
                                            clientId={window.AUTH0_CLIENT_ID}
                                            onRedirectCallback={onRedirectCallback}
                                            authorizationParams={AUTH0_AUTHORIZATION_PARAMS}
                                        >
                                            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                <Switch>
                                                    <Route path={route('/auth/signup/invite')} exact>
                                                        <React.Suspense
                                                            fallback={
                                                                <CenteredLayout>
                                                                    <CircularProgress />
                                                                </CenteredLayout>
                                                            }
                                                        >
                                                            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                                <SignUpPage />
                                                            </Sentry.ErrorBoundary>
                                                        </React.Suspense>
                                                    </Route>
                                                    <Route path={route('/auth/signup/customer')} exact>
                                                        <React.Suspense
                                                            fallback={
                                                                <CenteredLayout>
                                                                    <CircularProgress />
                                                                </CenteredLayout>
                                                            }
                                                        >
                                                            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                                <CustomerSignUpPage />
                                                            </Sentry.ErrorBoundary>
                                                        </React.Suspense>
                                                    </Route>
                                                    <Route>
                                                        <React.Suspense
                                                            fallback={
                                                                <CenteredLayout>
                                                                    <CircularProgress />
                                                                </CenteredLayout>
                                                            }
                                                        >
                                                            <Auth0TokenContextProvider>
                                                                <SynthQLContextProvider>
                                                                    <Auth0UserContextProvider>
                                                                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                                            <CurrentUserDetailsContextProvider>
                                                                                <DevFeatureFlagsProvider>
                                                                                    <ModalContextProvider>
                                                                                        <VersionUpdatedNotifier />
                                                                                        <AuthenticatedApp />
                                                                                        <IntercomWidget />
                                                                                    </ModalContextProvider>
                                                                                </DevFeatureFlagsProvider>
                                                                            </CurrentUserDetailsContextProvider>
                                                                        </Sentry.ErrorBoundary>
                                                                    </Auth0UserContextProvider>
                                                                </SynthQLContextProvider>
                                                            </Auth0TokenContextProvider>
                                                        </React.Suspense>
                                                    </Route>
                                                </Switch>
                                            </Sentry.ErrorBoundary>
                                        </Auth0Provider>
                                        <ReactQueryDevtools initialIsOpen={false} />
                                    </TrpcProvider>
                                </AppQueryClientProvider>
                            </SnackbarProvider>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </I18nProvider>
        </React.StrictMode>
    );
};

export default Sentry.withProfiler(App);

import { isPresent, uniqBy } from '@luminovo/commons';
import { ExtractResponseBody, SupplierAndStockLocationDTO } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery } from '../http/useHttpQuery';
import { useOrganizationSolutionPreferences } from '../organizationSettings/organizationSolutionPreferenceHandler';

const sortByFormattedSupplierName = (a: SupplierAndStockLocationDTO, b: SupplierAndStockLocationDTO) => {
    const formatedA = formatSupplierAndStockLocationDTO(a);
    const formatedB = formatSupplierAndStockLocationDTO(b);

    return formatedA.localeCompare(formatedB);
};

export function useSelectFromSupplierAndStockLocations<T>({
    select,
    enabled,
}: {
    select: (res: ExtractResponseBody<'GET /suppliers-and-stock-locations'>) => T;
    enabled?: boolean;
}) {
    return useHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        { enabled, select, notifyOnChangeProps: ['data'], refetchOnWindowFocus: true },
    );
}

export function useSupplierAndStockLocations(): UseQueryResult<SupplierAndStockLocationDTO[]> {
    return useSelectFromSupplierAndStockLocations({
        select: (res) => Array.from(res.data).sort(sortByFormattedSupplierName),
    });
}

function useMultipleSupplierAndStockLocations(
    ids: string[] | undefined = [],
): UseQueryResult<SupplierAndStockLocationDTO[]> {
    return useSelectFromSupplierAndStockLocations({
        select: (res) => {
            return res.data
                .filter((supplierAndStockLocation) => ids.includes(supplierAndStockLocation.id))
                .sort(sortByFormattedSupplierName);
        },
    });
}

export function useSupplierAndStockLocation(id?: string | null): UseQueryResult<SupplierAndStockLocationDTO | null> {
    return useSelectFromSupplierAndStockLocations({
        select: (res) => {
            return res.data.find((supplierAndStockLocation) => supplierAndStockLocation.id === id) ?? null;
        },
    });
}

export function useNonExcludedSupplierAndStockLocations() {
    const { data: organizationSolutionPreferencesDTO } = useOrganizationSolutionPreferences();

    return useSelectFromSupplierAndStockLocations({
        enabled: isPresent(organizationSolutionPreferencesDTO),
        select: (res) => {
            return res.data
                .filter((supplierAndStockLocation) => {
                    if (
                        !isPresent(organizationSolutionPreferencesDTO) ||
                        !isPresent(organizationSolutionPreferencesDTO.excluded_suppliers_and_stock_locations)
                    ) {
                        return true;
                    }

                    return !organizationSolutionPreferencesDTO.excluded_suppliers_and_stock_locations.includes(
                        supplierAndStockLocation.id,
                    );
                })
                .sort(sortByFormattedSupplierName);
        },
    });
}

export function useSupplierAndStockLocationsOptions() {
    const { data: organizationSolutionPreferencesDTO } = useOrganizationSolutionPreferences();

    return useSelectFromSupplierAndStockLocations({
        enabled: isPresent(organizationSolutionPreferencesDTO),
        select: (res) => {
            const sorted = Array.from(res.data).sort(sortByFormattedSupplierName);

            const preferredSuppliers = sorted.filter(
                (s) => organizationSolutionPreferencesDTO?.preferred_suppliers_and_stock_locations?.includes(s.id),
            );
            const approvedSuppliers = sorted.filter(
                (s) => organizationSolutionPreferencesDTO?.approved_suppliers_and_stock_locations?.includes(s.id),
            );
            const nonApprovedSuppliers = sorted.filter(
                (s) => preferredSuppliers.every((p) => p.id !== s.id) && approvedSuppliers.every((a) => a.id !== s.id),
            );
            return {
                nonApprovedSuppliers,
                preferredSuppliers,
                approvedSuppliers,
                options: uniqBy([...preferredSuppliers, ...approvedSuppliers, ...nonApprovedSuppliers], 'id'),
            };
        },
    });
}

export function useGlobalPreferredSupplierAndStockLocations() {
    const { data: organizationSolutionPreferencesDTO } = useOrganizationSolutionPreferences();

    const organizationSolutionPreferencesValue =
        organizationSolutionPreferencesDTO?.preferred_suppliers_and_stock_locations ?? [];

    return useMultipleSupplierAndStockLocations(organizationSolutionPreferencesValue);
}

export function useGlobalApprovedSupplierAndStockLocations() {
    const { data: organizationSolutionPreferencesDTO } = useOrganizationSolutionPreferences();

    const preferredSupplierIds = organizationSolutionPreferencesDTO?.preferred_suppliers_and_stock_locations ?? [];
    const approvedSupplierIds = organizationSolutionPreferencesDTO?.approved_suppliers_and_stock_locations ?? [];

    return useMultipleSupplierAndStockLocations(approvedSupplierIds.filter((s) => !preferredSupplierIds.includes(s)));
}

/* eslint-disable camelcase */
import { Trans, t } from '@lingui/macro';
import {
    compareByString,
    formatDecimal,
    formatMonetaryValue,
    formatRelativeTime,
    formatToLongDate,
    isPresent,
    throwErrorUnlessProduction,
    transEnum,
    uniq,
} from '@luminovo/commons';
import {
    AvatarIcon,
    Flexbox,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { RfqListItemDTO, RfqStatus, UserDTO, UserType } from '@luminovo/http-client';
import { GenericLabel } from '@luminovo/manufacturing-core';
import { extractLowerVolumeEstimate, formatVolumeEstimate, rfqStatusTranslations } from '@luminovo/sourcing-core';
import { Notes } from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router';
import { AssemblyTypes } from '../../../components/AssemblyTypes';
import { StyledRfqStatusBox } from '../../../components/StyledComponents';
import { useCurrentUserDetailsContext, useUserType } from '../../../components/contexts/CurrentUserDetailsContext';
import { useCustomers } from '../../../resources/customer/customerHandler';
import { assemblyTypePublicTranslations } from '../../../resources/rfq/i18n';
import { useRfqs } from '../../../resources/rfq/rfqHandler';
import { useAllOrganizationUsers } from '../../../resources/user/userHandler';
import { useHasCustomers } from '../../../utils/featureFlags';
import { route } from '../../../utils/routes';
import { RfqCommentsButton } from '../../RfqDashboard/components/ToolbarOemDashboard';

export type RfqOverviewTableItem = RfqListItemDTO & { contact_person: UserDTO | undefined };

const CellCreatedBy: React.FunctionComponent<{ rfq: RfqOverviewTableItem }> = ({ rfq }) => {
    const { customer, organization } = useCurrentUserDetailsContext();
    const isCreatedByCustomer = isPresent(rfq.created_by?.customer);

    const isSameUserType = rfq.created_by?.customer?.id === customer?.id;
    const creatorOrganizationName = isCreatedByCustomer ? rfq.customer_name : organization.name;

    if (!isPresent(rfq.created_by)) {
        return (
            <Text variant={'inherit'} color={colorSystem.neutral[6]}>
                -
            </Text>
        );
    }

    return (
        <Tooltip
            placement={'bottom'}
            title={
                <>
                    {`${rfq.created_by.first_name} ${rfq.created_by.last_name} ∙ ${rfq.created_by.email}`}
                    {isCreatedByCustomer && (
                        <>
                            <br />
                            {creatorOrganizationName}
                        </>
                    )}
                </>
            }
        >
            <Text
                variant="inherit"
                style={{
                    color: isSameUserType ? colorSystem.neutral[9] : colorSystem.neutral[6],
                    maxWidth: 150,
                }}
            >{`${rfq.created_by.first_name} ${rfq.created_by.last_name}`}</Text>
        </Tooltip>
    );
};

const CellContributors: React.FunctionComponent<{ rfq: RfqOverviewTableItem }> = ({ rfq }) => {
    const contributors = rfq.contributors;
    const maxAvatarsToShow = 3;

    return (
        <Flexbox flexDirection={'row'} gap={4} alignItems="center">
            {contributors.length === 0
                ? '-'
                : contributors.slice(0, maxAvatarsToShow).map((contributor, i) => {
                      const nameArray = contributor.name
                          .split(' ')
                          .map((str) => str.trim())
                          .filter((s) => s.length > 0);
                      return (
                          <Tooltip
                              key={i}
                              placement={'bottom'}
                              title={[contributor.name.trim(), contributor.email.trim()]
                                  .filter((s) => s.length > 0)
                                  .join(' ∙ ')}
                          >
                              <AvatarIcon
                                  size="small"
                                  extended
                                  user={{
                                      email: contributor.email,
                                      firstName: nameArray[0] ?? '',
                                      lastName: nameArray[1] ?? '',
                                  }}
                              />
                          </Tooltip>
                      );
                  })}
            {contributors.length > maxAvatarsToShow && (
                <Text variant="inherit">&nbsp;{contributors.length - maxAvatarsToShow}+</Text>
            )}
        </Flexbox>
    );
};

const NotesIcon: React.FunctionComponent<{ notes: string | null | undefined }> = ({ notes }) => {
    if (!Boolean(notes) || !isPresent(notes)) {
        return <></>;
    }

    return (
        <Flexbox justifyContent={'center'}>
            <Tooltip
                variant={'white'}
                title={
                    <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Notes</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[8]}>
                            {notes}
                        </Text>
                    </Flexbox>
                }
            >
                <Notes
                    style={{
                        color: colorSystem.neutral[6],
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        borderRadius: '24px',
                        padding: '2px 8px',
                        fontSize: '16px',
                    }}
                />
            </Tooltip>
        </Flexbox>
    );
};

const columnHelper = createColumnHelper<RfqOverviewTableItem>();

const columnRfq = columnHelper.text((row) => `${row.name} ${row.ems_internal_number ?? ''}`, {
    id: 'rfq',
    label: () => t`RfQ`,
    size: 200,
    cell: ({ row }) => (
        <GenericLabel variant={'inherit'} name={row.original.name} additionalInfo={row.original.ems_internal_number} />
    ),
});

const columnCustomerName = columnHelper.text((row) => `${row.customer_name} ${row.customer_number ?? ''}`, {
    id: 'customer',
    label: () => t`Customer`,
    size: 200,
    cell: ({ row }) => (
        <GenericLabel
            variant={'inherit'}
            name={row.original.customer_name}
            additionalInfo={row.original.customer_number}
        />
    ),
});

const columnCompanyName = columnHelper.text((row) => `${row.customer_name} ${row.customer_number ?? ''}`, {
    id: 'company',
    label: () => t`Company`,
    size: 200,
    cell: ({ row }) => (
        <GenericLabel
            variant={'inherit'}
            name={row.original.customer_name}
            additionalInfo={row.original.customer_number}
        />
    ),
});

const columnStatus = columnHelper.enum('status', {
    label: () => t`Status`,
    size: 130,
    options: Object.values(RfqStatus),
    getOptionLabel: (status) => transEnum(status, rfqStatusTranslations),
    renderOption: (status) => <StyledRfqStatusBox rfqStatus={status} />,
    cell: (item) => <StyledRfqStatusBox rfqStatus={item.getValue()} />,
    quickFilters: [
        {
            label: () => t`Only open RfQs`,
            value: [
                RfqStatus.RequestInDraft,
                RfqStatus.QuotationInProgress,
                RfqStatus.QuotationAvailable,
                RfqStatus.OrderPlaced,
                RfqStatus.OrderInProduction,
                RfqStatus.OrderInProcurement,
            ],
        },
    ],
});

const columnVolume = columnHelper.number((row) => extractLowerVolumeEstimate(row), {
    id: 'volume',
    label: () => t`Volume`,
    size: 80,
    initialVisibility: false,
    cell: ({ row }) => {
        if (!isPresent(row.original.ems_rfq_details.volume_estimate)) {
            return (
                <Text variant={'inherit'} color={colorSystem.neutral[6]}>
                    <Trans>Unknown</Trans>
                </Text>
            );
        }
        return formatVolumeEstimate(row.original.ems_rfq_details.volume_estimate);
    },
});

const columnDueDate = columnHelper.date('due_date', {
    label: () => t`Due date`,
    size: 100,
    initialVisibility: false,
    cell: (item) => {
        const dueDate = item.getValue();
        return (
            <Tooltip title={dueDate ? formatRelativeTime(dueDate) : ''}>
                <Text variant={'inherit'}>{formatToLongDate(dueDate)}</Text>
            </Tooltip>
        );
    },
});

const columnCreationDate = columnHelper.date('creation_date', {
    label: () => t`Creation date`,
    size: 100,
    cell: (item) => (
        <Tooltip title={formatRelativeTime(item.getValue())}>
            <Text variant={'inherit'}>{formatToLongDate(item.getValue())}</Text>
        </Tooltip>
    ),
});

const columnCreatedBy = columnHelper.enum(
    (row) => (isPresent(row.created_by) ? `${row.created_by.first_name} ${row.created_by.last_name}` : t`Unknown`),
    {
        id: 'createdBy',
        label: () => t`Created by`,
        size: 100,
        renderType: 'text',
        getOptionLabel: (option) => option,
        cell: ({ row }) => <CellCreatedBy rfq={row.original} />,
    },
);

const columnContributors = columnHelper.array((row) => row.contributors.map((c) => c.name), {
    id: 'contributors',
    label: () => t`Contributors`,
    size: 80,
    getOptionLabel: (option) => option,
    cell: ({ row }) => <CellContributors rfq={row.original} />,
});

const columnContactPerson = columnHelper.enum(
    (row) =>
        isPresent(row.contact_person) ? `${row.contact_person.first_name} ${row.contact_person.last_name}` : null,
    {
        id: 'ContactPerson',
        label: () => t`Contact person`,
        size: 80,
        initialVisibility: false,
        getOptionLabel: (option) => option ?? t`Unknown`,
        cell: ({ row }) => {
            const value = row.original.contact_person;
            if (isPresent(value)) {
                return (
                    <AvatarIcon
                        size="small"
                        extended
                        user={{
                            email: value.email,
                            firstName: value.first_name,
                            lastName: value.last_name,
                        }}
                    />
                );
            }
            return <></>;
        },
    },
);

const columnAssemblyTypes = columnHelper.array(
    (row) => uniq(row.assembly_types.map((a) => a.type)).sort(compareByString),
    {
        id: 'assemblyTypes',
        label: () => t`Assembly types`,
        size: 80,
        initialVisibility: false,
        getOptionLabel: (option) => transEnum(option, assemblyTypePublicTranslations),
        cell: (item) => <AssemblyTypes assemblyTypes={item.getValue()} />,
    },
);

const columnNotes = columnHelper.text('notes', {
    id: 'notes',
    label: () => t`Notes`,
    size: 60,
    initialVisibility: false,
    enableGlobalFilter: false,
    cell: (item) => <NotesIcon notes={item.getValue()} />,
});

const columnComments = columnHelper.generic(() => null, {
    id: 'comments',
    label: () => t`Comments`,
    size: 80,
    enableColumnFilter: false,
    enableSorting: false,
    enableOnRowClick: false,
    align: 'center',
    cell: ({ row }) => <RfqCommentsButton rfqId={row.original.id} iconButtonOnly={true} size={'small'} />,
});

const columnState = columnHelper.enum('is_archived', {
    label: () => t`State`,
    size: 100,
    options: [false, true],
    getOptionLabel: (option) => (option ? t`Archived` : t`Active`),
    initialVisibility: false,
    quickFilters: [
        {
            label: () => t`Only active RfQs`,
            value: [false],
        },
    ],
    cell: (item) => {
        if (item.getValue()) {
            return <Tag color={'red'} attention={'low'} label={t`Archived`} />;
        } else {
            return <Tag color={'green'} attention={'low'} label={t`Active`} />;
        }
    },
});

const columnSelectedOrderSize = columnHelper.number('sales_order.order_size', {
    id: 'selectedOrderSize',
    label: () => t`Selected order size`,
    size: 140,
    initialVisibility: false,
    cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
});

const columnPrice = columnHelper.monetaryValue('sales_order.total_price', {
    id: 'totalPrice',
    label: () => t`Total price`,
    size: 100,
    initialVisibility: false,
    cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '-' }),
});

const columnAdditionalServices = columnHelper.array((row) => row.additional_services.map((a) => a.name_en), {
    id: 'additionalServices',
    label: () => t`Additional services`,
    size: 140,
    initialVisibility: false,
    getOptionLabel: (option) => option,
    cell: (item) => (
        <Flexbox flexDirection={'column'} gap={4}>
            {item.getValue().length === 0 && '-'}
            {item.getValue().map((service, index) => (
                <Text key={index} variant={'inherit'}>
                    {service}
                </Text>
            ))}
        </Flexbox>
    ),
});

export function useRfqOverviewTable() {
    const { data: rfqs } = useRfqs();
    const { data: customer } = useCustomers();
    const { data: contactPersons } = useAllOrganizationUsers();
    const history = useHistory();
    const hasCustomers = useHasCustomers();
    const userType = useUserType();

    const data = React.useMemo(() => {
        return rfqs?.map((rfq) => {
            // FIXME: This is a workaround to get the default contact person for the customer, we should get it via the backend.
            const customerMatch = customer?.find(
                (c) => c.name === rfq.customer_name && c.customer_number === rfq.customer_number,
            );

            const contactPerson = contactPersons?.find((p) => p.id === customerMatch?.default_contact_person);
            return {
                ...rfq,
                contact_person: contactPerson,
            };
        });
    }, [rfqs, customer, contactPersons]);

    const columns = React.useMemo(() => {
        switch (userType) {
            case UserType.Internal:
                return [
                    hasCustomers ? columnCustomerName : columnCompanyName,
                    columnRfq,
                    columnStatus,
                    columnCreationDate,
                    columnCreatedBy,
                    columnContributors,
                    columnContactPerson,
                    columnAssemblyTypes,
                    columnVolume,
                    columnDueDate,
                    columnAdditionalServices,
                    columnSelectedOrderSize,
                    columnPrice,
                    columnNotes,
                    columnComments,
                    columnState,
                ];
            case UserType.Customer:
                return [
                    columnRfq,
                    columnStatus,
                    columnCreationDate,
                    columnCreatedBy,
                    columnContributors,
                    columnAssemblyTypes,
                    columnAdditionalServices,
                    columnSelectedOrderSize,
                    columnPrice,
                    columnNotes,
                    columnComments,
                    columnState,
                ];
            case UserType.Supplier:
                throwErrorUnlessProduction('Suppliers should not see the RFQ overview table');
                return [];
        }
    }, [hasCustomers, userType]);

    return useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableSaveAsDefault: {
            key: 'rfq-overview-table-v2',
            storage: 'local',
        },
        enableExcelExport: true,

        onRowClick: (row) => history.push(route('/rfqs/:rfqId/dashboard', { rfqId: row.original.id })),
    });
}

export function RfqOverviewMiniTable({ data }: { data: RfqListItemDTO[] | undefined }) {
    const history = useHistory();
    const { table } = useTanStackTable({
        columns: [columnRfq, columnStatus, columnCreationDate, columnCreatedBy],
        data,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        onRowClick: (row) => history.push(route('/rfqs/:rfqId/dashboard', { rfqId: row.original.id })),
    });

    return <TanStackTable table={table} size={'small'} />;
}

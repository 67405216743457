/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { formatMonetaryValue } from '@luminovo/commons';
import { createColumnHelper, SecondaryButton, TanStackTable, useTanStackTable } from '@luminovo/design-system';
import { MonetaryValueBackendRuntype } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { useMutation } from '@tanstack/react-query';
import { QuoteRequestLineItem } from '../hooks/queries';

export function TableQuoteRequestLineItems({ lineItems }: { lineItems: QuoteRequestLineItem[] }) {
    const helper = createColumnHelper<QuoteRequestLineItem>();

    const { table } = useTanStackTable({
        data: lineItems,
        columns: [
            helper.text((row) => row.quoteRequest?.supplier?.name ?? t`Unknown`, {
                id: 'supplier',
                label: () => t`Supplier`,
                size: 150,
            }),

            helper.text((row) => formatQuantity(row.required_quantity, { showPiecesUnit: true }), {
                id: 'requiredQuantity',
                label: () => t`Qty`,
                size: 100,
            }),

            helper.text((row) => formatQuantity(row.potential_quantity ?? 0, { showPiecesUnit: true }), {
                id: 'potentialQuantity',
                size: 100,
                label: () => t`Potential Qty`,
            }),

            helper.monetaryValue((row) => row.target_price, {
                id: 'targetPrice',
                label: () => t`Target price`,
                size: 150,

                cell: ({ row }) => {
                    return formatMonetaryValue(
                        MonetaryValueBackendRuntype.optional().nullable().check(row.original.target_price),
                        'unit-price',
                        { ifAbsent: '-' },
                    );
                },
            }),

            helper.action({
                id: 'actions',
                size: 100,
                align: 'right',
                enablePinning: true,
                cell: function Cell() {
                    const { mutateAsync, isLoading } = useMutation({
                        mutationFn: () => {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve(true);
                                }, 1000);
                            });
                        },
                    });

                    const handleClick = () => {
                        mutateAsync();
                    };
                    return (
                        <SecondaryButton onClick={handleClick} isLoading={isLoading} size="medium">
                            <Trans>Select</Trans>
                        </SecondaryButton>
                    );
                },
            }),
        ],
        defautlInitalState: {
            columnPinning: {
                right: ['actions'],
            },
        },
    });

    return <TanStackTable table={table} />;
}

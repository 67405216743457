import { Trans } from '@lingui/macro';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import { LifecycleEnum, OffTheShelfPartVote } from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import { useCallback, useState } from 'react';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { LifecycleChipWithTooltip } from './LifecycleChipWithTooltip';

export const OtsPartLifecycleStatusOriginsTooltip: React.FunctionComponent<{
    partId: string;
    lifecycleStatus: LifecycleEnum;
    children: TooltipProps['children'];
}> = ({ partId, lifecycleStatus, children }): JSX.Element => {
    const [votes, setVotes] = useState<OffTheShelfPartVote[] | undefined>();

    const { mutateAsync: fetchVotes, isLoading } = useHttpMutation('GET /parts/off-the-shelf/:partId/votes', {
        snackbarMessage: null,
    });

    const handleOpen = useCallback(async () => {
        if (lifecycleStatus === LifecycleEnum.Unknown) return;
        if (!votes) {
            const response = await fetchVotes({ pathParams: { partId } });
            setVotes(response.items);
        }
    }, [votes, fetchVotes, partId, lifecycleStatus]);

    if (isLoading) {
        return (
            <Tooltip
                arrow
                title={
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>Lifecycle origins</Trans>
                        </Text>
                        <Skeleton width="75%" />
                    </Flexbox>
                }
                variant="white"
            >
                <span>{children}</span>
            </Tooltip>
        );
    }

    const showTooltip = votes && votes.length > 0 && votes.some((origin) => origin.lifecycle);

    return (
        <Tooltip
            arrow
            onOpen={handleOpen}
            title={
                showTooltip ? (
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>Lifecycle origins</Trans>
                        </Text>
                        <Flexbox flexDirection="column" gap="8px">
                            {votes.map((origin, i) => {
                                if (!origin.lifecycle) {
                                    return null;
                                }
                                return (
                                    <Flexbox key={i} alignItems="center" justifyContent="space-between">
                                        <LifecycleChipWithTooltip status={origin.lifecycle} disabledTooltip={true} />
                                        <Text variant="body-small">{origin.origin.type}</Text>
                                    </Flexbox>
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                ) : (
                    ''
                )
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};

import { Trans, t } from '@lingui/macro';
import { TransEnum, isPresent } from '@luminovo/commons';
import { Flexbox, Tag, TertiaryIconButton, Text, colorSystem } from '@luminovo/design-system';
import { AssemblyDTO } from '@luminovo/http-client';
import { assemblyIndustryTranslations } from '@luminovo/manufacturing-core';
import { KeyboardTabRounded } from '@mui/icons-material';
import { Collapse, Skeleton, styled } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { useAssemblies } from '../../../resources/assembly/assemblyHandler';
import { useCustomer } from '../../../resources/customer/customerHandler';
import { assemblyTypeTranslations } from '../../../resources/rfq/i18n';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { route } from '../../../utils/routes';
import { BomOverallRisk } from '../../Bom/components/BomHealthDashboard/AssemblyDetails';
import { ViewContext } from '../../Bom/components/ModuleTableData';

type Props = {
    assembly: AssemblyDTO;
    isSideBarOpen: boolean;
    toggleIsSidebarOpen: () => void;
};
export default function SideBar({ assembly, isSideBarOpen, toggleIsSidebarOpen }: Props) {
    const assemblyParentsWithoutBOMWrapper = Object.entries(assembly.parents)
        .filter(([assemblyId, quantity]) => {
            if (!assembly.rfq) return true;
            return assemblyId !== assembly.rfq;
        })
        .reduce(
            (acc, [key, value]) => {
                acc[key] = value;
                return acc;
            },
            {} as { [key: string]: number },
        );

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId: assembly.rfq ?? undefined,
        };
    }, [assembly.rfq]);

    return (
        <div style={{ padding: '24px', transition: 'all 0.2s ease-in' }}>
            <Flexbox style={{ marginBottom: 20 }} alignItems={'center'} justifyContent={'space-between'}>
                <StyledCollapse in={isSideBarOpen} orientation="horizontal">
                    <Text variant="h3" showEllipsis={true} style={{ display: 'block', maxWidth: '200px' }}>
                        {assembly.designator}
                    </Text>
                </StyledCollapse>
                <TertiaryIconButton onClick={toggleIsSidebarOpen} disableRipple>
                    <KeyboardTabRounded
                        style={{ transform: isSideBarOpen ? 'rotate(180deg)' : undefined }}
                        fontSize="inherit"
                    />
                </TertiaryIconButton>
            </Flexbox>
            <StyledCollapse in={isSideBarOpen} orientation="horizontal">
                <StyledAssemblyFlexBox gap={20}>
                    <BomOverallRisk viewContext={viewContext} assemblyId={assembly.id} />
                    <StyledAssemblyFlexBox gap={4}>
                        <StyledAssemblyPropText>
                            <Trans>Origin</Trans>
                        </StyledAssemblyPropText>
                        {assembly.origin.type === 'Imported' || assembly.origin.type === 'ApiImport' ? (
                            <Tag color={'primary'} label={t`API import`} attention="low" />
                        ) : (
                            <Tag color={'neutral'} label={t`Manually created`} attention="low" />
                        )}
                    </StyledAssemblyFlexBox>
                    {assembly.ipn_id && (
                        <StyledAssemblyFlexBox gap={4}>
                            <StyledAssemblyPropText>
                                <Trans>IPN</Trans>
                            </StyledAssemblyPropText>
                            <Tag color={'neutral'} label={assembly.ipn_id} attention="low" />
                        </StyledAssemblyFlexBox>
                    )}
                    <StyledAssemblyFlexBox gap={4}>
                        <StyledAssemblyPropText>
                            <Trans>Industry</Trans>
                        </StyledAssemblyPropText>
                        <Tag
                            label={<TransEnum text={assembly.industry} translations={assemblyIndustryTranslations} />}
                            color="neutral"
                            attention="low"
                        />
                    </StyledAssemblyFlexBox>

                    <StyledAssemblyFlexBox gap={4}>
                        <StyledAssemblyPropText>
                            <Trans>Type</Trans>
                        </StyledAssemblyPropText>
                        <Tag
                            label={<TransEnum text={assembly.type.type} translations={assemblyTypeTranslations} />}
                            color="neutral"
                            attention="low"
                        />
                    </StyledAssemblyFlexBox>
                    {assembly.customer && <Customer customer={assembly.customer} />}

                    <StyledAssemblyFlexBox gap={4}>
                        <StyledAssemblyPropText>
                            <Trans>Notes</Trans>
                        </StyledAssemblyPropText>
                        <StyledBodyText>{assembly.notes ?? '-'}</StyledBodyText>
                    </StyledAssemblyFlexBox>

                    <StyledAssemblyFlexBox gap={8}>
                        <StyledAssemblyPropText>
                            <Trans>Assembly</Trans>
                        </StyledAssemblyPropText>
                        <StyledAssemblyFlexBox gap={10}>
                            <Flexbox justifyContent={'space-between'}>
                                <Text>
                                    <Trans>BOM items</Trans>
                                </Text>
                                <Text>{assembly.bom_items.length}</Text>
                            </Flexbox>
                            <Flexbox justifyContent={'space-between'}>
                                <Text>
                                    <Trans>Design items</Trans>
                                </Text>
                                <Text>{assembly.design_items.items.length}</Text>
                            </Flexbox>
                            <Flexbox justifyContent={'space-between'}>
                                <Text>
                                    <Trans>Subassemblies</Trans>
                                </Text>
                                <Text>{assembly.subassemblies.items.length}</Text>
                            </Flexbox>
                        </StyledAssemblyFlexBox>
                    </StyledAssemblyFlexBox>
                    {Object.keys(assemblyParentsWithoutBOMWrapper).length > 0 && (
                        <UsedInAssemblies parents={assemblyParentsWithoutBOMWrapper} />
                    )}

                    {assembly.rfq && <AssemblyRfq assemblyId={assembly.id} rfqId={assembly.rfq} />}
                </StyledAssemblyFlexBox>
            </StyledCollapse>
        </div>
    );
}

function Customer({ customer }: { customer: string }) {
    const { data } = useCustomer(customer);

    return (
        <StyledAssemblyFlexBox gap={4}>
            <StyledAssemblyPropText>
                <Trans>Customer</Trans>
            </StyledAssemblyPropText>
            {isPresent(data) ? <StyledBodyText>{data.name}</StyledBodyText> : <Skeleton height="20px" width="45%" />}
        </StyledAssemblyFlexBox>
    );
}

function UsedInAssemblies({ parents }: { parents: { [key: string]: number } }) {
    const assemblyIds = Object.keys(parents);
    const { data = [] } = useAssemblies(assemblyIds);
    return (
        <div>
            <div style={{ marginBottom: '8px' }}>
                <StyledAssemblyPropText>
                    <Trans>Used in assemblies</Trans>
                </StyledAssemblyPropText>
            </div>
            <Flexbox flexDirection="column" gap={8}>
                {data.map((assembly) => (
                    <Flexbox justifyContent={'space-between'} key={assembly.id}>
                        <StyledAssemblyLink
                            content={assembly.designator}
                            link={route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id })}
                        />
                        <Text>{parents[assembly.id]}</Text>
                    </Flexbox>
                ))}
            </Flexbox>
        </div>
    );
}

function AssemblyRfq({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }) {
    const { data } = useRfQ(rfqId);

    return (
        <StyledAssemblyFlexBox gap={4}>
            <StyledAssemblyPropText>
                <Trans>Used in RfQs</Trans>
            </StyledAssemblyPropText>
            {isPresent(data) ? (
                <StyledAssemblyLink
                    content={data.name}
                    link={route('/rfqs/:rfqId/bom/assembly/:assemblyId', { assemblyId, rfqId })}
                />
            ) : (
                <Skeleton height="20px" width="45%" />
            )}
        </StyledAssemblyFlexBox>
    );
}

const StyledAssemblyFlexBox = styled(Flexbox)({
    flexDirection: 'column',
});

const StyledAssemblyPropText = ({ children }: { children: React.ReactNode }) => {
    return (
        <Text variant="h4" color={colorSystem.neutral[6]}>
            {children}
        </Text>
    );
};

const StyledAssemblyLink = ({ link, content }: { link: string; content: string }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(link);
    };
    return (
        <StyledBodyText color={colorSystem.blue[6]} onClick={handleClick} style={{ cursor: 'pointer' }}>
            {content}
        </StyledBodyText>
    );
};

const StyledBodyText = ({
    color,
    children,
    style,
    onClick,
}: {
    color?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
}) => {
    return (
        <Text
            variant="body"
            onClick={onClick}
            showEllipsis={true}
            color={color}
            style={{ display: 'block', maxWidth: '230px', ...style }}
        >
            {children}
        </Text>
    );
};

const StyledCollapse = styled(Collapse)({
    '& .MuiCollapse-wrapper': {
        maxWidth: '100%',
    },
});

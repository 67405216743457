import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Flexbox, TertiaryButton, Tooltip } from '@luminovo/design-system';
import {
    DriverIdDTO,
    DriverStatusDesignItem,
    DriverStatusDetailsDTO,
    DriverStatusDTO,
    ErrorDriverStatusDTO,
    ManufacturingAssemblyDetailsDTO,
    ManufacturingScenarioDTO,
    MissingDesignItemInformationDTO,
    SourcingScenarioDTO,
    WarningDriverStatusDTO,
} from '@luminovo/http-client';
import { findStatusOfDriver } from '@luminovo/manufacturing-core';
import LaunchIcon from '@mui/icons-material/Launch';
import { Skeleton } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { assertPresent } from '../../../utils/assertPresent';
import { route } from '../../../utils/routes';
import { assertUnreachable } from '../../../utils/typingUtils';
import { FilterId } from '../../Bom/components/ModuleTableData/filters';
import { useEditManufacturingScenarioDialog } from '../ManufacturingScenario/ManufacturingScenarioForm/useEditManufacturingScenarioDialog';
import { StatusWithInformation } from '../utils/status';
import {
    getDesignItemsWarningString,
    getSourcingScenarioInformation,
    useSourcingScenariosForDriver,
} from './driverStatusInformationUtils';

type DriverStatusDetails = {
    description: string;
    subDescription?: string;
    tooltipText?: string;
    button?: JSX.Element;
};

const ActionButtonWithLink = ({
    buttonText,
    buttonIcon,
    buttonHttpRoute,
    isDisabled,
}: {
    buttonText?: string;
    buttonHttpRoute: string;
    buttonIcon?: JSX.Element;
    isDisabled?: boolean;
}) => {
    return (
        <TertiaryButton
            style={{ marginLeft: 'auto', display: 'flex', padding: 0 }}
            href={buttonHttpRoute}
            size="medium"
            disabled={isDisabled}
        >
            {buttonIcon ?? <LaunchIcon fontSize={'small'} style={{ marginInlineEnd: '4px' }} />}
            {buttonText ?? <Trans>Resolve</Trans>}
        </TertiaryButton>
    );
};

const MANUFACTURING_FILTERS: FilterId[] = [
    FilterId.PinsMissing,
    FilterId.MountingMissing,
    FilterId.PackageNameMissing,
    FilterId.PackageMismatch,
];

const createResolveInBomButton = (
    missingDesignItemsInfo: MissingDesignItemInformationDTO,
    rfqId: string,
    assemblyId: string | undefined,
): JSX.Element => {
    if (!assemblyId) {
        return <ActionButtonWithLink buttonHttpRoute="" isDisabled />;
    }

    const alldesignItems: DriverStatusDesignItem[] = missingDesignItemsInfo.data;
    const firstDesignItemForAssembly = alldesignItems.find((item) => item.assembly === assemblyId);

    // The DTO maps driverIds to design items that have errors for this assembly.
    // So we can be sure that there exists an erroneous design item for this assembly.
    const selectedDesignItem = assertPresent(firstDesignItemForAssembly);

    if (!selectedDesignItem) {
        return <ActionButtonWithLink buttonHttpRoute="" isDisabled />;
    }

    const filters = MANUFACTURING_FILTERS.join(',');

    const assemblyRoute = route(
        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
        { rfqId, assemblyId },
        {
            designItemId: selectedDesignItem.id,
            isReadonly: false.toString(),
            bomTab: 'manufacturingData',
            filters,
            dashboardFilters: null,
            search: null,
            onlyShowItemsWithManufacturingWarnings: true.toString(),
            currentParentAssemblyId: null,
        },
    );

    return <ActionButtonWithLink buttonHttpRoute={assemblyRoute} />;
};

const createResolveInPnpButton = (rfqId: string, assemblyId: string | undefined): JSX.Element => {
    if (!assemblyId) {
        return <ActionButtonWithLink buttonHttpRoute="" isDisabled />;
    }

    const pnpRoute = route('/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer', { rfqId, assemblyId });

    return <ActionButtonWithLink buttonHttpRoute={pnpRoute} />;
};

const getWarningDetails = ({
    driverStatus,
    rfqId,
    formattedSourcingScenarioNames,
    assemblyId,
}: {
    driverStatus: WarningDriverStatusDTO;
    rfqId: string;
    formattedSourcingScenarioNames: string;
    assemblyId: string | undefined;
}): DriverStatusDetails => {
    switch (driverStatus.data.type) {
        case 'MissingDesignItemInformation':
            const bomButton = createResolveInBomButton(driverStatus.data, rfqId, assemblyId);

            return {
                description: t`Some information in the BOM is missing.`,
                subDescription: t`Click on resolve to add this information to the BOM.`,
                tooltipText: getDesignItemsWarningString(driverStatus),
                button: bomButton,
            };

        case 'MissingPnpInformation':
            const pnpButton = createResolveInPnpButton(rfqId, assemblyId);

            return {
                description:
                    t`Information missing from Pick and Place data for design items:` +
                    ' ' +
                    driverStatus.data.data.map((item) => item.designator).join(', ') +
                    '.',
                subDescription: t`Re-import the Pick and Place file with the missing information.`,
                tooltipText: getDesignItemsWarningString(driverStatus),
                button: pnpButton,
            };

        case 'MissingOffer':
            return {
                description: t`Offers missing in sourcing scenario`,
                subDescription: formattedSourcingScenarioNames,
            };

        case 'AssemblyNotPCBA':
            return {
                description: t`The selected assembly type does not require a PCB so no values can be extracted.`,
            };

        default:
            assertUnreachable(driverStatus.data);
    }
};

const PCBActionButton = ({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }): JSX.Element | null => {
    const { data } = useHttpQuery(
        'GET /assemblies/:assemblyId/data',
        {
            pathParams: { assemblyId },
        },
        { suspense: true, useErrorBoundary: true },
    );

    const assemblyIsPCBA: boolean = data?.type.type === 'Pcba';

    return (
        <Tooltip title={assemblyIsPCBA ? '' : t`Only PCBA assemblies can have a PCB attached to them`}>
            <span>
                <ActionButtonWithLink
                    buttonHttpRoute={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification', {
                        rfqId,
                        assemblyId,
                    })}
                    isDisabled={!assemblyIsPCBA}
                />
            </span>
        </Tooltip>
    );
};

const AddSourcingScenarioActionButton = ({
    rfqId,
    assemblyId,
    manufacturingScenarioId,
}: {
    rfqId: string;
    assemblyId: string;
    manufacturingScenarioId: string;
}): JSX.Element => {
    const { data: manufacturingScenario } = useHttpQuery(
        'GET /manufacturing-scenarios/:manufacturingScenarioId',
        {
            pathParams: { manufacturingScenarioId },
        },
        { select: (data) => data.data },
    );

    if (!manufacturingScenario) {
        return <Skeleton width={50} />;
    }

    return (
        <AddSourcingScenarioActionButtonInner
            rfqId={rfqId}
            assemblyId={assemblyId}
            manufacturingScenario={manufacturingScenario}
        />
    );
};

const AddSourcingScenarioActionButtonInner = ({
    rfqId,
    assemblyId,
    manufacturingScenario,
}: {
    rfqId: string;
    assemblyId: string;
    manufacturingScenario: ManufacturingScenarioDTO;
}): JSX.Element => {
    const { openDialog } = useEditManufacturingScenarioDialog({
        rfqId,
        assemblyId,
        manufacturingScenario: {
            id: manufacturingScenario.id,
            name: manufacturingScenario.name,
            notes: manufacturingScenario.notes ?? undefined,
            batchSizes: manufacturingScenario.batch_sizes,
            sourcingScenarioId: manufacturingScenario.sourcing_scenario ?? undefined,
        },
    });

    return (
        <TertiaryButton style={{ marginLeft: 'auto', display: 'flex', padding: 0 }} size="medium" onClick={openDialog}>
            <Trans>Resolve</Trans>
        </TertiaryButton>
    );
};

const getErrorDetails = ({
    driverStatus,
    rfqId,
    manufacturingAssemblyDetailsId,
    assemblyId,
}: {
    driverStatus: ErrorDriverStatusDTO;
    rfqId: string;
    manufacturingAssemblyDetailsId: string;
    assemblyId: string;
}): DriverStatusDetails => {
    switch (driverStatus.data.type) {
        case 'MissingDriverCount':
            return {
                description: t`Manual input required`,
                subDescription: t`Add a count manually to calculate.`,
            };

        case 'NoSourcingScenarioLinked':
            return {
                description: t`No sourcing scenario linked to the manufacturing scenario`,
                button: (
                    <AddSourcingScenarioActionButton
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        manufacturingScenarioId={driverStatus.data.data.manufacturing_scenario_id}
                    />
                ),
            };
        case 'MissingPcbInformation':
            return {
                description: t`Some information from the PCB is missing.`,
                subDescription: t`Click on resolve to add this information in the PCB module.`,
                button: <PCBActionButton rfqId={rfqId} assemblyId={assemblyId} />,
            };
        case 'MissingPnpFile':
            return {
                description: t`No Pick and Place data has been uploaded.`,
                subDescription: t`Upload Pick and Place data or overwrite the count manually.`,
                button: (
                    <ActionButtonWithLink
                        buttonHttpRoute={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer', {
                            rfqId,
                            assemblyId,
                        })}
                        buttonText={t`Go to pick and place upload`}
                    />
                ),
            };
        case 'MissingPlacementData':
            return {
                description: t`No placement data available.`,
                subDescription: t`Please upload a pick & place file or set the sides of placement value in the PCB specification.`,
                button: (
                    <Flexbox gap="8px">
                        <ActionButtonWithLink
                            buttonHttpRoute={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer', {
                                rfqId,
                                assemblyId,
                            })}
                            buttonText={t`Go to pick and place upload`}
                        />
                        <PCBActionButton rfqId={rfqId} assemblyId={assemblyId} />
                    </Flexbox>
                ),
            };
        case 'ConflictingPlacementData':
            return {
                description: t`Conflicting placement information.`,
                subDescription: t`The driver count extracted from the PCB specification (${driverStatus.data.data.pcb_spec_count}) does not match the one from the pick and place file (${driverStatus.data.data.pnp_file_count}). Please resolve the inconsistency.`,
                button: <PCBActionButton rfqId={rfqId} assemblyId={assemblyId} />,
            };
        case 'PlacementSideInPcbSpecificationRequired':
            return {
                description: t`No placement information in the PCB specification`,
                subDescription: t`Please update the 'Placements' value in the PCB specification`,
                button: <PCBActionButton rfqId={rfqId} assemblyId={assemblyId} />,
            };
        default:
            assertUnreachable(driverStatus.data);
    }
};

export const useStatusInformationFromDriverStatus = (
    driverStatus: DriverStatusDTO,
    rfqId: string,
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO,
): StatusWithInformation & DriverStatusDetails => {
    const { data: sourcingScenarioDTOs } = useSourcingScenariosForDriver(driverStatus);

    return getStatusInformationFromDrivers({
        driverStatus,
        rfqId,
        manufacturingAssemblyDetails,
        sourcingScenarioDTOs: sourcingScenarioDTOs ?? [],
    });
};

export const getStatusInformationFromDrivers = ({
    driverStatus,
    rfqId,
    manufacturingAssemblyDetails,
    sourcingScenarioDTOs,
}: {
    driverStatus: DriverStatusDTO;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    sourcingScenarioDTOs: SourcingScenarioDTO[];
}): StatusWithInformation & DriverStatusDetails => {
    const { formattedSourcingScenarioNames } = getSourcingScenarioInformation(driverStatus, sourcingScenarioDTOs);

    switch (driverStatus.type) {
        case 'Ok':
            return {
                type: 'Ok',
                description: t`All required information is available`,
            };
        case 'Warning':
            return {
                type: 'Warning',
                ...getWarningDetails({
                    driverStatus,
                    rfqId,
                    formattedSourcingScenarioNames,
                    assemblyId: manufacturingAssemblyDetails.assembly_id,
                }),
            };
        case 'Error':
            return {
                type: 'Error',
                ...getErrorDetails({
                    driverStatus,
                    rfqId,
                    manufacturingAssemblyDetailsId: manufacturingAssemblyDetails.id,
                    assemblyId: manufacturingAssemblyDetails.assembly_id,
                }),
            };
        default:
            assertUnreachable(driverStatus);
    }
};

export const getFirstErrorDriverStatusIfAnyConsideringDriverIds = ({
    driverIdsToConsider,
    driverStatuses,
}: {
    driverIdsToConsider: DriverIdDTO[];
    driverStatuses: DriverStatusDetailsDTO[];
}): DriverStatusDetailsDTO | undefined => {
    if (driverStatuses.length === 0 || driverIdsToConsider.length === 0) {
        return undefined;
    }
    const statusesToConsider = driverIdsToConsider
        .map((driverId) => findStatusOfDriver(driverId, driverStatuses))
        .filter(isPresent);

    return getFirstErrorDriverStatusIfAny({ driverStatuses: statusesToConsider });
};

export const getFirstErrorDriverStatusIfAny = ({
    driverStatuses,
}: {
    driverStatuses: DriverStatusDetailsDTO[];
}): DriverStatusDetailsDTO | undefined => {
    if (driverStatuses.length === 0) {
        return undefined;
    }

    const errorDriverStatuses = driverStatuses.filter(
        (driverStatus) =>
            (driverStatus.type === 'Status' && driverStatus.details.status.type !== 'Ok') ||
            driverStatus.type === 'InvalidFormula',
    );

    const first = errorDriverStatuses[0];
    if (first) {
        return first;
    } else {
        return driverStatuses[0];
    }
};

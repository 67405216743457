import { isPresent } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from './errorHandlers/ErrorBoundary';

export function LayoutCard({
    id,
    title,
    actions,
    children,
    style,
}: React.PropsWithChildren<{
    title?: string | JSX.Element | undefined;
    actions?: JSX.Element;
    id?: string;
    style?: React.CSSProperties;
}>): JSX.Element {
    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Flexbox
                id={id}
                flexDirection="column"
                gap={'20px'}
                padding="20px"
                borderRadius="8px"
                sx={{ boxSizing: 'border-box' }}
                style={{ background: colorSystem.neutral.white, ...style }}
            >
                {isPresent(title) && (
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        {typeof title === 'string' ? <Text variant="h2">{title}</Text> : title}
                        <Flexbox gap={8} alignItems={'center'}>
                            {actions}
                        </Flexbox>
                    </Flexbox>
                )}
                {children}
            </Flexbox>
        </Sentry.ErrorBoundary>
    );
}

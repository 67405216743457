import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import {
    AvailabilityType,
    CustomPartTypeEnum,
    DepanelizationDTO,
    GenericPartTypes,
    InventoryPreferenceType,
    OperatorField,
    OperatorId,
    PackageMountingEnum,
    Packaging,
    PartIdTypes,
    QuantityUnit,
    RfqStatus,
    SnapshotTagType,
    SolutionTag,
    SourcingPreferenceOptions,
    SupplierOrigin,
    SupplierPartType,
    SupplierPreference,
    SupplierTag,
    SupplierType,
} from '@luminovo/http-client';
import { SolutionStatus } from './types';

export const packagingTranslations: Record<Packaging, MessageDescriptor> = {
    [Packaging.AmmoPack]: defineMessage({ message: 'Ammo Pack' }),
    [Packaging.Bag]: defineMessage({ message: 'Bag' }),
    [Packaging.Bulk]: defineMessage({ message: 'Bulk' }),
    [Packaging.Reel]: defineMessage({ message: 'Reel' }),
    [Packaging.ReReel]: defineMessage({ message: 'Re-Reel' }),
    [Packaging.Tape]: defineMessage({ message: 'Tape' }),
    [Packaging.Tray]: defineMessage({ message: 'Tray' }),
    [Packaging.Tube]: defineMessage({ message: 'Tube' }),
    [Packaging.Carton]: defineMessage({ message: 'Carton' }),
};

export const dayFormatTranslations: Record<string, MessageDescriptor> = {
    weekDay: defineMessage({ message: 'Week days' }),
    businessDay: defineMessage({ message: 'Business days' }),
    calendarDay: defineMessage({ message: 'Calendar days' }),
};

export const solutionStatusTranslations: Record<SolutionStatus, MessageDescriptor> = {
    [SolutionStatus.Good]: defineMessage({ message: 'Good' }),
    [SolutionStatus.Warning]: defineMessage({ message: 'Warning' }),
    [SolutionStatus.Error]: defineMessage({ message: 'Error' }),
};

export const supplierPreferenceTranslations: Record<SupplierPreference, MessageDescriptor> = {
    [SupplierPreference.NotApproved]: defineMessage({ message: 'Non-approved supplier' }),
    [SupplierPreference.Approved]: defineMessage({ message: 'Approved supplier' }),
    [SupplierPreference.Preferred]: defineMessage({ message: 'Preferred supplier' }),
};

export const supplierTagTranslations: Record<SupplierTag, MessageDescriptor> = {
    [SupplierTag.QuotePartner]: defineMessage({ message: 'Quote partner' }),
    [SupplierTag.ManuallyAdded]: defineMessage({ message: 'Manually added' }),
    [SupplierTag.OffTheShelfPartSupplier]: defineMessage({ message: 'Off-the-shelf part supplier' }),
    [SupplierTag.PcbSupplier]: defineMessage({ message: 'PCB supplier' }),
    [SupplierTag.CustomPartSupplier]: defineMessage({ message: 'Custom part supplier' }),
    [SupplierTag.AssemblySupplier]: defineMessage({ message: 'Assembly supplier' }),
    [SupplierTag.Distributor]: defineMessage({ message: 'Distributor' }),
    [SupplierTag.Manufacturer]: defineMessage({ message: 'Manufacturer' }),
    [SupplierTag.Ems]: defineMessage({ message: 'EMS' }),
    [SupplierTag.System]: defineMessage({ message: 'System' }),
    [SupplierTag.Erp]: defineMessage({ message: 'ERP' }),
    [SupplierTag.Manual]: defineMessage({ message: 'Manual' }),
};

export const supplierTypeTranslations: Record<SupplierType, MessageDescriptor> = {
    [SupplierType.Distributor]: defineMessage({ message: 'Distributor' }),
    [SupplierType.Manufacturer]: defineMessage({ message: 'Manufacturer' }),
    [SupplierType.Ems]: defineMessage({ message: 'EMS' }),
};

export const supplierPartTypeTranslations: Record<SupplierPartType, MessageDescriptor> = {
    [SupplierPartType.OffTheShelf]: defineMessage({ message: 'Off-the-shelf part' }),
    [SupplierPartType.Pcb]: defineMessage({ message: 'PCB' }),
    [SupplierPartType.Custom]: defineMessage({ message: 'Custom part' }),
    [SupplierPartType.Assembly]: defineMessage({ message: 'Assembly' }),
};

export const supplierOriginTranslations: Record<SupplierOrigin, MessageDescriptor> = {
    [SupplierOrigin.System]: defineMessage({ message: 'System' }),
    [SupplierOrigin.Erp]: defineMessage({ message: 'ERP' }),
    [SupplierOrigin.Manual]: defineMessage({ message: 'Manual' }),
};
export const solutionTagTranslations: Record<SolutionTag, MessageDescriptor> = {
    [SolutionTag.Customer]: defineMessage({ message: 'Customer price' }),
    [SolutionTag.RfQ]: defineMessage({ message: 'Project price' }),
    [SolutionTag.ManualOffer]: defineMessage({ message: 'Manual' }),
    [SolutionTag.SupplierNotPreferred]: defineMessage({ message: 'Supplier not preferred' }),
    [SolutionTag.SupplierNotApproved]: defineMessage({ message: 'Supplier not approved' }),
    [SolutionTag.SupplierPreferred]: defineMessage({ message: 'Preferred supplier' }),
    [SolutionTag.SupplierApproved]: defineMessage({ message: 'Approved supplier' }),
    [SolutionTag.SupplierExcluded]: defineMessage({ message: 'Excluded supplier' }),
    [SolutionTag.QuotePrice]: defineMessage({ message: 'Quote price' }),
    [SolutionTag.ContractPrice]: defineMessage({ message: 'Contract price' }),
    [SolutionTag.ListPrice]: defineMessage({ message: 'List price' }),
    [SolutionTag.CustomerNegotiatedPrice]: defineMessage({ message: 'Customer negotiated price' }),
    [SolutionTag.PurchasePrice]: defineMessage({ message: 'Purchase price' }),
    [SolutionTag.StandardPrice]: defineMessage({ message: 'Standard price' }),
    [SolutionTag.Expiring]: defineMessage({ message: 'Expiring' }),
    [SolutionTag.Expired]: defineMessage({ message: 'Expired' }),
    [SolutionTag.CreationDate]: defineMessage({ message: 'Creation date' }),
    [SolutionTag.LongLeadTime]: defineMessage({ message: 'Long lead time' }),
    [SolutionTag.Old]: defineMessage({ message: 'Old' }),
    [SolutionTag.Outdated]: defineMessage({ message: 'Outdated' }),
    [SolutionTag.Unavailable]: defineMessage({ message: 'Unavailable' }),
    [SolutionTag.UnknownLeadTime]: defineMessage({ message: 'Unknown lead time' }),
    [SolutionTag.OnOrder]: defineMessage({ message: 'On order' }),
    [SolutionTag.UnitMismatch]: defineMessage({ message: 'Unit of measurement mismatch' }),
    [SolutionTag.OnePoint]: defineMessage({ message: 'Estimated' }),
    [SolutionTag.Extrapolated]: defineMessage({ message: 'Estimated' }),
    [SolutionTag.Interpolated]: defineMessage({ message: 'Estimated' }),
    [SolutionTag.LowStock]: defineMessage({ message: 'Low stock' }),
    [SolutionTag.Excess]: defineMessage({ message: 'Excess material' }),
    [SolutionTag.AutoSelected]: defineMessage({ message: 'AutoSelected' }),
    [SolutionTag.Inactive]: defineMessage({ message: 'Inactive' }),
    [SolutionTag.Selected]: defineMessage({ message: 'Manually selected' }),
    [SolutionTag.OutdatedManual]: defineMessage({ message: 'Outdated manually selected solution' }),
    [SolutionTag.Converted]: defineMessage({ message: 'Conversion rate' }),
    [SolutionTag.Inventory]: defineMessage({ message: 'Inventory' }),
    [SolutionTag.ExternalSupplier]: defineMessage({ message: 'Market' }),
    [SolutionTag.Packaging]: defineMessage({ message: 'Packaging' }),
    [SolutionTag.OneTimeCost]: defineMessage({ message: 'One-time costs' }),
    [SolutionTag.ManualOneTimeCost]: defineMessage({ message: 'Manual one-time costs' }),
    [SolutionTag.UnitCost]: defineMessage({ message: 'Unit costs' }),
    [SolutionTag.ManualUnitCost]: defineMessage({ message: 'Manual unit costs' }),
    [SolutionTag.AggregatedQuantity]: defineMessage({ message: 'Aggregated quantity' }),
    [SolutionTag.ScrapQuantity]: defineMessage({ message: 'Scrap quantity' }),
    [SolutionTag.Consigned]: defineMessage({ message: 'Consigned' }),
    [SolutionTag.ThirdPartyOrigin]: defineMessage({ message: 'Third party origin' }),
    [SolutionTag.ExceedsOfferedQuantity]: defineMessage({ message: 'Exceeds offered quantity' }),
    [SolutionTag.GreatlyExceedsOfferedQuantity]: defineMessage({ message: 'Greatly exceeds offered quantity' }),
    [SolutionTag.NoApprovedParts]: defineMessage({ message: 'No approved parts' }),
    [SolutionTag.ManualStatus]: defineMessage({ message: 'Manual status' }),
    [SolutionTag.InvalidSpecification]: defineMessage({ message: 'Outdated specification' }),
    [SolutionTag.NonCancellableNonReturnable]: defineMessage({ message: 'NCNR' }),
    [SolutionTag.SourcingScenario]: defineMessage({ message: 'Sourcing scenario' }),
    [SolutionTag.ShippingTime]: defineMessage({ message: 'Shipping time' }),
    [SolutionTag.PanelMismatch]: defineMessage({ message: 'Panel mismatch' }),
};

export const inventoryPreferenceTranslations: Record<InventoryPreferenceType, MessageDescriptor> = {
    PreferInventory: defineMessage({ message: 'Prefer inventory' }),
    Neutral: defineMessage({ message: 'Neutral' }),
    PreferMarketOffers: defineMessage({ message: 'Prefer market offers' }),
};

export const operatorIdTranslations: Record<OperatorId, MessageDescriptor> = {
    Eq: defineMessage({ message: '=' }),
    Gt: defineMessage({ message: '>' }),
    Gte: defineMessage({ message: '≥' }),
    Lt: defineMessage({ message: '<' }),
    Lte: defineMessage({ message: '≤' }),
    // InRange: defineMessage({ message: '' }),
    And: defineMessage({ message: 'and' }),
    Or: defineMessage({ message: 'or' }),
    In: defineMessage({ message: '=' }),
};

export const comparisonOperatorIdTranslations: Record<
    Exclude<OperatorId, 'Or' | 'And' | 'InRange'>,
    MessageDescriptor
> = {
    Eq: defineMessage({ message: '=' }),
    Gt: defineMessage({ message: '>' }),
    Gte: defineMessage({ message: '≥' }),
    Lt: defineMessage({ message: '<' }),
    Lte: defineMessage({ message: '≤' }),
    In: defineMessage({ message: '=' }),
};

export const conditionOperatorIdTranslations: Record<'Or' | 'And', MessageDescriptor> = {
    Or: defineMessage({ message: 'or' }),
    And: defineMessage({ message: 'and' }),
};

export const operatorFieldTranslations: Record<OperatorField, MessageDescriptor> = {
    PartType: defineMessage({ message: 'Part type' }),
    PartCategory: defineMessage({ message: 'Part category' }),
    AggregatedQuantity: defineMessage({ message: 'Aggregated quantity of the part' }),
    OrderSize: defineMessage({ message: 'Order size of the assembly' }),
    Packaging: defineMessage({ message: 'Packaging' }),
    UnitPrice: defineMessage({ message: 'Unit price' }),
    Package: defineMessage({ message: 'Package' }),
    Mounting: defineMessage({ message: 'Mounting' }),
};

export const availabilityTypeTranslations: Record<AvailabilityType, MessageDescriptor> = {
    [AvailabilityType.LeadTime]: defineMessage({ message: 'Lead time' }),
    [AvailabilityType.OnOrder]: defineMessage({ message: 'On order' }),
    [AvailabilityType.OnOrderWithUnknownLeadTime]: defineMessage({ message: 'On order, unknown lead time' }),
    [AvailabilityType.Stock]: defineMessage({ message: 'In stock' }),
    [AvailabilityType.PriceBreakLeadTime]: defineMessage({ message: 'Lead time' }),
};

export const rfqStatusTranslations: Record<RfqStatus, MessageDescriptor> = {
    [RfqStatus.RequestInDraft]: defineMessage({ message: 'Request in draft' }),
    [RfqStatus.QuotationInProgress]: defineMessage({ message: 'Quotation in progress' }),
    [RfqStatus.QuotationAvailable]: defineMessage({ message: 'Quotation available' }),
    [RfqStatus.NoQuotation]: defineMessage({ message: 'No quotation' }),
    [RfqStatus.NoOrder]: defineMessage({ message: 'No order' }),
    [RfqStatus.OrderPlaced]: defineMessage({ message: 'Order placed' }),
    [RfqStatus.OrderConfirmed]: defineMessage({ message: 'Order confirmed' }),
    [RfqStatus.OrderInProduction]: defineMessage({ message: 'Order in production' }),
    [RfqStatus.OrderShipped]: defineMessage({ message: 'Order shipped' }),
    [RfqStatus.OrderInProcurement]: defineMessage({ message: 'Order in procurement' }),
    [RfqStatus.PaymentFailed]: defineMessage({ message: 'Payment failed' }),
};

export const sourcingPreferenceTranslations: Record<SourcingPreferenceOptions, MessageDescriptor> = {
    [SourcingPreferenceOptions.Fastest]: defineMessage({ message: 'Fastest' }),
    [SourcingPreferenceOptions.BestPrice]: defineMessage({ message: 'Best price' }),
    [SourcingPreferenceOptions.BestPriceBy]: defineMessage({ message: 'Best price by' }),
};

export const partSpecificationTypeTranslations: Record<GenericPartTypes, MessageDescriptor> = {
    [GenericPartTypes.Resistor]: defineMessage({ message: 'Resistor' }),
    [GenericPartTypes.Capacitor]: defineMessage({ message: 'Capacitor' }),
};

export const customPartTypeTranslations: Record<CustomPartTypeEnum, MessageDescriptor> = {
    [CustomPartTypeEnum.PCB]: defineMessage({ message: 'PCB' }),
    [CustomPartTypeEnum.Mechanical]: defineMessage({ message: 'Mechanical' }),
    [CustomPartTypeEnum.Cable]: defineMessage({ message: 'Cable & wiring' }),
    [CustomPartTypeEnum.Label]: defineMessage({ message: 'Label & marking' }),
    [CustomPartTypeEnum.Plastic]: defineMessage({ message: 'Plastic' }),
    [CustomPartTypeEnum.Packaging]: defineMessage({ message: 'Packaging' }),
    [CustomPartTypeEnum.Other]: defineMessage({ message: 'Other' }),
};

export const packageMountingEnumTranslations: Record<PackageMountingEnum, MessageDescriptor> = {
    [PackageMountingEnum.SMT]: defineMessage({ message: 'SMT' }),
    [PackageMountingEnum.THT]: defineMessage({ message: 'THT' }),
    [PackageMountingEnum.PressFit]: defineMessage({ message: 'Press fit' }),
    [PackageMountingEnum.Other]: defineMessage({ message: 'Other' }),
};

export const partTypesTranslations: Record<PartIdTypes, MessageDescriptor> = {
    [PartIdTypes.Custom]: defineMessage({ message: 'Custom' }),
    [PartIdTypes.CustomComponent]: defineMessage({ message: 'Custom component' }),
    [PartIdTypes.Generic]: defineMessage({ message: 'Generic' }),
    [PartIdTypes.OffTheShelf]: defineMessage({ message: 'OTS' }),
    [PartIdTypes.Ipn]: defineMessage({ message: 'IPN' }),
};

export const QuantityUnitRecordSingular: Record<QuantityUnit, MessageDescriptor> = {
    [QuantityUnit.Kg]: defineMessage({ message: 'Kilogram' }),
    [QuantityUnit.Meters]: defineMessage({ message: 'Meter' }),
    [QuantityUnit.Liters]: defineMessage({ message: 'Liter' }),
    [QuantityUnit.Pieces]: defineMessage({ message: 'Piece' }),
};

export const QuantityUnitRecordPlural: Record<QuantityUnit, MessageDescriptor> = {
    [QuantityUnit.Kg]: defineMessage({ message: 'Kilograms' }),
    [QuantityUnit.Meters]: defineMessage({ message: 'Meters' }),
    [QuantityUnit.Liters]: defineMessage({ message: 'Liters' }),
    [QuantityUnit.Pieces]: defineMessage({ message: 'Pieces' }),
};

export const depanelizationTranslations: Record<DepanelizationDTO, MessageDescriptor> = {
    Milling: defineMessage({ message: 'Milling' }),
    VCut: defineMessage({ message: 'VCut' }),
    MillingAndVCut: defineMessage({ message: 'Milling and VCut' }),
};

export const snapshotTagTranslations: Record<SnapshotTagType, MessageDescriptor> = {
    [SnapshotTagType.Manual]: defineMessage({ message: 'Manual' }),
    [SnapshotTagType.Note]: defineMessage({ message: 'Note' }),
    [SnapshotTagType.RfqCreated]: defineMessage({ message: 'RfQ created' }),
    [SnapshotTagType.RfqStatusChanged]: defineMessage({ message: 'RfQ status changed' }),
    [SnapshotTagType.SourcingScenarioName]: defineMessage({ message: 'Sourcing scenario' }),
    [SnapshotTagType.SourcingScenarioId]: defineMessage({ message: 'Sourcing scenario' }),
    [SnapshotTagType.CostedBomExported]: defineMessage({ message: 'Costed BOM exported' }),
};

import { Trans } from '@lingui/macro';
import { compareByString, transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import { Flexbox, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { LifecycleEnum } from '@luminovo/http-client';
import { getLifecycleColor, lifecycleEnumPublicTranslations } from '../chips';

export const lifecycleTooltipLabel: Record<LifecycleEnum, { description: JSX.Element }> = {
    [LifecycleEnum.Obsolete]: {
        description: <Trans>Part is no longer available.</Trans>,
    },
    [LifecycleEnum.EndOfLife]: {
        description: <Trans>Last time buy (LTB) has been announced and the part will become obsolete.</Trans>,
    },
    [LifecycleEnum.NotRecommendedForNewDesigns]: {
        description: (
            <Trans>
                Part is in active production, but in most cases, a new generation has been released and is preferred.
            </Trans>
        ),
    },
    [LifecycleEnum.PreRelease]: {
        description: (
            <Trans>The part is in process of qualification for release so the datasheet is subject to change.</Trans>
        ),
    },
    [LifecycleEnum.Active]: {
        description: <Trans>Part is in active production and is recommended for design-in.</Trans>,
    },
    [LifecycleEnum.Unknown]: {
        description: <Trans>The lifecycle information is not available or has not been determined yet.</Trans>,
    },
    [LifecycleEnum.Aftermarket]: {
        description: <Trans>Part is no longer available.</Trans>,
    },
    [LifecycleEnum.Acquired]: {
        description: <Trans>Part has been acquired by another company.</Trans>,
    },
};

export function LifecycleTooltip({ children }: { children: React.ReactElement }): JSX.Element {
    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <Flexbox flexDirection="column" gap={4} padding="4px">
                    {typeSafeObjectKeys(LifecycleEnum)
                        .sort((a, b) => compareByString(a, b))
                        .map((status, index) => {
                            const color = colorSystem[getLifecycleColor(status as LifecycleEnum)][5];
                            return (
                                <Flexbox flexDirection="column" gap="4px" padding="4px" key={index}>
                                    <Flexbox gap={4} alignItems="center">
                                        <div
                                            style={{
                                                borderRadius: 4,
                                                width: 8,
                                                height: 8,
                                                backgroundColor: color,
                                            }}
                                        />
                                        <Text variant="h5">{transEnum(status, lifecycleEnumPublicTranslations)}</Text>
                                    </Flexbox>
                                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                                        {lifecycleTooltipLabel[status].description}
                                    </Text>
                                </Flexbox>
                            );
                        })}
                </Flexbox>
            }
        >
            {children}
        </Tooltip>
    );
}

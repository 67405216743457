import { t, Trans } from '@lingui/macro';
import { Protected } from '@luminovo/auth';
import { formatDecimal, isPresent, transEnum } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    Flexbox,
    FormItem,
    Link,
    RightBoxDrawer,
    SecondaryIconButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { CustomFullPart, CustomPartTypeEnum } from '@luminovo/http-client';
import { ComplianceStatusTranslations, customPartTypeTranslations } from '@luminovo/sourcing-core';
import { DescriptionOutlined, Edit } from '@mui/icons-material';
import { CircularProgress, Skeleton, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useCustomPartEditDialog } from '../../../modules/PartLibrary/PartDetailsPage/components/CustomPartDialogs/useCustomPartEditDialog';
import { useCustomPart, useCustomPartResources } from '../../../resources/part/partHandler';
import { getFileNameFromResourcesString } from '../../../utils/stringFunctions';
import { CloseDrawerButton, useDrawerContext } from '../../contexts/ModalContext';
import { PartCardLayout } from '../PartCardLayout';
import { DetailsButton } from '../PartSpecificationCardFields';

interface CustomPartCardProps {
    partId: string;
    cardStyle?: React.CSSProperties;
    isTruncated?: boolean;
}

export function useCustomPartCardDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ partId }: { partId: string }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox flexDirection={'column'} style={{ width: '400px' }}>
                        <CustomPartCardDetails partId={partId} BackButton={() => <CloseDrawerButton />} />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}

const EditButton: React.FunctionComponent<{
    customPartId: string;
}> = ({ customPartId }) => {
    const { openDialog } = useCustomPartEditDialog();

    return (
        <Protected requiredPermissions={['edit:custom:part']}>
            <Tooltip title={t`Edit part`} placement="top" arrow>
                <SecondaryIconButton size="small" onClick={() => openDialog({ customPartId })}>
                    <Edit fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
        </Protected>
    );
};

export const CustomPartCardDetails: React.FunctionComponent<{
    partId: string;
    BackButton: React.ComponentType;
}> = ({ partId, BackButton }): JSX.Element => {
    const { data: part } = useCustomPart(partId);
    const { data: resources = [], isLoading: isLoadingResources } = useCustomPartResources(partId);

    if (!isPresent(part)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <Flexbox flexDirection={'column'} gap={20} paddingLeft={'16px'}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'} marginTop={'16px'} marginRight={'16px'}>
                <BackButton />
                <EditButton customPartId={part.id} />
            </Flexbox>

            <Text variant="h2">
                <Trans>Custom part</Trans>
            </Text>

            <FormItem label={t`Type`}>
                <Text>{transEnum(part.type.name, customPartTypeTranslations)}</Text>
            </FormItem>
            <FormItem label={t`RoHS compliance`}>
                <Text>{transEnum(part.rohs_compliant, ComplianceStatusTranslations)}</Text>
            </FormItem>
            <FormItem label={t`REACH compliance`}>
                <Text>{transEnum(part.reach_compliant, ComplianceStatusTranslations)}</Text>
            </FormItem>
            <FormItem label={t`Files`}>
                {isLoadingResources ? (
                    <Skeleton />
                ) : (
                    <>
                        {resources.length === 0 && (
                            <Text color={colorSystem.neutral[7]}>
                                <Trans>No files added</Trans>
                            </Text>
                        )}
                        {resources.map((resource, index) => {
                            return (
                                <Link
                                    href={resource}
                                    download={resource}
                                    key={index}
                                    attention="high"
                                    startIcon={<DescriptionOutlined />}
                                >
                                    {getFileNameFromResourcesString(resource)}
                                </Link>
                            );
                        })}
                    </>
                )}
            </FormItem>
            <FormItem label={t`Description`}>
                {part.description ? (
                    <CustomPartDescription part={part} />
                ) : (
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>No description added</Trans>
                    </Text>
                )}
            </FormItem>
        </Flexbox>
    );
};

const CustomPartDescription: React.FunctionComponent<{ part: CustomFullPart } & TypographyProps> = ({
    part,
    ...rest
}) => (
    <Typography {...rest}>
        {part.type.name === CustomPartTypeEnum.PCB ? <Trans>Automatically created</Trans> : part.description}
    </Typography>
);

export const CustomPartCard = ({ partId, cardStyle }: CustomPartCardProps): JSX.Element => {
    const { data: resources = [], isLoading: isLoadingResources } = useCustomPartResources(partId);
    const { data: part } = useCustomPart(partId);
    const { openDrawer } = useCustomPartCardDrawer();

    if (!isPresent(part)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }
    return (
        <PartCardLayout
            headerSection={
                <>
                    <Flexbox alignItems={'center'} gap={8}>
                        <Text variant="h5" color={colorSystem.neutral[5]}>
                            <Trans>Custom</Trans>
                        </Text>
                        <Text variant="h5">{transEnum(part.type.name, customPartTypeTranslations)}</Text>
                    </Flexbox>
                    <Flexbox gap={8} alignItems="center">
                        <Text variant="h5" color={colorSystem.neutral[5]}>
                            <Trans>Files</Trans>
                        </Text>
                        {isLoadingResources ? (
                            <Skeleton width={20} />
                        ) : (
                            <Typography variant="h5">{formatDecimal(resources.length)}</Typography>
                        )}
                    </Flexbox>
                </>
            }
            headerHover={<DetailsButton handleClick={() => openDrawer({ partId })} />}
            footerSection={<CustomPartDescription part={part} variant="subtitle1" color="textSecondary" noWrap />}
            cardStyle={cardStyle}
        />
    );
};

import { colorSystem, Flexbox, Text } from '@luminovo/design-system';

export function PropertiesTable({ properties }: { properties: Record<string, string | JSX.Element> }) {
    return (
        <Flexbox flexDirection={'column'} gap="20px">
            {Object.entries(properties).map(([label, value]) => (
                <PropertyLabel key={label} label={label} value={value} />
            ))}
        </Flexbox>
    );
}

function PropertyLabel({ label, value }: { label: string; value: string | JSX.Element }) {
    return (
        <Flexbox flexDirection="column" gap="4px">
            <Text variant="body-small" color={colorSystem.neutral[8]}>
                {label}
            </Text>
            <Text variant="h4" color={colorSystem.neutral[9]}>
                {value}
            </Text>
        </Flexbox>
    );
}

/* eslint-disable import/no-unused-modules */
import * as rt from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';

export const BigPointRuntype = rt.Record({ x: rt.Number, y: rt.Number });

export type BigPoint = rt.Static<typeof BigPointRuntype>;

export const DimensionRuntype = rt.Record({
    min: BigPointRuntype,
    max: BigPointRuntype,
});

export type Dimension = rt.Static<typeof DimensionRuntype>;

export const FormatRuntype = rt.Record({
    unit: rt.String,
    resolution: rt.Number,
    dimension: DimensionRuntype.optional(),
    complexity: rt.Number.optional(),
    scaling: rt.Number.optional(),
    gerberscale: rt.Number.optional(),
});

export type Format = rt.Static<typeof FormatRuntype>;

export enum PCBFileTypes {
    OUTLINE = 'Outline',
    KEEP_OUT = 'KeepOut',
    PASTE_TOP = 'PasteTop',
    PASTE_BOTTOM = 'PasteBottom',
    SILKSCREEN_TOP = 'SilkscreenTop',
    SILKSCREEN_BOTTOM = 'SilkscreenBottom',
    SOLDERMASK_TOP = 'SoldermaskTop',
    SOLDERMASK_BOTTOM = 'SoldermaskBottom',
    COPPER_TOP = 'CopperTop',
    COPPER_MID = 'CopperMid',
    PLANE_MID = 'PlaneMid',
    COPPER_BOTTOM = 'CopperBottom',
    DRILL = 'Drill',
    PHDRILL = 'PHDrill',
    NPHDRILL = 'NPHDrill',
    DRILLSETS = 'DrillSets',
    MECHANICAL = 'Mechanical',
    NATIVE_ALTIUM = 'native-altium',
    NATIVE_KICAD = 'native-kicad',
    NATIVE_EAGLE = 'native-eagle',
    LEGACY_GERBER = 'LegacyGerber',
    UNMATCHED = 'unmatched',
    UNKNOWN = 'unknown',
    ADHESIVE_TOP = 'AdhesiveTop',
    ADHESIVE_BOTTOM = 'AdhesiveBottom',
    PEELABLE_TOP = 'PeelableTop',
    PEELABLE_BOTTOM = 'PeelableBottom',
    STACK_UP = 'StackUp',
}

export const PCBFileTypesRuntimeType = runtypeFromEnum(PCBFileTypes);

export enum PCBFileCategory {
    GERBER = 'gerber',
    ODB = 'odb',
    MECHANICAL = 'mechanical',
    UNKNOWN = 'unknown',
}

export const PCBFileCategoryRuntimeType = runtypeFromEnum(PCBFileCategory);

export const FileTypeRuntype = rt.Record({
    service: rt.String,
    category: PCBFileCategoryRuntimeType.optional(),
    fileType: PCBFileTypesRuntimeType,
    productionFile: rt.Boolean,
    mimeType: rt.Optional(rt.String.nullable()),
    index: rt.Optional(rt.Number.nullable()),
    from: rt.Optional(rt.Number.nullable()),
    to: rt.Optional(rt.Number.nullable()),
});

export type FileType = rt.Static<typeof FileTypeRuntype>;

export const MetaInfoRuntype = rt.Record({ properties: rt.Record({}) });

export type MetaInfo = rt.Static<typeof MetaInfoRuntype>;

export const LayerFileRuntype = rt.Record({
    id: rt.String.optional(),
    name: rt.String.optional(),
    data: rt.String.optional(),
    format: FormatRuntype.optional(),
    fileType: FileTypeRuntype.optional(),
    metaInfo: MetaInfoRuntype,
    inverted: rt.Boolean,
});

export type LayerFile = rt.Static<typeof LayerFileRuntype>;

export const MetaStatusRuntype = rt.Record({
    name: rt.String,
    since: rt.String,
});

export type MetaStatus = rt.Static<typeof MetaStatusRuntype>;

export const DrillSetRuntype = rt.Record({
    name: rt.String.optional(),
    filename: rt.String.optional(),
    layers: rt.Array(rt.String),
});

export type DrillSet = rt.Static<typeof DrillSetRuntype>;

export const PCBRuntype = rt.Record({
    assembly: rt.String,
    version: rt.String,
    outline: LayerFileRuntype.optional(),
    holes: rt.Array(rt.String),
    files: rt.Array(LayerFileRuntype),
    metaInfo: MetaInfoRuntype.optional(),
    analysisStatus: MetaStatusRuntype.optional(),
    specifications: rt.Array(rt.String),
    defaultSpecification: rt.String.optional(),
    drillSets: rt.Array(DrillSetRuntype).optional(),
});

export type PCB = rt.Static<typeof PCBRuntype>;

export const AssemblyFeatureRuntype = rt.Record({
    service: rt.String,
    feature: rt.String,
});

export type AssemblyFeature = rt.Static<typeof AssemblyFeatureRuntype>;

export const AssemblyStatusRuntype = rt.Record({});

export type AssemblyStatus = rt.Static<typeof AssemblyStatusRuntype>;

export const UIStatusRuntype = rt.Record({
    status: rt.String,
    progress: rt.Number.optional(),
    messages: rt.Array(rt.String).optional(),
});

export type UIStatus = rt.Static<typeof UIStatusRuntype>;

export const DeadlineDocRuntype = rt.Record({
    time: rt.Record({
        length: rt.Number,
        unit: rt.Union(
            rt.Literal('NANOSECONDS'),
            rt.Literal('MICROSECONDS'),
            rt.Literal('MILLISECONDS'),
            rt.Literal('SECONDS'),
            rt.Literal('MINUTES'),
            rt.Literal('HOURS'),
            rt.Literal('DAYS'),
        ),
    }),
});

export type DeadlineDoc = rt.Static<typeof DeadlineDocRuntype>;

export const FileLifecycleStateRuntype = rt.Record({
    name: rt.Union(
        rt.Literal('unknown'),
        rt.Literal('waiting'),
        rt.Literal('progress'),
        rt.Literal('error'),
        rt.Literal('success'),
        rt.Literal('timeout'),
    ),
    messages: rt.Array(rt.String),
    percent: rt.Number.optional(),
    duration: rt.Number.optional(),
    deadline: DeadlineDocRuntype.optional(),
});

export type FileLifecycleState = rt.Static<typeof FileLifecycleStateRuntype>;

export const HistoricLifecycleStateRuntype = rt.Record({
    name: rt.String,
    start: rt.Number,
    end: rt.Number.optional(),
});

export type HistoricLifecycleState = rt.Static<typeof HistoricLifecycleStateRuntype>;

export const FileLifecycleRuntype = rt.Record({
    name: rt.Union(
        rt.Literal('main'),
        rt.Literal('composition'),
        rt.Literal('dfmanalysis'),
        rt.Literal('customer-panel'),
        rt.Literal('dfm'),
        rt.Literal('outline'),
        rt.Literal('reconciliation'),
        rt.Literal('analysis'),
        rt.Literal('files'),
        rt.Literal('fileanalysis'),
        rt.Literal('layerstack'),
        rt.Literal('render'),
        rt.Literal('specification-render'),
        rt.Literal('reconciled-specification-render'),
        rt.Literal('initialization'),
        rt.Literal('production-analysis'),
        rt.Literal('preview'),
    ),
    status: FileLifecycleStateRuntype,
    history: rt.Array(HistoricLifecycleStateRuntype).optional(),
});

export type FileLifecycle = rt.Static<typeof FileLifecycleRuntype>;

export const FileRuntype = rt.Record({
    name: rt.String,
    fType: FileTypeRuntype,
    detectedTypes: rt.Array(FileTypeRuntype),
    created: rt.String,
    preview: rt.String.optional(),
    lifecycles: rt.Array(FileLifecycleRuntype),
});

export type File = rt.Static<typeof FileRuntype>;

export const VersionRuntype = rt.Record({
    id: rt.String,
    name: rt.String.optional(),
    created: rt.String,
    released: rt.String.optional(),
    files: rt.Array(FileRuntype).optional(),
    filesLocked: rt.Boolean,
    lifecycles: rt.Array(FileLifecycleRuntype),
});

export type Version = rt.Static<typeof VersionRuntype>;

export const AssemblyReferenceRuntype = rt.Record({
    team: rt.String,
    id: rt.String,
    gid: rt.String.optional(),
    version: rt.String,
});

export type AssemblyReference = rt.Static<typeof AssemblyReferenceRuntype>;

export const AssemblyRuntype = rt.Record({
    team: rt.String,
    id: rt.String,
    gid: rt.String,
    name: rt.String,
    description: rt.String.optional(),
    creator: rt.String,
    created: rt.String,
    customer: rt.String.optional(),
    orderId: rt.String.optional(),
    assignee: rt.String.optional(),
    itemNo: rt.String.optional(),
    features: rt.Array(AssemblyFeatureRuntype),
    status: AssemblyStatusRuntype,
    uiStatus: UIStatusRuntype,
    currentVersion: VersionRuntype.optional(),
    preview: rt.String.optional(),
    mail: rt.String.optional(),
    template: AssemblyReferenceRuntype.optional(),
});

export type Assembly = rt.Static<typeof AssemblyRuntype>;

export const MaterialRuntype = rt.Record({
    team: rt.String.optional(),
    id: rt.String.optional(),
    name: rt.String.optional(),
    meta: MetaInfoRuntype.optional(),
    materialType: rt.String.optional(),
});

export type Material = rt.Static<typeof MaterialRuntype>;

export const LayerDefinitionRuntype = rt.Record({
    id: rt.String.optional(),
    layerType: rt.String.optional(),
    meta: MetaInfoRuntype.optional(),
    materialRef: rt.String.optional(),
    material: MaterialRuntype.optional(),
});

export type LayerDefinition = rt.Static<typeof LayerDefinitionRuntype>;

export const DrillSupportRuntype = rt.Record({
    from: rt.Number,
    to: rt.Number,
    col: rt.Number.optional(),
    drillType: rt.String,
});

export type DrillSupport = rt.Static<typeof DrillSupportRuntype>;

export const SubStackDefinitionRuntype = rt.Record({
    name: rt.String,
    layers: rt.Array(LayerDefinitionRuntype).optional(),
    stackType: rt.String.optional(),
    drills: rt.Array(DrillSupportRuntype).optional(),
    offset: rt.Number.optional(),
});

export type SubStackDefinition = rt.Static<typeof SubStackDefinitionRuntype>;

export const LayerstackDefinitionAPIRuntype = rt.Record({
    id: rt.String.optional(),
    team: rt.String.optional(),
    name: rt.String.optional(),
    stacks: rt.Array(SubStackDefinitionRuntype).optional(),
    price: rt.Number.optional(),
    metaInfo: MetaInfoRuntype.optional(),
    creation: rt.String.optional(),
    image: rt.String.optional(),
    default: rt.Boolean.optional(),
});

export type LayerstackDefinitionAPI = rt.Static<typeof LayerstackDefinitionAPIRuntype>;

export const LayerAPIRuntype = rt.Record({
    definition: LayerDefinitionRuntype,
    files: rt.Array(LayerFileRuntype).optional(),
    metaInfo: MetaInfoRuntype,
});

export type LayerAPI = rt.Static<typeof LayerAPIRuntype>;

export const SubStackAPIRuntype = rt.Record({
    definition: SubStackDefinitionRuntype,
    layers: rt.Array(LayerAPIRuntype),
    metaInfo: MetaInfoRuntype,
});

export type SubStackAPI = rt.Static<typeof SubStackAPIRuntype>;

export const LayerStackAPIRuntype = rt.Record({
    assembly: AssemblyReferenceRuntype,
    definition: LayerstackDefinitionAPIRuntype,
    stacks: rt.Array(SubStackAPIRuntype),
    unmatchedFiles: rt.Array(LayerFileRuntype),
});

export type LayerStackAPI = rt.Static<typeof LayerStackAPIRuntype>;

export const CustomerPanelElementRuntype = rt.Record({});

export type CustomerPanelElement = rt.Static<typeof CustomerPanelElementRuntype>;

export const SpacingRuntype = rt.Record({
    topPadding: rt.Number.optional(),
    rightPadding: rt.Number.optional(),
    leftPadding: rt.Number.optional(),
    bottomPadding: rt.Number.optional(),
    verticalSpacing: rt.Number.optional(),
    horizontalSpacing: rt.Number.optional(),
});

export type Spacing = rt.Static<typeof SpacingRuntype>;

export const WorkingPanelUsageAPIRuntype = rt.Record({
    workingPanel: rt.String,
    customerPanels: rt.Number,
    customerBoards: rt.Number,
    panelYield: rt.Number,
    preview: rt.String.optional(),
});

export type WorkingPanelUsageAPI = rt.Static<typeof WorkingPanelUsageAPIRuntype>;

export const CustomerPanelAPIRuntype = rt.Record({
    id: rt.String.optional(),
    name: rt.String,
    elements: rt.Array(CustomerPanelElementRuntype),
    description: rt.String.optional(),
    preview: rt.String.optional(),
    spacing: SpacingRuntype,
    working: rt.Array(WorkingPanelUsageAPIRuntype).optional(),
    selected: rt.String.optional(),
    bestYield: rt.String.optional(),
    width: rt.Number.optional(),
    height: rt.Number.optional(),
    weight: rt.Number.optional(),
});

export type CustomerPanelAPI = rt.Static<typeof CustomerPanelAPIRuntype>;

export const CollectedPCBRuntype = rt.Record({
    assembly: AssemblyRuntype,
    pcb: PCBRuntype,
    layerstack: LayerStackAPIRuntype.optional(),
    panels: rt.Array(CustomerPanelAPIRuntype).optional(),
});

export type CollectedPCB = rt.Static<typeof CollectedPCBRuntype>;

export const PCBUpdateRuntype = rt.Record({
    defaultSpecification: rt.String.optional(),
});

export type PCBUpdate = rt.Static<typeof PCBUpdateRuntype>;

export const SetOutlineRuntype = rt.Record({
    name: rt.String,
    userChoice: rt.Boolean.optional(),
});

export type SetOutline = rt.Static<typeof SetOutlineRuntype>;

export const GraphicUsageRuntype = rt.Record({
    reference: rt.String,
    location: BigPointRuntype,
});

export type GraphicUsage = rt.Static<typeof GraphicUsageRuntype>;

export const GraphicElementRuntype = rt.Record({
    path: rt.String.optional(),
    tag: rt.String.optional(),
    use: GraphicUsageRuntype.optional(),
    attributes: rt.Record({}).optional(),
    elementIds: rt.Array(rt.String).optional(),
    trace: rt.String.optional(),
});

export type GraphicElement = rt.Static<typeof GraphicElementRuntype>;

export const GraphicDefinitionRuntype = rt.Record({
    id: rt.String,
    path: rt.String,
});

export type GraphicDefinition = rt.Static<typeof GraphicDefinitionRuntype>;

export const GraphicRuntype = rt.Record({
    viewbox: DimensionRuntype,
    format: FormatRuntype,
    count: rt.Number,
    paths: rt.Array(rt.Array(GraphicElementRuntype)),
    defs: rt.Array(GraphicDefinitionRuntype),
});

export type Graphic = rt.Static<typeof GraphicRuntype>;

export const LayerFileUpdateRuntype = rt.Record({
    inverted: rt.Boolean.optional(),
});

export type LayerFileUpdate = rt.Static<typeof LayerFileUpdateRuntype>;

export const MessageRuntype = rt.Record({});

export type Message = rt.Static<typeof MessageRuntype>;

export const FilePathRuntype = rt.Record({
    fsroot: rt.String,
    team: rt.String,
    resource: rt.String,
    base: rt.String,
    filename: rt.String,
});

export type FilePath = rt.Static<typeof FilePathRuntype>;

export const PCBSpecificationRuntype = rt.Record({
    assembly: AssemblyReferenceRuntype,
    id: rt.String,
    alias: rt.String,
    dfm: MetaInfoRuntype,
    settings: MetaInfoRuntype,
    user: MetaInfoRuntype.optional(),
    status: rt.String,
    technology: rt.String,
    base: MetaInfoRuntype.optional(),
    preview: FilePathRuntype.optional(),
    previewRear: FilePathRuntype.optional(),
});

export type PCBSpecification = rt.Static<typeof PCBSpecificationRuntype>;

export const SpecificationPreviewsRuntype = rt.Record({
    front: rt.String.optional(),
    rear: rt.String.optional(),
});

export type SpecificationPreviews = rt.Static<typeof SpecificationPreviewsRuntype>;

export const PCBSpecificationApiRuntype = rt.Record({
    spec: PCBSpecificationRuntype,
    previews: SpecificationPreviewsRuntype,
});

export type PCBSpecificationApi = rt.Static<typeof PCBSpecificationApiRuntype>;

export const PCBSpecificationCreationRuntype = rt.Record({
    alias: rt.String,
    template: rt.String.optional(),
});

export type PCBSpecificationCreation = rt.Static<typeof PCBSpecificationCreationRuntype>;

export const PropertyRuntype = rt.Record({});

export type Property = rt.Static<typeof PropertyRuntype>;

export const PCBSpecificationUpdateRuntype = rt.Record({
    dfm: rt.Array(PropertyRuntype).optional(),
    settings: rt.Array(PropertyRuntype).optional(),
    user: rt.Array(PropertyRuntype).optional(),
    status: rt.String.optional(),
});

export type PCBSpecificationUpdate = rt.Static<typeof PCBSpecificationUpdateRuntype>;

export const PCBV2CreationRuntype = rt.Record({
    name: rt.String.optional(),
    customer: rt.String.optional(),
    contact: rt.String.optional(),
    orderId: rt.String.optional(),
    description: rt.String.optional(),
});

export type PCBV2Creation = rt.Static<typeof PCBV2CreationRuntype>;

export const PCBV2FileRuntype = rt.Record({
    id: rt.String.optional(),
    name: rt.String,
    fileType: FileTypeRuntype,
    preview: rt.Optional(rt.String.nullable()),
    path: rt.String.optional(),
    lifecycles: rt.Array(FileLifecycleRuntype),
});

export type PCBV2File = rt.Static<typeof PCBV2FileRuntype>;

export const FileAccessRuntype = rt.Record({ path: rt.String, key: rt.String });

export type FileAccess = rt.Static<typeof FileAccessRuntype>;

export const PCBPreviewsRuntype = rt.Record({
    front: FileAccessRuntype.optional(),
    rear: FileAccessRuntype.optional(),
});

export type PCBPreviews = rt.Static<typeof PCBPreviewsRuntype>;

export enum PCBSurfaceFinish {
    NONE = 'none',
    HAL_PB = 'hal-pb',
    HAL_PB_FREE = 'hal-pb-free',
    ENIG = 'enig',
    ENIPIG = 'enipig',
    ENEPIG = 'enepig',
    ENAG = 'enag',
    ASIG = 'asig',
    EP = 'ep',
    EPAG = 'epag',
    EPIG = 'epig',
    IS = 'is',
    IT = 'it',
    ISIG = 'isig',
    OSP = 'osp',
}

export enum PCBSilkscreenColors {
    WHITE = 'white',
    BLACK = 'black',
    YELLOW = 'yellow',
    RED = 'red',
    GREEN = 'green',
    BLUE = 'blue',
}

export enum PCBSoldermaskColors {
    RED = 'red',
    GREEN = 'green',
    GREEN_MATT = 'green-matt',
    GREEN_GLOSSY = 'green-glossy',
    WHITE = 'white',
    BLUE = 'blue',
    BLACK = 'black',
    YELLOW = 'yellow',
    PURPLE = 'purple',
}

export enum PCBLayerSides {
    NONE = 'none',
    TOP = 'top',
    BOTTOM = 'bottom',
    BOTH = 'both',
}

export enum PCBIPC600Class {
    NONE = 'ipcnone',
    IPC1 = 'ipc1',
    IPC2 = 'ipc2',
    IPC3 = 'ipc3',
}

export enum FlammabilityRating {
    NONE = 'none',
    V0 = 'v-0',
    V1 = 'v-1',
    V2 = 'v-2',
}

export enum PCBBaseMaterial {
    FR2 = 'fr-2',
    FR3 = 'fr-3',
    FR4 = 'fr-4',
    CEM1 = 'cem1',
    CEM2 = 'cem2',
    CEM3 = 'cem3',
    POLYIMIDE = 'polyimide',
}

// We use Reflection to map and using runtypeFromEnum is not working
const SilkscreenColorLiterals = Object.values(PCBSilkscreenColors).map((color) => rt.Literal(color.toString()));
const SoldermaskColorLiterals = Object.values(PCBSoldermaskColors).map((color) => rt.Literal(color.toString()));
const PCBLayerSidesLiterals = Object.values(PCBLayerSides).map((side) => rt.Literal(side.toString()));
const PCBSurfaceFinishLiterals = Object.values(PCBSurfaceFinish).map((finish) => rt.Literal(finish.toString()));
const PCBBaseMaterialLiterals = Object.values(PCBBaseMaterial).map((finish) => rt.Literal(finish.toString()));
const PCBIPC600ClassLiterals = Object.values(PCBIPC600Class).map((finish) => rt.Literal(finish.toString()));
const FlammabilityRatingLiterals = Object.values(FlammabilityRating).map((finish) => rt.Literal(finish.toString()));

export const PCBV2BoardPropertiesRuntype = rt.Record({
    boardHeight: rt.Number.optional(),
    boardWidth: rt.Number.optional(),
    silkscreenColor: rt.Union(SilkscreenColorLiterals[0], ...SilkscreenColorLiterals).optional(),
    silkscreenSide: rt.Union(PCBLayerSidesLiterals[0], ...PCBLayerSidesLiterals).optional(),
    surfaceFinish: rt.Union(PCBSurfaceFinishLiterals[0], ...PCBSurfaceFinishLiterals).optional(),
    enigThickness: rt.Number.optional(),
    applicationType: rt
        .Union(
            rt.Literal('industrial'),
            rt.Literal('military'),
            rt.Literal('automotive'),
            rt.Literal('medical'),
            rt.Literal('consumer'),
            rt.Literal('aerospace'),
        )
        .optional(),
    notes: rt.String.optional(),
    hardGold: rt.Boolean.optional(),
    hardGoldArea: rt.Number.optional(),
    exposedCopperArea: rt.Number.optional(),
    exposedCopperAreaTop: rt.Number.optional(),
    exposedCopperAreaBottom: rt.Number.optional(),
    traceWidth: rt.Number.optional(),
    copperClearance: rt.Number.optional(),
    soldermaskColor: rt.Union(SoldermaskColorLiterals[0], ...SoldermaskColorLiterals).optional(),
    soldermaskSide: rt.Union(PCBLayerSidesLiterals[0], ...PCBLayerSidesLiterals).optional(),
    soldermaskDam: rt.Number.optional(),
    soldermaskClearance: rt.Number.optional(),
    ipc600Class: rt.Union(PCBIPC600ClassLiterals[0], ...PCBIPC600ClassLiterals).optional(),
    eTest: rt.Boolean.optional(),
    pressFit: rt.Boolean.optional(),
    impedanceTested: rt.Boolean.optional(),
    peelableMask: rt.Union(PCBLayerSidesLiterals[0], ...PCBLayerSidesLiterals).optional(),
    captonTape: rt.Union(rt.Literal('none'), rt.Literal('top'), rt.Literal('bottom'), rt.Literal('both')).optional(),
    reports: rt
        .Array(
            rt.Union(
                rt.Literal('cross_section_report'),
                rt.Literal('first_sample_test_report'),
                rt.Literal('impedance_report'),
                rt.Literal('coc_report'),
            ),
        )
        .optional(),
    itar: rt.Boolean.optional(),
    carbonPrint: rt.Boolean.optional(),
    edgeMetalization: rt.Boolean.optional(),
    // deprecated => use ulMarkingType instead, plus we will add datecode and manufactureridentificationmarking separately
    marking: rt
        .Array(
            rt.Union(
                rt.Literal('ulmarking'),
                rt.Literal('datecodemarking'),
                rt.Literal('manufactureridentificationmarking'),
            ),
        )
        .optional(),
    flammabilityRating: rt.Union(FlammabilityRatingLiterals[0], ...FlammabilityRatingLiterals).optional(),
    ctiClass: rt.Union(rt.Literal('i'), rt.Literal('ii'), rt.Literal('iiia'), rt.Literal('iiib')).optional(),
    maxXOutsAllowed: rt.Number.optional(),
    halogenFree: rt.Boolean.optional(),
    placementSide: rt.Union(PCBLayerSidesLiterals[0], ...PCBLayerSidesLiterals).optional(),
});

// Extracting values from the PCBV2BoardPropertiesRuntype record
export type PCBV2BoardPropertiesValuesDTO = rt.Static<typeof PCBV2BoardPropertiesValuesRuntype>;
export const PCBV2BoardPropertiesValuesRuntype = rt.Union(
    PCBV2BoardPropertiesRuntype.fields.boardHeight,
    PCBV2BoardPropertiesRuntype.fields.boardWidth,
    PCBV2BoardPropertiesRuntype.fields.silkscreenColor,
    PCBV2BoardPropertiesRuntype.fields.silkscreenSide,
    PCBV2BoardPropertiesRuntype.fields.surfaceFinish,
    PCBV2BoardPropertiesRuntype.fields.enigThickness,
    PCBV2BoardPropertiesRuntype.fields.applicationType,
    PCBV2BoardPropertiesRuntype.fields.notes,
    PCBV2BoardPropertiesRuntype.fields.hardGold,
    PCBV2BoardPropertiesRuntype.fields.hardGoldArea,
    PCBV2BoardPropertiesRuntype.fields.exposedCopperArea,
    PCBV2BoardPropertiesRuntype.fields.exposedCopperAreaTop,
    PCBV2BoardPropertiesRuntype.fields.exposedCopperAreaBottom,
    PCBV2BoardPropertiesRuntype.fields.traceWidth,
    PCBV2BoardPropertiesRuntype.fields.copperClearance,
    PCBV2BoardPropertiesRuntype.fields.soldermaskColor,
    PCBV2BoardPropertiesRuntype.fields.soldermaskSide,
    PCBV2BoardPropertiesRuntype.fields.soldermaskDam,
    PCBV2BoardPropertiesRuntype.fields.soldermaskClearance,
    PCBV2BoardPropertiesRuntype.fields.ipc600Class,
    PCBV2BoardPropertiesRuntype.fields.eTest,
    PCBV2BoardPropertiesRuntype.fields.pressFit,
    PCBV2BoardPropertiesRuntype.fields.impedanceTested,
    PCBV2BoardPropertiesRuntype.fields.peelableMask,
    PCBV2BoardPropertiesRuntype.fields.captonTape,
    PCBV2BoardPropertiesRuntype.fields.reports,
    PCBV2BoardPropertiesRuntype.fields.itar,
    PCBV2BoardPropertiesRuntype.fields.carbonPrint,
    PCBV2BoardPropertiesRuntype.fields.edgeMetalization,
    PCBV2BoardPropertiesRuntype.fields.marking,
    PCBV2BoardPropertiesRuntype.fields.flammabilityRating,
    PCBV2BoardPropertiesRuntype.fields.ctiClass,
    PCBV2BoardPropertiesRuntype.fields.maxXOutsAllowed,
    PCBV2BoardPropertiesRuntype.fields.halogenFree,
    PCBV2BoardPropertiesRuntype.fields.placementSide,
);

export type PCBV2BoardProperties = rt.Static<typeof PCBV2BoardPropertiesRuntype>;

export const PCBV2LayerStackPropertiesRuntype = rt.Record({
    layerstackType: rt
        .Union(rt.Literal('rigid'), rt.Literal('flex'), rt.Literal('ims'), rt.Literal('rigid-flex'))
        .optional(),
    layerstackThicknessTolerance: rt.Number.optional(),
    ulLayerStack: rt.Boolean.optional(),
    ulMarkingType: rt
        .Union(rt.Literal('no-marking'), rt.Literal('default-marking'), rt.Literal('custom-marking'))
        .optional(),
    layercount: rt.Number.optional(),
    finalThickness: rt.Number.optional(),
    baseMaterial: rt.Union(PCBBaseMaterialLiterals[0], ...PCBBaseMaterialLiterals).optional(),
    outerCopperThickness: rt.Number.optional(),
    innerCopperThickness: rt.Number.optional(),
    minOuterLayerStructure: rt.Number.optional(),
    minInnerLayerStructure: rt.Number.optional(),
    rohsCompilant: rt.Boolean.optional(),
    tgValue: rt.Number.optional(),
});

export type PCBV2LayerStackProperties = rt.Static<typeof PCBV2LayerStackPropertiesRuntype>;

export const PCBV2MechanicalPropertiesRuntype = rt.Record({
    minViaDiameter: rt.Number.optional(),
    viaFilling: rt
        .Union(
            rt.Literal('none'),
            rt.Literal('ia'),
            rt.Literal('ib'),
            rt.Literal('iib'),
            rt.Literal('iiia'),
            rt.Literal('iiib'),
            rt.Literal('iva'),
            rt.Literal('ivb'),
            rt.Literal('v'),
            rt.Literal('vi'),
            rt.Literal('vii'),
        )
        .optional(),
    blindVias: rt.Boolean.optional(),
    buriedVias: rt.Boolean.optional(),
    chamfering: rt.Union(rt.Literal('none'), rt.Literal('20-pci'), rt.Literal('45-isa')).optional(),
    zAxisMilling: rt.Boolean.optional(),
    aspectRatio: rt.Number.optional(),
    totalDrillCount: rt.Number.optional(),
    microVias: rt.Boolean.optional(),
    phCount: rt.Number.optional(),
    phToolCount: rt.Number.optional(),
    nphCount: rt.Number.optional(),
    phMinSize: rt.Number.optional(),
    nphMaxSize: rt.Number.optional(),
    phMaxSize: rt.Number.optional(),
    nphToolCount: rt.Number.optional(),
    phAnnularRing: rt.Number.optional(),
    nphMinSize: rt.Number.optional(),
});

export type PCBV2MechanicalProperties = rt.Static<typeof PCBV2MechanicalPropertiesRuntype>;

export const PCBV2PropertiesRuntype = rt.Record({
    board: PCBV2BoardPropertiesRuntype,
    layerStack: PCBV2LayerStackPropertiesRuntype,
    mechanical: PCBV2MechanicalPropertiesRuntype,
});

export type PCBV2Properties = rt.Static<typeof PCBV2PropertiesRuntype>;

export enum SpecificationStatusEnum {
    Changed = 'changed',
    Active = 'active',
    Removed = 'removed',
}

const SpecificationStatusEnumRuntype = runtypeFromEnum(SpecificationStatusEnum);

const ChangedValuesRuntype = rt.Record({
    status: SpecificationStatusEnumRuntype,
});

const SpecificationChangeRuntype = rt.Record({
    previousValue: ChangedValuesRuntype,
    newValue: ChangedValuesRuntype,
});

export const PCBV2SpecificationRuntype = rt.Record({
    id: rt.String,
    name: rt.String,
    previews: PCBPreviewsRuntype,
    settings: PCBV2PropertiesRuntype,
    status: SpecificationStatusEnumRuntype,
    hash: rt.String,
    changes: rt.Array(SpecificationChangeRuntype).optional(),
});

export type PCBV2Specification = rt.Static<typeof PCBV2SpecificationRuntype>;

export const PCBV2Runtype = rt.Record({
    id: rt.String,
    name: rt.String.optional(),
    assembly: rt.String.optional(),
    description: rt.String.optional(),
    created: rt.String,
    files: rt.Array(PCBV2FileRuntype).optional(),
    filesLocked: rt.Boolean,
    lifecycles: rt.Array(FileLifecycleRuntype),
    orderId: rt.String.optional(),
    outline: rt.String.optional(),
    specifications: rt.Array(PCBV2SpecificationRuntype),
    properties: PCBV2PropertiesRuntype,
    customer: rt.String.optional(),
    projectType: rt.Union(rt.Literal('WithFiles'), rt.Literal('NoFiles')),
});

export type PCBV2 = rt.Static<typeof PCBV2Runtype>;

export const NumericSpecificationCapabilityRuntype = rt.Record({
    min: rt.Number.optional(),
    max: rt.Number.optional(),
    allowed: rt.Array(rt.Number).optional(),
    default: rt.Number.optional(),
    disabled: rt.Boolean.optional(),
});

export type NumericSpecificationCapability = rt.Static<typeof NumericSpecificationCapabilityRuntype>;

export const EnumSpecificationCapabilityRuntype = rt.Record({
    allowed: rt.Array(rt.String),
    default: rt.String.optional(),
});

export type EnumSpecificationCapability = rt.Static<typeof EnumSpecificationCapabilityRuntype>;

export const BooleanSpecificationCapabilityRuntype = rt.Record({
    allowed: rt.Array(rt.Boolean),
    default: rt.Boolean,
});

export type BooleanSpecificationCapability = rt.Static<typeof BooleanSpecificationCapabilityRuntype>;

export const PCBV2BoardCapabilitiesRuntype = rt.Record({
    boardHeight: NumericSpecificationCapabilityRuntype,
    boardWidth: NumericSpecificationCapabilityRuntype,
    silkscreenColor: EnumSpecificationCapabilityRuntype,
    silkscreenSide: EnumSpecificationCapabilityRuntype,
    surfaceFinish: EnumSpecificationCapabilityRuntype,
    enigThickness: NumericSpecificationCapabilityRuntype,
    applicationType: EnumSpecificationCapabilityRuntype,
    hardGold: BooleanSpecificationCapabilityRuntype,
    hardGoldArea: NumericSpecificationCapabilityRuntype,
    exposedCopperArea: NumericSpecificationCapabilityRuntype,
    exposedCopperAreaTop: NumericSpecificationCapabilityRuntype,
    exposedCopperAreaBottom: NumericSpecificationCapabilityRuntype,
    traceWidth: NumericSpecificationCapabilityRuntype,
    copperClearance: NumericSpecificationCapabilityRuntype,
    soldermaskColor: EnumSpecificationCapabilityRuntype,
    soldermaskSide: EnumSpecificationCapabilityRuntype,
    soldermaskDam: NumericSpecificationCapabilityRuntype,
    soldermaskClearance: NumericSpecificationCapabilityRuntype,
    ipc600Class: EnumSpecificationCapabilityRuntype,
    eTest: BooleanSpecificationCapabilityRuntype,
    pressFit: BooleanSpecificationCapabilityRuntype,
    impedanceTested: BooleanSpecificationCapabilityRuntype,
    peelableMask: EnumSpecificationCapabilityRuntype,
    captonTape: EnumSpecificationCapabilityRuntype,
    reports: EnumSpecificationCapabilityRuntype,
    itar: BooleanSpecificationCapabilityRuntype,
    carbonPrint: BooleanSpecificationCapabilityRuntype,
    edgeMetalization: BooleanSpecificationCapabilityRuntype,
    marking: EnumSpecificationCapabilityRuntype,
    flammabilityRating: EnumSpecificationCapabilityRuntype,
    ctiClass: EnumSpecificationCapabilityRuntype,
    maxXOutsAllowed: NumericSpecificationCapabilityRuntype,
    halogenFree: BooleanSpecificationCapabilityRuntype,
    placementSide: EnumSpecificationCapabilityRuntype,
});

export type PCBV2BoardCapabilities = rt.Static<typeof PCBV2BoardCapabilitiesRuntype>;

export const PCBV2LayerStackCapabilitiesRuntype = rt.Record({
    layerstackType: EnumSpecificationCapabilityRuntype,
    layerstackThicknessTolerance: NumericSpecificationCapabilityRuntype,
    ulLayerStack: BooleanSpecificationCapabilityRuntype,
    ulMarkingType: EnumSpecificationCapabilityRuntype,
    layercount: NumericSpecificationCapabilityRuntype,
    finalThickness: NumericSpecificationCapabilityRuntype,
    baseMaterial: EnumSpecificationCapabilityRuntype,
    outerCopperThickness: NumericSpecificationCapabilityRuntype,
    innerCopperThickness: NumericSpecificationCapabilityRuntype,
    minOuterLayerStructure: NumericSpecificationCapabilityRuntype,
    minInnerLayerStructure: NumericSpecificationCapabilityRuntype,
    rohsCompilant: BooleanSpecificationCapabilityRuntype,
    tgValue: NumericSpecificationCapabilityRuntype,
});

export type PCBV2LayerStackCapabilities = rt.Static<typeof PCBV2LayerStackCapabilitiesRuntype>;

export const PCBV2MechanicalCapabilitiesRuntype = rt.Record({
    minViaDiameter: NumericSpecificationCapabilityRuntype,
    viaFilling: EnumSpecificationCapabilityRuntype,
    blindVias: BooleanSpecificationCapabilityRuntype,
    buriedVias: BooleanSpecificationCapabilityRuntype,
    chamfering: EnumSpecificationCapabilityRuntype,
    zAxisMilling: BooleanSpecificationCapabilityRuntype,
    aspectRatio: NumericSpecificationCapabilityRuntype,
    totalDrillCount: NumericSpecificationCapabilityRuntype,
    microVias: BooleanSpecificationCapabilityRuntype,
    phCount: NumericSpecificationCapabilityRuntype,
    phToolCount: NumericSpecificationCapabilityRuntype,
    nphCount: NumericSpecificationCapabilityRuntype,
    phMinSize: NumericSpecificationCapabilityRuntype,
    nphMaxSize: NumericSpecificationCapabilityRuntype,
    phMaxSize: NumericSpecificationCapabilityRuntype,
    nphToolCount: NumericSpecificationCapabilityRuntype,
    phAnnularRing: NumericSpecificationCapabilityRuntype,
    nphMinSize: NumericSpecificationCapabilityRuntype,
});

export type PCBV2MechanicalCapabilities = rt.Static<typeof PCBV2MechanicalCapabilitiesRuntype>;

export const PCBV2SpecificationCapabilitiesRuntype = rt.Record({
    board: PCBV2BoardCapabilitiesRuntype,
    layerStack: PCBV2LayerStackCapabilitiesRuntype,
    mechanical: PCBV2MechanicalCapabilitiesRuntype,
});

export interface PCBV2SpecificationCapabilities extends rt.Static<typeof PCBV2SpecificationCapabilitiesRuntype> {}

export const PCBV2UpdateRuntype = rt.Record({
    name: rt.String.optional(),
    settings: PCBV2PropertiesRuntype.optional(),
});

export type PCBV2Update = rt.Static<typeof PCBV2UpdateRuntype>;

export const ARuntype = rt.Record({});

export type A = rt.Static<typeof ARuntype>;

export const FileUploadRuntype = rt.Record({});

export type FileUpload = rt.Static<typeof FileUploadRuntype>;

export const OfferPriceAvailabilityErrorRuntype = rt.Record({
    name: rt.String,
    error: rt.String,
    label: rt.String,
});

export type OfferPriceAvailabilityError = rt.Static<typeof OfferPriceAvailabilityErrorRuntype>;

export const OfferPriceAvailabilityDataRuntype = rt.Record({
    message: rt.String.optional(),
    errors: rt.Array(OfferPriceAvailabilityErrorRuntype).optional(),
});

const PCBManufacturerRuntype = rt.Record({
    id: rt.String,
    name: rt.String,
    approved: rt.Boolean,
    preferred: rt.Boolean,
    region: rt.String,
});

export type PCBManufacturer = rt.Static<typeof PCBManufacturerRuntype>;

const ValidationStatusRuntype = rt.Union(rt.Literal('success'), rt.Literal('error'));
const ValidationErrorsRuntype = rt.Array(OfferPriceAvailabilityErrorRuntype);

export const OfferPriceAvailabilityResponseRuntype = rt.Record({
    manufacturer: PCBManufacturerRuntype,
    status: ValidationStatusRuntype,
    errors: ValidationErrorsRuntype,
});

export type OfferPriceAvailabilityResponse = rt.Static<typeof OfferPriceAvailabilityResponseRuntype>;

export enum ValidationResultType {
    Detailed = 'DetailedValidationResultResponse',
    Simple = 'SimpleValidationResultResponse',
}

const DetailedValidationResultRuntype = rt.Record({
    results: rt.Array(OfferPriceAvailabilityResponseRuntype),
    type: rt.Literal(ValidationResultType.Detailed),
});

export type DetailedValidationResult = rt.Static<typeof DetailedValidationResultRuntype>;

const SimpleValidationResultRuntype = rt.Record({
    status: ValidationStatusRuntype,
    errors: ValidationErrorsRuntype,
    type: rt.Literal(ValidationResultType.Simple),
});

export type SimpleValidationResult = rt.Static<typeof SimpleValidationResultRuntype>;

export const OfferPriceAvailabilityRuntype = rt.Union(DetailedValidationResultRuntype, SimpleValidationResultRuntype);

export type OfferPriceAvailability = rt.Static<typeof OfferPriceAvailabilityRuntype>;

export const OfferPriceAvailabilityRequestRuntype = rt.Record({
    // eslint-disable-next-line camelcase
    pcb_id: rt.String,
    quantity: rt.Number,
    rfq_id: rt.String,
});

export type OfferPriceAvailabilityRequest = rt.Static<typeof OfferPriceAvailabilityRequestRuntype>;

const SingleFileTypeUpdateRuntype = rt.Record({
    file: rt.String,
    function: FileTypeRuntype.optional(),
    detectedTypes: rt.Array(FileTypeRuntype).optional(),
});

export type SingleFileTypeUpdate = rt.Static<typeof SingleFileTypeUpdateRuntype>;

export const FileTypeUpdateRequestRuntype = rt.Record({
    updates: rt.Array(SingleFileTypeUpdateRuntype),
});

export type FileTypeUpdateRequest = rt.Static<typeof FileTypeUpdateRequestRuntype>;

const SingleFileUploadResultRuntype = rt.Record({
    name: rt.String,
    fType: FileTypeRuntype,
});

export const UploadFileResponseRuntype = rt.Record({
    files: rt.Array(SingleFileUploadResultRuntype),
    warnings: rt.Array(rt.String).optional(),
});

export type UploadFileResponse = rt.Static<typeof UploadFileResponseRuntype>;

export enum PcbServerErrorType {
    TechnicalError = 'TechnicalError',
    PropertyErrors = 'PropertyErrors',
    MissingLayerstack = 'MissingLayerstack',
    MissingOutline = 'MissingOutline',
}

export const PcbServerErrorTypeRuntype = runtypeFromEnum(PcbServerErrorType);

const PcbTechnicalErrorRuntype = rt.Record({
    type: rt.Literal(PcbServerErrorType.TechnicalError),
    message: rt.String,
});

const PcbMissingLayerstackErrorRuntype = rt.Record({
    type: rt.Literal(PcbServerErrorType.MissingLayerstack),
});

const PcbMissingOutlineErrorRuntype = rt.Record({
    type: rt.Literal(PcbServerErrorType.MissingOutline),
});

const PcbPropertyErrorsRuntype = rt.Record({
    type: rt.Literal(PcbServerErrorType.PropertyErrors),
    errors: rt.Array(rt.String),
});

export const PcbErrorRuntype = rt.Record({
    status: rt.Literal('error'),
    error: rt.Union(
        PcbTechnicalErrorRuntype,
        PcbMissingLayerstackErrorRuntype,
        PcbMissingOutlineErrorRuntype,
        PcbPropertyErrorsRuntype,
    ),
});

export type PcbError = rt.Static<typeof PcbErrorRuntype>;

export const PCBV2SpecificationUpdateRequest = rt.Record({
    status: SpecificationStatusEnumRuntype,
});

export const PcbApprovalStateRuntype = rt.Record({
    pcb: rt.String,
    is_pcb_specification_approved: rt.Boolean,
    is_pcb_stackup_approved: rt.Boolean,
    is_pcb_drill_manager_approved: rt.Boolean,
    is_pcb_file_manager_approved: rt.Boolean,
});

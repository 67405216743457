/* eslint-disable camelcase */
import { DB, from } from '@luminovo/http-client';
import { QueryResult, col } from '@synthql/queries';

export function negotiation() {
    const user = from('app_user')
        .columns('id', 'first_name', 'last_name', 'email')
        .where({ id: col('negotiation.created_by') })
        .first();

    const lineItems = from('negotiation_line_item')
        .columns('id', 'quantity')
        .where({ negotiation_id: col('negotiation.id') })
        .all();

    return (
        from('negotiation')
            .columns('id', 'name', 'created_at')
            //
            .include({
                user,
                lineItems,
            })
    );
}

const oneNegotiationQuery = negotiation().firstOrThrow();
export type Negotiation = QueryResult<DB, typeof oneNegotiationQuery>;

export type NegotiationPart =
    | NegotiationLineItem['part_option_component'][0]
    | NegotiationLineItem['part_option_component_custom'][0]
    | NegotiationLineItem['part_option_custom'][0]
    | NegotiationLineItem['part_option_generic'][0]
    | NegotiationLineItem['part_option_off_the_shelf'][0];

export type NegotiationLineItem = QueryResult<DB, ReturnType<typeof findNegotiationLineItems>>[0];
export type AwardedPartOts = NegotiationLineItem['awarded_part_off_the_shelf'][0];
export type AwardedPartCustom = NegotiationLineItem['awarded_part_custom'][0];
export type AwardedPart = AwardedPartOts | AwardedPartCustom;

export type AwardScenario = {
    id: number;
    name: string;
    awardedOffers: { id: number }[];
};

export function findNegotiationLineItems({ negotiationId }: { negotiationId: number }) {
    const part_option_off_the_shelf = from('negotiation_part_option_off_the_shelf')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const part_option_generic = from('negotiation_part_option_generic')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const part_option_custom = from('negotiation_part_option_custom')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const part_option_component = from('negotiation_part_option_component')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const part_option_component_custom = from('negotiation_part_option_custom_component')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const awarded_offer_off_the_shelf = from('awarded_offer_off_the_shelf')
        .columns('available_prices', 'award_scenario_id', 'id', 'awarded_supplier_name', 'awarded_supplier_id')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const awarded_offer_off_the_shelf_public = from('awarded_offer_off_the_shelf_public')
        .columns('available_prices', 'award_scenario_id', 'id', 'awarded_supplier_name', 'awarded_supplier_id')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const awarded_offer_custom = from('awarded_offer_custom')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const awarded_part_off_the_shelf = from('awarded_part_off_the_shelf')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const awarded_part_custom = from('awarded_part_custom')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    const demands = from('negotiation_line_item_demand')
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .include({
            demand: demand()
                .where({
                    id: col('negotiation_line_item_demand.demand_id'),
                })
                .first(),
        })
        .all();

    const quoteRequestLineItems = quoteRequestLineItem()
        .where({ negotiation_line_item_id: col('negotiation_line_item.id') })
        .all();

    return from('negotiation_line_item')
        .columns('id', 'quantity', 'negotiation_id')
        .include({
            part_option_off_the_shelf,
            part_option_generic,
            part_option_custom,
            part_option_component,
            part_option_component_custom,
            awarded_offer_off_the_shelf,
            awarded_offer_off_the_shelf_public,
            awarded_offer_custom,
            awarded_part_off_the_shelf,
            awarded_part_custom,
            demands,
            quoteRequestLineItems,
        })
        .where({ negotiation_id: negotiationId })
        .all();
}

export function demand() {
    const customer = from('customer')
        .columns('id', 'name')
        .where({ id: col('demand.end_customer') })
        .first();

    const site = from('site')
        .columns('id', 'name')
        .where({ id: col('demand.recipient_internal') })
        .first();

    const supplier = from('public.supplier')
        .columns('id', 'name')
        .where({ id: col('demand.supplier_external') })
        .first();

    return from('demand').include({
        customer,
        site,
        supplier,
    });
}

const oneDemandQuery = demand().firstOrThrow();
export type Demand = QueryResult<DB, typeof oneDemandQuery>;

export function quoteRequest() {
    const lineItems = from('quote_request_line_item')
        .columns('id')
        .where({ quote_request_id: col('quote_request.id') })
        .all();

    const supplier = from('public.supplier')
        .columns('id', 'name')
        .where({ id: col('quote_request.supplier_id') })
        .maybe();

    const sender = from('app_user')
        .columns('id', 'first_name', 'last_name', 'email')
        .where({ id: col('quote_request.sent_by') })
        .maybe();

    return from('quote_request')
        .columns('id', 'created_at', 'discarded', 'due_date', 'received_date', 'sent_date')
        .include({
            lineItems,
            supplier,
            sender,
        });
}
const oneQuoteRequestQuery = quoteRequest().firstOrThrow();
export type QuoteRequest = QueryResult<DB, typeof oneQuoteRequestQuery>;

export function quoteRequestLineItem() {
    const supplier = from('public.supplier')
        .columns('id', 'name')
        .where({ id: col('quote_request.supplier_id') })
        .first();

    const quoteRequest = from('quote_request')
        .where({ id: col('quote_request_line_item.quote_request_id') })
        .include({ supplier })
        .first();

    return from('quote_request_line_item').include({
        quoteRequest,
    });
}
const oneQuoteRequestLineItemQuery = quoteRequestLineItem().firstOrThrow();
export type QuoteRequestLineItem = QueryResult<DB, typeof oneQuoteRequestLineItemQuery>;

/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { CountryAlpha2Code, VATIdentificationTypeRuntype, allCountryCodes } from '@luminovo/http-client';
import { UniversalImporter, onImportUsingIndividualMutations } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { VATIdentificationTypeTranslations } from '../../resources/customer/i18n/vatIdentificationType';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

export function CustomersImporterPage() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { data: customers = [] } = useHttpQuery(
        'GET /customers',
        {},
        {
            select: (res) => res.data,
            suspense: true,
        },
    );

    const customerNumbers: Array<{ id: string }> = customers.flatMap((c) =>
        c.customer_number ? [{ id: c.customer_number }] : [],
    );

    const { mutateAsync: createCustomer } = useHttpMutation('POST /customers', {
        snackbarMessage: null,
    });

    const { mutateAsync: updateCustomer } = useHttpMutation('PATCH /customers/:customerId', {
        snackbarMessage: null,
    });

    const { data: users = [] } = useHttpQuery(
        'GET /users/organization',
        {},
        {
            select: (res) => res.data,
            suspense: true,
        },
    );

    return (
        <UniversalImporter
            title={t`Import customers`}
            batchSize={2}
            onImportDone={() => {
                enqueueSnackbar(t`Customers imported successfully`, { variant: 'success' });
                history.push(route('/customers'));
            }}
            onImportBatch={onImportUsingIndividualMutations({
                formatError: formatError,
                insertRecord: async (record) => {
                    return createCustomer({
                        requestBody: {
                            name: record.data.name,
                            customer_number: record.data.customer_number,
                            billing_address: {
                                city: record.data.city,
                                postal_code: record.data.postal_code,
                                country_code: record.data.country_code,
                                line1: record.data.line1,
                                line2: record.data.line2,
                            },
                            customer_type: {
                                type: 'Business',
                                data: {
                                    vat_id:
                                        record.data.vat_identification_number === null &&
                                        record.data.vat_identification_type === null
                                            ? null
                                            : {
                                                  id: record.data.vat_identification_number,
                                                  id_type: record.data.vat_identification_type,
                                              },
                                    commercial_register_number: record.data.commercial_register_number,
                                },
                            },
                            language: record.data.language,
                            default_contact_person: record.data.default_contact_person,
                        },
                    });
                },
                updateRecord: async (record) => {
                    const customer = customers.find((c) => c.customer_number === record.data.customer_number);
                    if (!customer) {
                        throw new Error(`Customer not found`);
                    }
                    return updateCustomer({
                        pathParams: { customerId: customer.id },
                        requestBody: {
                            name: record.data.name,
                            customer_number: record.data.customer_number,
                            billing_address: {
                                city: record.data.city,
                                postal_code: record.data.postal_code,
                                country_code: record.data.country_code,
                                line1: record.data.line1,
                                line2: record.data.line2,
                            },
                            customer_type: {
                                type: 'Business',
                                data: {
                                    vat_id: {
                                        id: record.data.vat_identification_number,
                                        id_type: record.data.vat_identification_type,
                                    },
                                    commercial_register_number: record.data.commercial_register_number,
                                },
                            },
                            language: record.data.language,
                            default_contact_person: record.data.default_contact_person,
                        },
                    });
                },
            })}
            config={{
                fields: [
                    {
                        id: 'name' as const,
                        columnIndices: [],
                        parser: { type: 'string', options: { trim: true } },
                        required: true,
                        label: t`Name`,
                        description: t`The name of the customer`,
                    },
                    {
                        id: 'customer_number' as const,
                        label: t`Customer number`,
                        unique: true,
                        description: t`The unique identifier of the customer`,
                        columnIndices: [],
                        parser: {
                            type: 'identifier',
                            options: { ids: customerNumbers },
                        },
                        required: true,
                    },
                    {
                        id: 'language' as const,
                        label: t`Language`,
                        description: t`The language of the customer`,
                        columnIndices: [],
                        parser: {
                            type: 'language',
                            options: {},
                        },
                        required: false,
                        defaultValue: { id: 'en', label: t`English` },
                    },
                    {
                        id: 'vat_identification_type' as const,
                        label: t`VAT identification type`,
                        description: t`The type of the VAT identification number`,
                        columnIndices: [],
                        parser: {
                            type: 'enum',
                            options: {
                                caseSensitive: false,
                                cases: VATIdentificationTypeRuntype.alternatives.map((vatId) => {
                                    const value = vatId.value;
                                    return {
                                        value: {
                                            id: value,
                                            label: transEnum(value, VATIdentificationTypeTranslations),
                                        },
                                        matches: [value],
                                    };
                                }),
                            },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'vat_identification_number' as const,
                        label: t`VAT identification number`,
                        description: t`The VAT identification number of the customer`,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'commercial_register_number' as const,
                        label: t`Commercial register number`,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'city' as const,
                        label: t`City`,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'postal_code' as const,
                        label: t`Postal code`,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'country_code' as const,
                        label: t`Country code`,
                        columnIndices: [],
                        parser: {
                            type: 'enum',
                            options: {
                                caseSensitive: false,
                                cases: allCountryCodes.map(
                                    (
                                        cc,
                                    ): {
                                        value: { id: CountryAlpha2Code; label: string };
                                        matches: string[];
                                    } => {
                                        return {
                                            value: { id: cc, label: cc },
                                            matches: [cc],
                                        };
                                    },
                                ),
                            },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'line1' as const,
                        label: t`Address line 1`,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'line2' as const,
                        label: t`Address line 2`,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true },
                        },
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                    },
                    {
                        id: 'default_contact_person' as const,
                        label: t`Default contact person`,
                        columnIndices: [],
                        parser: {
                            type: 'enum',
                            options: {
                                caseSensitive: false,
                                cases: users.map((user) => {
                                    return {
                                        value: { id: user.id, label: `${user.first_name} ${user.last_name}` },
                                        matches: [`${user.first_name} ${user.last_name}`, user.email],
                                    };
                                }),
                            },
                        },
                        required: true,
                        defaultValue: { id: null, label: '' },
                    },
                ],
            }}
        />
    );
}

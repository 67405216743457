import { formatDecimal } from '@luminovo/commons';
import { PrimaryButton } from '@luminovo/design-system';
import { LayoutCard } from '../../../components/LayoutCard';
import { UrlParams } from '../../../utils/routes/routes';
import { NegotiationsLayout } from '../components/NegotiationsLayout';
import { PropertiesTable } from '../components/PropertiesTable';
import { TableQuoteRequestLineItems } from '../components/TableQuoteRequestLineItems';
import { useQuoteRequest } from '../hooks/useQuoteRequest';
import { formatQuoteRequest } from '../model/formatQuoteRequest';

export function QuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = Number(pathParams.quoteRequestId);
    const { data: quoteRequest } = useQuoteRequest({ quoteRequestId });

    if (!quoteRequest) {
        return <></>;
    }

    return (
        <NegotiationsLayout
            negotiationId={negotiationId}
            quoteRequestId={quoteRequestId}
            title={formatQuoteRequest(quoteRequest)}
            actions={
                <>
                    <PrimaryButton size="medium">Send request</PrimaryButton>
                </>
            }
        >
            <LayoutCard>
                <PropertiesTable
                    properties={{
                        [`Line items`]: formatDecimal(quoteRequest.lineItems.length),
                        [`Reference volume`]: '0',
                        [`Target volume`]: '0',
                        [`Quoted volume`]: '0',
                    }}
                />
            </LayoutCard>

            <LayoutCard>
                <TableQuoteRequestLineItems lineItems={[]} />
            </LayoutCard>
        </NegotiationsLayout>
    );
}

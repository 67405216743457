import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';

export const negotiationEndpoints = {
    'POST /quote-request/draft': endpoint({
        description: 'Creates a quote request draft',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            negotiation_line_item_ids: r.Array(r.Number),
            supplier_ids: r.Array(r.String),
        }),
        responseBody: r.Unknown,
    }),

    'POST /negotiation': endpoint({
        description: 'Creates a negotiation',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            name: r.String,
            demand_ids: r.Array(r.String),
            filter_by_categories: r.Array(
                r.Union(
                    r.Record({ type: r.Literal('OtsCategory'), id: r.Number }),
                    r.Record({ type: r.Literal('CustomPartType'), data: r.String }),
                ),
            ),
            filter_manufacturers: r.Array(r.String),
            filter_not_quoted_since: r.String.nullable(),
            group_by_customer: r.Boolean,
            group_by_site_groups: r.Array(r.Array(r.String)),
            group_by_supplier_groups: r.Array(r.Array(r.String)),
            group_by_sourcing_scenario: r.Boolean,
        }),
        responseBody: r.Record({
            id: r.Number,
        }),
    }),

    'DELETE /negotiation/:id': endpoint({
        description: 'Deletes a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),
};

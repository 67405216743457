import { NegotiationPart } from '../hooks/queries';

export function formatNegotiationPart(part: NegotiationPart): string {
    if ('manufacturer_name' in part) {
        return `${part.manufacturer_name}, ${part.mpn}`;
    }
    if ('ipn_id' in part) {
        return part.ipn_id;
    }
    if ('part_type' in part) {
        return `${part.part_type} ${part.description}`;
    }
    if ('matches' in part) {
        return part.id;
    }
    return JSON.stringify(part.content);
}

// @ts-nocheck
// This file is generated by introspect. Do not edit it manually.
import { Schema } from '@synthql/queries';
import { DB } from './db';
export const schema: Schema<DB> = {
    $schema: 'https://json-schema.org/draft/2020-12/schema',
    type: 'object',
    description: "Your database's schema",
    properties: {
        'public.manufacturer': {
            type: 'object',
            description: 'Table details:\n\n- Schema: public\n- Table: manufacturer',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: billing_address\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        website_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: website_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        phone_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: phone_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        has_online_shopping: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: has_online_shopping\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        alternative_names: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: alternative_names\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        updated_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: updated_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'billing_address',
                        'website_url',
                        'phone_number',
                        'has_online_shopping',
                        'alternative_names',
                        'created_at',
                        'updated_at',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        'public.supplier': {
            type: 'object',
            description: 'Table details:\n\n- Schema: public\n- Table: supplier',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        alternative_names: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: alternative_names\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        tenant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: tenant\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_part_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: supplier_part_type\n- PG type: types.supplier_part_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.supplier_part_type_enum.enum',
                                    type: 'string',
                                    enum: ['OffTheShelf', 'Pcb', 'Custom', 'Assembly'],
                                    description: 'The supplier_part_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        is_quote_partner: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: is_quote_partner\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: supplier_type\n- PG type: types.supplier_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.supplier_type_enum.enum',
                                    type: 'string',
                                    enum: ['Distributor', 'Manufacturer', 'Ems'],
                                    description: 'The supplier_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: origin\n- PG type: types.supplier_origin_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.supplier_origin_enum.enum',
                                    type: 'string',
                                    enum: ['System', 'Erp', 'Manual'],
                                    description: 'The supplier_origin_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        updated_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: updated_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        default_currency: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: default_currency\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'alternative_names',
                        'tenant',
                        'supplier_part_type',
                        'is_quote_partner',
                        'supplier_type',
                        'origin',
                        'created_at',
                        'updated_at',
                        'default_currency',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        app_user: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: app_user',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        first_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: first_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        last_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: last_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        email: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: email\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        external_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: external_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        phone_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: phone_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        timezone: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: timezone\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        user_language: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: user_language\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        position: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: position\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        photo: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: photo\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        updated_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: updated_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        invited_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: invited_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        invited_by_entity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: invited_by_entity\n- PG type: types.invited_by_entity_enum\n- PG kind: enum\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.invited_by_entity_enum.enum',
                                    type: 'string',
                                    enum: ['customer', 'organization'],
                                    description: 'The invited_by_entity_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'first_name',
                        'last_name',
                        'email',
                        'external_id',
                        'phone_number',
                        'timezone',
                        'user_language',
                        'position',
                        'photo',
                        'customer',
                        'created_at',
                        'updated_at',
                        'invited_by',
                        'invited_by_entity',
                        'supplier_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        rfq: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: rfq',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: creation_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        due_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: due_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        status: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: status\n- PG type: types.rfq_status_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.rfq_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'RequestInDraft',
                                        'QuotationInProgress',
                                        'QuotationAvailable',
                                        'OrderPlaced',
                                        'OrderInProduction',
                                        'OrderShipped',
                                        'OrderConfirmed',
                                        'OrderInProcurement',
                                        'PaymentFailed',
                                        'NoOrder',
                                        'NoQuotation',
                                    ],
                                    description: 'The rfq_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ems_internal_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: ems_internal_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        volume_estimate: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: volume_estimate\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: created_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        currency: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: currency\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        is_archived: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: is_archived\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        workflow_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: workflow_type\n- PG type: types.workflow_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.workflow_type_enum.enum',
                                    type: 'string',
                                    enum: ['Automatic', 'Manual', 'DontShow'],
                                    description: 'The workflow_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        shipping_tracking_link: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: shipping_tracking_link\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_by_m2m: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: created_by_m2m\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'creation_date',
                        'due_date',
                        'status',
                        'ems_internal_number',
                        'volume_estimate',
                        'created_by',
                        'currency',
                        'is_archived',
                        'customer',
                        'name',
                        'workflow_type',
                        'shipping_tracking_link',
                        'created_by_m2m',
                        'notes',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        customer: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: customer',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: billing_address\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        default_contact_person: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: default_contact_person\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: customer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        language: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: language\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        vat_identification_number_backup: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: vat_identification_number_backup\n- PG type: pg_catalog.varchar\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.varchar',
                                    type: 'string',
                                    description: 'A PG varchar',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        commercial_register_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: commercial_register_number\n- PG type: pg_catalog.varchar\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.varchar',
                                    type: 'string',
                                    description: 'A PG varchar',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address_backup: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: billing_address_backup\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        vat_identification_type_backup: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: vat_identification_type_backup\n- PG type: pg_catalog.varchar\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.varchar',
                                    type: 'string',
                                    description: 'A PG varchar',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        vat_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: vat_id\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        is_private: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: is_private\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'billing_address',
                        'default_contact_person',
                        'customer_number',
                        'language',
                        'vat_identification_number_backup',
                        'commercial_register_number',
                        'billing_address_backup',
                        'vat_identification_type_backup',
                        'vat_id',
                        'is_private',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        award_scenario: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: award_scenario',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: BY DEFAULT',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: negotiation_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'negotiation_id', 'name', 'created_at'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        organization: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: organization',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: billing_address\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        client_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: client_type\n- PG type: types.customer_type_enum\n- PG kind: enum\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.customer_type_enum.enum',
                                    type: 'string',
                                    enum: ['customer', 'test', 'trial', 'inactive'],
                                    description: 'The customer_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        one_row: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: one_row\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        has_access_to_customer_portal: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: has_access_to_customer_portal\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer_success_manager_email: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: customer_success_manager_email\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        has_customers: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: has_customers\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part_emission_request_quota: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: part_emission_request_quota\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'billing_address',
                        'client_type',
                        'one_row',
                        'has_access_to_customer_portal',
                        'customer_success_manager_email',
                        'has_customers',
                        'part_emission_request_quota',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        awarded_offer: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        private_off_the_shelf_offer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: private_off_the_shelf_offer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        public_off_the_shelf_offer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: public_off_the_shelf_offer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_offer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: custom_offer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                        'private_off_the_shelf_offer_id',
                        'public_off_the_shelf_offer_id',
                        'custom_offer_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        quote_request: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: quote_request',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rfq_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: rfq_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: negotiation_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        due_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: due_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        discarded: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: discarded\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sent_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: sent_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sent_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: sent_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: received_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        configuration_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: configuration_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'rfq_id',
                        'negotiation_id',
                        'supplier_id',
                        'created_at',
                        'due_date',
                        'discarded',
                        'sent_date',
                        'sent_by',
                        'received_date',
                        'configuration_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        quote_request_line_item: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: quote_request_line_item',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: ALWAYS',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        quote_request_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: quote_request_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        off_the_shelf_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: off_the_shelf_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        generic_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: generic_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: custom_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_component_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: custom_component_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        required_quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: required_quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        potential_quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: potential_quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        target_price: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: target_price\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'MonetaryValue',
                                    description: 'A monetary value',
                                    type: 'object',
                                    properties: {
                                        amount: {
                                            type: 'string',
                                        },
                                        currency: {
                                            type: 'string',
                                        },
                                    },
                                    required: ['amount', 'currency'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_private_off_the_shelf_offer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: received_private_off_the_shelf_offer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_public_off_the_shelf_offer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: received_public_off_the_shelf_offer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_custom_part_offer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: received_custom_part_offer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'quote_request_id',
                        'negotiation_line_item_id',
                        'off_the_shelf_part_id',
                        'generic_part_id',
                        'ipn_id',
                        'custom_part_id',
                        'custom_component_id',
                        'required_quantity',
                        'potential_quantity',
                        'target_price',
                        'received_private_off_the_shelf_offer',
                        'received_public_off_the_shelf_offer',
                        'received_custom_part_offer',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        site: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: site',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: address\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        site_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: site_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        tags: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: tags\n- PG type: types.site_tag_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.site_tag_enum.enum',
                                    type: 'string',
                                    enum: ['Inventory', 'Manufacturing', 'Sourcing'],
                                    description: 'The site_tag_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'name', 'address', 'notes', 'site_number', 'tags'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: ALWAYS',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: created_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'name', 'created_at', 'created_by'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_line_item: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_line_item',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item\n- Column: negotiation_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item\n- Column: quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'negotiation_id', 'quantity'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_line_item_demand: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_demand',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_demand\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        demand_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_demand\n- Column: demand_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['negotiation_line_item_id', 'demand_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_line_item_part_option: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: ALWAYS',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        off_the_shelf_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: off_the_shelf_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        generic_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: generic_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: custom_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_component_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: custom_component_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'negotiation_line_item_id',
                        'off_the_shelf_part_id',
                        'generic_part_id',
                        'ipn_id',
                        'custom_part_id',
                        'custom_component_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        demand: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: demand',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        demand_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: demand_type\n- PG type: types.demand_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.demand_type_enum.enum',
                                    type: 'string',
                                    enum: ['gross', 'net'],
                                    description: 'The demand_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ots_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: ots_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        component_ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: component_ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        assembly_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: assembly_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        production_start_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: production_start_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        delivery_start_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: delivery_start_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_external: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: supplier_external\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_internal: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: supplier_internal\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        recipient_external: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: recipient_external\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        recipient_internal: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: recipient_internal\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        delivery_end_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: delivery_end_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        context: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: context\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        end_customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: end_customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sourcing_scenario_origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: sourcing_scenario_origin\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'demand_type',
                        'ots_part_id',
                        'component_ipn_id',
                        'assembly_id',
                        'production_start_date',
                        'delivery_start_date',
                        'quantity',
                        'supplier_external',
                        'supplier_internal',
                        'recipient_external',
                        'recipient_internal',
                        'created_at',
                        'delivery_end_date',
                        'context',
                        'end_customer',
                        'sourcing_scenario_origin',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_part_option_off_the_shelf: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeOts',
                                    type: 'string',
                                    const: 'off_the_shelf',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        mpn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: mpn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: manufacturer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: manufacturer_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['kind', 'id', 'mpn', 'manufacturer_id', 'manufacturer_name', 'negotiation_line_item_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_part_option_generic: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeGeneric',
                                    type: 'string',
                                    const: 'generic',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        content: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: content\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        package_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: package_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['kind', 'id', 'content', 'package_id', 'created_at', 'negotiation_line_item_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_part_option_component: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeComponent',
                                    type: 'string',
                                    const: 'component',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        off_the_shelf_part_matches: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: off_the_shelf_part_matches\n- PG type: luminovo.negotiation_part_off_the_shelf\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        generic_part_matches: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: generic_part_matches\n- PG type: luminovo.negotiation_part_option_generic\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        raw_specifications: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: raw_specifications\n- PG type: luminovo.internal_part_number_raw_specification\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'ipn_id',
                        'off_the_shelf_part_matches',
                        'generic_part_matches',
                        'raw_specifications',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_part_option_custom: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeCustom',
                                    type: 'string',
                                    const: 'custom',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: part_type\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        description: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: description\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        reach_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: reach_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rohs_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: rohs_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'part_type',
                        'description',
                        'reach_compliant',
                        'rohs_compliant',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        negotiation_part_option_custom_component: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeComponentCustom',
                                    type: 'string',
                                    const: 'custom_component',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        description: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: description\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        matches: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: matches\n- PG type: luminovo.negotiation_part_option_custom\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['kind', 'id', 'description', 'matches', 'negotiation_line_item_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        awarded_offer_off_the_shelf: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_part_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: supplier_part_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: part\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: origin\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: creation_date\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        valid_until: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: valid_until\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: offer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        available_prices: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: available_prices\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        price_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: price_type\n- PG type: types.price_type_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.price_type_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'list_price',
                                        'contract_price',
                                        'quote_price',
                                        'purchase_price',
                                        'customer_negotiated_price',
                                        'standard_price',
                                    ],
                                    description: 'The price_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        unit_of_measurement: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: unit_of_measurement\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: offer_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        attachment: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: attachment\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_and_stock_location: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: supplier_and_stock_location\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        packaging: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: packaging\n- PG type: types.packaging_v2_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.packaging_v2_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'ammo_pack',
                                        'bag',
                                        'bulk',
                                        'reel',
                                        're_reel',
                                        'tape',
                                        'tray',
                                        'tube',
                                        'carton',
                                    ],
                                    description: 'The packaging_v2_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rfq: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: rfq\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: ipn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        inventory_site: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: inventory_site\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        inventory_unique_key: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: inventory_unique_key\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ncnr: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: ncnr\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: awarded_supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: awarded_supplier_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'supplier_part_number',
                        'part',
                        'origin',
                        'notes',
                        'creation_date',
                        'valid_until',
                        'offer_number',
                        'available_prices',
                        'price_type',
                        'unit_of_measurement',
                        'offer_url',
                        'attachment',
                        'supplier_and_stock_location',
                        'packaging',
                        'rfq',
                        'ipn',
                        'inventory_site',
                        'customer',
                        'inventory_unique_key',
                        'ncnr',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                        'awarded_supplier_id',
                        'awarded_supplier_name',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        awarded_offer_off_the_shelf_public: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_part_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: supplier_part_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: part\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: origin\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: creation_date\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        valid_until: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: valid_until\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: offer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        available_prices: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: available_prices\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        unit_of_measurement: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: unit_of_measurement\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: offer_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        attachment: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: attachment\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_and_stock_location: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: supplier_and_stock_location\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        packaging: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: packaging\n- PG type: types.packaging_v2_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.packaging_v2_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'ammo_pack',
                                        'bag',
                                        'bulk',
                                        'reel',
                                        're_reel',
                                        'tape',
                                        'tray',
                                        'tube',
                                        'carton',
                                    ],
                                    description: 'The packaging_v2_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ncnr: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: ncnr\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: awarded_supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: awarded_supplier_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'supplier_part_number',
                        'part',
                        'origin',
                        'notes',
                        'creation_date',
                        'valid_until',
                        'offer_number',
                        'available_prices',
                        'unit_of_measurement',
                        'offer_url',
                        'attachment',
                        'supplier_and_stock_location',
                        'packaging',
                        'ncnr',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                        'awarded_supplier_id',
                        'awarded_supplier_name',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        awarded_offer_custom: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: creation_date\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        valid_until: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: valid_until\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: offer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        price_points: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: price_points\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        one_time_costs: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: one_time_costs\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_part: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: custom_part\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        unit_of_measurement: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: unit_of_measurement\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_and_stock_location: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: supplier_and_stock_location\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_validity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: offer_validity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: offer_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        price_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: price_type\n- PG type: types.custom_price_type_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.custom_price_type_enum.enum',
                                    type: 'string',
                                    enum: ['list_price', 'quote_price', 'customer_negotiated_price', 'contract_price'],
                                    description: 'The custom_price_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sourcing_scenario: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: sourcing_scenario\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: origin\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: ipn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        specification: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: specification\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'notes',
                        'creation_date',
                        'valid_until',
                        'offer_number',
                        'price_points',
                        'one_time_costs',
                        'custom_part',
                        'unit_of_measurement',
                        'supplier_and_stock_location',
                        'offer_validity',
                        'offer_url',
                        'price_type',
                        'sourcing_scenario',
                        'origin',
                        'ipn',
                        'specification',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        awarded_part_custom: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_part_custom',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeCustom',
                                    type: 'string',
                                    const: 'custom',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: part_type\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        description: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: description\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        reach_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: reach_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rohs_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: rohs_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'part_type',
                        'description',
                        'reach_compliant',
                        'rohs_compliant',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        awarded_part_off_the_shelf: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeOts',
                                    type: 'string',
                                    const: 'off_the_shelf',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        mpn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: mpn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: manufacturer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: manufacturer_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'mpn',
                        'manufacturer_id',
                        'manufacturer_name',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
    },
    required: [
        'public.manufacturer',
        'public.supplier',
        'app_user',
        'rfq',
        'customer',
        'award_scenario',
        'organization',
        'awarded_offer',
        'quote_request',
        'quote_request_line_item',
        'site',
        'negotiation',
        'negotiation_line_item',
        'negotiation_line_item_demand',
        'negotiation_line_item_part_option',
        'demand',
        'negotiation_part_option_off_the_shelf',
        'negotiation_part_option_generic',
        'negotiation_part_option_component',
        'negotiation_part_option_custom',
        'negotiation_part_option_custom_component',
        'awarded_offer_off_the_shelf',
        'awarded_offer_off_the_shelf_public',
        'awarded_offer_custom',
        'awarded_part_custom',
        'awarded_part_off_the_shelf',
    ],
    additionalProperties: false,
    $defs: {
        table_public_manufacturer: {
            type: 'object',
            description: 'Table details:\n\n- Schema: public\n- Table: manufacturer',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: billing_address\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        website_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: website_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        phone_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: phone_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        has_online_shopping: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: has_online_shopping\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        alternative_names: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: alternative_names\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        updated_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: manufacturer\n- Column: updated_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'billing_address',
                        'website_url',
                        'phone_number',
                        'has_online_shopping',
                        'alternative_names',
                        'created_at',
                        'updated_at',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_public_supplier: {
            type: 'object',
            description: 'Table details:\n\n- Schema: public\n- Table: supplier',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        alternative_names: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: alternative_names\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        tenant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: tenant\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_part_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: supplier_part_type\n- PG type: types.supplier_part_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.supplier_part_type_enum.enum',
                                    type: 'string',
                                    enum: ['OffTheShelf', 'Pcb', 'Custom', 'Assembly'],
                                    description: 'The supplier_part_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        is_quote_partner: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: is_quote_partner\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: supplier_type\n- PG type: types.supplier_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.supplier_type_enum.enum',
                                    type: 'string',
                                    enum: ['Distributor', 'Manufacturer', 'Ems'],
                                    description: 'The supplier_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: origin\n- PG type: types.supplier_origin_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.supplier_origin_enum.enum',
                                    type: 'string',
                                    enum: ['System', 'Erp', 'Manual'],
                                    description: 'The supplier_origin_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        updated_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: updated_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        default_currency: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: public\n- Table: supplier\n- Column: default_currency\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'alternative_names',
                        'tenant',
                        'supplier_part_type',
                        'is_quote_partner',
                        'supplier_type',
                        'origin',
                        'created_at',
                        'updated_at',
                        'default_currency',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_app_user: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: app_user',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        first_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: first_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        last_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: last_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        email: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: email\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        external_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: external_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        phone_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: phone_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        timezone: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: timezone\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        user_language: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: user_language\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        position: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: position\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        photo: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: photo\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        updated_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: updated_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        invited_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: invited_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        invited_by_entity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: invited_by_entity\n- PG type: types.invited_by_entity_enum\n- PG kind: enum\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.invited_by_entity_enum.enum',
                                    type: 'string',
                                    enum: ['customer', 'organization'],
                                    description: 'The invited_by_entity_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: app_user\n- Column: supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'first_name',
                        'last_name',
                        'email',
                        'external_id',
                        'phone_number',
                        'timezone',
                        'user_language',
                        'position',
                        'photo',
                        'customer',
                        'created_at',
                        'updated_at',
                        'invited_by',
                        'invited_by_entity',
                        'supplier_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_rfq: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: rfq',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: creation_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        due_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: due_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        status: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: status\n- PG type: types.rfq_status_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.rfq_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'RequestInDraft',
                                        'QuotationInProgress',
                                        'QuotationAvailable',
                                        'OrderPlaced',
                                        'OrderInProduction',
                                        'OrderShipped',
                                        'OrderConfirmed',
                                        'OrderInProcurement',
                                        'PaymentFailed',
                                        'NoOrder',
                                        'NoQuotation',
                                    ],
                                    description: 'The rfq_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ems_internal_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: ems_internal_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        volume_estimate: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: volume_estimate\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: created_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        currency: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: currency\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        is_archived: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: is_archived\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        workflow_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: workflow_type\n- PG type: types.workflow_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.workflow_type_enum.enum',
                                    type: 'string',
                                    enum: ['Automatic', 'Manual', 'DontShow'],
                                    description: 'The workflow_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        shipping_tracking_link: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: shipping_tracking_link\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_by_m2m: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: created_by_m2m\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: rfq\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'creation_date',
                        'due_date',
                        'status',
                        'ems_internal_number',
                        'volume_estimate',
                        'created_by',
                        'currency',
                        'is_archived',
                        'customer',
                        'name',
                        'workflow_type',
                        'shipping_tracking_link',
                        'created_by_m2m',
                        'notes',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_customer: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: customer',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: billing_address\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        default_contact_person: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: default_contact_person\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: customer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        language: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: language\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        vat_identification_number_backup: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: vat_identification_number_backup\n- PG type: pg_catalog.varchar\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.varchar',
                                    type: 'string',
                                    description: 'A PG varchar',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        commercial_register_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: commercial_register_number\n- PG type: pg_catalog.varchar\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.varchar',
                                    type: 'string',
                                    description: 'A PG varchar',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address_backup: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: billing_address_backup\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        vat_identification_type_backup: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: vat_identification_type_backup\n- PG type: pg_catalog.varchar\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.varchar',
                                    type: 'string',
                                    description: 'A PG varchar',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        vat_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: vat_id\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        is_private: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: customer\n- Column: is_private\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'billing_address',
                        'default_contact_person',
                        'customer_number',
                        'language',
                        'vat_identification_number_backup',
                        'commercial_register_number',
                        'billing_address_backup',
                        'vat_identification_type_backup',
                        'vat_id',
                        'is_private',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_award_scenario: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: award_scenario',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: BY DEFAULT',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: negotiation_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: award_scenario\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'negotiation_id', 'name', 'created_at'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_organization: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: organization',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        billing_address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: billing_address\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        client_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: client_type\n- PG type: types.customer_type_enum\n- PG kind: enum\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.customer_type_enum.enum',
                                    type: 'string',
                                    enum: ['customer', 'test', 'trial', 'inactive'],
                                    description: 'The customer_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        one_row: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: one_row\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        has_access_to_customer_portal: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: has_access_to_customer_portal\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer_success_manager_email: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: customer_success_manager_email\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        has_customers: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: has_customers\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part_emission_request_quota: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: organization\n- Column: part_emission_request_quota\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'name',
                        'billing_address',
                        'client_type',
                        'one_row',
                        'has_access_to_customer_portal',
                        'customer_success_manager_email',
                        'has_customers',
                        'part_emission_request_quota',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_awarded_offer: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        private_off_the_shelf_offer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: private_off_the_shelf_offer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        public_off_the_shelf_offer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: public_off_the_shelf_offer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_offer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer\n- Column: custom_offer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                        'private_off_the_shelf_offer_id',
                        'public_off_the_shelf_offer_id',
                        'custom_offer_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_quote_request: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: quote_request',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rfq_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: rfq_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: negotiation_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        due_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: due_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        discarded: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: discarded\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sent_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: sent_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sent_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: sent_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: received_date\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        configuration_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request\n- Column: configuration_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'rfq_id',
                        'negotiation_id',
                        'supplier_id',
                        'created_at',
                        'due_date',
                        'discarded',
                        'sent_date',
                        'sent_by',
                        'received_date',
                        'configuration_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_quote_request_line_item: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: quote_request_line_item',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: ALWAYS',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        quote_request_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: quote_request_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        off_the_shelf_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: off_the_shelf_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        generic_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: generic_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: custom_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_component_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: custom_component_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        required_quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: required_quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        potential_quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: potential_quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        target_price: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: target_price\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'MonetaryValue',
                                    description: 'A monetary value',
                                    type: 'object',
                                    properties: {
                                        amount: {
                                            type: 'string',
                                        },
                                        currency: {
                                            type: 'string',
                                        },
                                    },
                                    required: ['amount', 'currency'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_private_off_the_shelf_offer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: received_private_off_the_shelf_offer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_public_off_the_shelf_offer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: received_public_off_the_shelf_offer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        received_custom_part_offer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: quote_request_line_item\n- Column: received_custom_part_offer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'quote_request_id',
                        'negotiation_line_item_id',
                        'off_the_shelf_part_id',
                        'generic_part_id',
                        'ipn_id',
                        'custom_part_id',
                        'custom_component_id',
                        'required_quantity',
                        'potential_quantity',
                        'target_price',
                        'received_private_off_the_shelf_offer',
                        'received_public_off_the_shelf_offer',
                        'received_custom_part_offer',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_site: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: site',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        address: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: address\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        site_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: site_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        tags: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: site\n- Column: tags\n- PG type: types.site_tag_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.site_tag_enum.enum',
                                    type: 'string',
                                    enum: ['Inventory', 'Manufacturing', 'Sourcing'],
                                    description: 'The site_tag_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'name', 'address', 'notes', 'site_number', 'tags'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: ALWAYS',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_by: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation\n- Column: created_by\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'name', 'created_at', 'created_by'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_line_item: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_line_item',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item\n- Column: negotiation_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item\n- Column: quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['id', 'negotiation_id', 'quantity'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_line_item_demand: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_demand',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_demand\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        demand_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_demand\n- Column: demand_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['negotiation_line_item_id', 'demand_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_line_item_part_option: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: ALWAYS',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        off_the_shelf_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: off_the_shelf_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        generic_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: generic_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: custom_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_component_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_line_item_part_option\n- Column: custom_component_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'negotiation_line_item_id',
                        'off_the_shelf_part_id',
                        'generic_part_id',
                        'ipn_id',
                        'custom_part_id',
                        'custom_component_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_demand: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: demand',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: true,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        demand_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: demand_type\n- PG type: types.demand_type_enum\n- PG kind: enum\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.demand_type_enum.enum',
                                    type: 'string',
                                    enum: ['gross', 'net'],
                                    description: 'The demand_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ots_part_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: ots_part_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        component_ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: component_ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        assembly_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: assembly_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        production_start_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: production_start_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        delivery_start_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: delivery_start_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        quantity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: quantity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'QuantityUnit',
                                    description: 'A unit of measurement and quantity',
                                    type: 'object',
                                    properties: {
                                        unit: {
                                            type: 'string',
                                            enum: ['Kg', 'Meters', 'Liters', 'Pieces'],
                                        },
                                        quantity: {
                                            type: 'number',
                                        },
                                    },
                                    required: ['unit', 'quantity'],
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_external: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: supplier_external\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_internal: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: supplier_internal\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        recipient_external: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: recipient_external\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        recipient_internal: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: recipient_internal\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: false\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        delivery_end_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: delivery_end_date\n- PG type: pg_catalog.date\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.date',
                                    type: 'string',
                                    format: 'date',
                                    description:
                                        'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        context: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: context\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        end_customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: end_customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sourcing_scenario_origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: demand\n- Column: sourcing_scenario_origin\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: true\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'id',
                        'demand_type',
                        'ots_part_id',
                        'component_ipn_id',
                        'assembly_id',
                        'production_start_date',
                        'delivery_start_date',
                        'quantity',
                        'supplier_external',
                        'supplier_internal',
                        'recipient_external',
                        'recipient_internal',
                        'created_at',
                        'delivery_end_date',
                        'context',
                        'end_customer',
                        'sourcing_scenario_origin',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_part_option_off_the_shelf: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeOts',
                                    type: 'string',
                                    const: 'off_the_shelf',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        mpn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: mpn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: manufacturer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: manufacturer_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_off_the_shelf\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['kind', 'id', 'mpn', 'manufacturer_id', 'manufacturer_name', 'negotiation_line_item_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_part_option_generic: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeGeneric',
                                    type: 'string',
                                    const: 'generic',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        content: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: content\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        package_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: package_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        created_at: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: created_at\n- PG type: pg_catalog.timestamptz\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamptz',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_generic\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['kind', 'id', 'content', 'package_id', 'created_at', 'negotiation_line_item_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_part_option_component: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeComponent',
                                    type: 'string',
                                    const: 'component',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: ipn_id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        off_the_shelf_part_matches: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: off_the_shelf_part_matches\n- PG type: luminovo.negotiation_part_off_the_shelf\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        generic_part_matches: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: generic_part_matches\n- PG type: luminovo.negotiation_part_option_generic\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        raw_specifications: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: raw_specifications\n- PG type: luminovo.internal_part_number_raw_specification\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_component\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'ipn_id',
                        'off_the_shelf_part_matches',
                        'generic_part_matches',
                        'raw_specifications',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_part_option_custom: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeCustom',
                                    type: 'string',
                                    const: 'custom',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: part_type\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        description: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: description\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        reach_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: reach_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rohs_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: rohs_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'part_type',
                        'description',
                        'reach_compliant',
                        'rohs_compliant',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_negotiation_part_option_custom_component: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeComponentCustom',
                                    type: 'string',
                                    const: 'custom_component',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: id\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        description: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: description\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        matches: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: matches\n- PG type: luminovo.negotiation_part_option_custom\n- PG kind: composite\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {},
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: negotiation_part_option_custom_component\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: ['kind', 'id', 'description', 'matches', 'negotiation_line_item_id'],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_awarded_offer_off_the_shelf: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_part_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: supplier_part_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: part\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: origin\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: creation_date\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        valid_until: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: valid_until\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: offer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        available_prices: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: available_prices\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        price_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: price_type\n- PG type: types.price_type_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.price_type_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'list_price',
                                        'contract_price',
                                        'quote_price',
                                        'purchase_price',
                                        'customer_negotiated_price',
                                        'standard_price',
                                    ],
                                    description: 'The price_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        unit_of_measurement: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: unit_of_measurement\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: offer_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        attachment: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: attachment\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_and_stock_location: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: supplier_and_stock_location\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        packaging: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: packaging\n- PG type: types.packaging_v2_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.packaging_v2_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'ammo_pack',
                                        'bag',
                                        'bulk',
                                        'reel',
                                        're_reel',
                                        'tape',
                                        'tray',
                                        'tube',
                                        'carton',
                                    ],
                                    description: 'The packaging_v2_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rfq: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: rfq\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: ipn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        inventory_site: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: inventory_site\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        customer: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: customer\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        inventory_unique_key: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: inventory_unique_key\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ncnr: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: ncnr\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: awarded_supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf\n- Column: awarded_supplier_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'supplier_part_number',
                        'part',
                        'origin',
                        'notes',
                        'creation_date',
                        'valid_until',
                        'offer_number',
                        'available_prices',
                        'price_type',
                        'unit_of_measurement',
                        'offer_url',
                        'attachment',
                        'supplier_and_stock_location',
                        'packaging',
                        'rfq',
                        'ipn',
                        'inventory_site',
                        'customer',
                        'inventory_unique_key',
                        'ncnr',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                        'awarded_supplier_id',
                        'awarded_supplier_name',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_awarded_offer_off_the_shelf_public: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_part_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: supplier_part_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: part\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: origin\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: creation_date\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        valid_until: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: valid_until\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: offer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        available_prices: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: available_prices\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        unit_of_measurement: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: unit_of_measurement\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: offer_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        attachment: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: attachment\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_and_stock_location: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: supplier_and_stock_location\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        packaging: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: packaging\n- PG type: types.packaging_v2_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.packaging_v2_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'ammo_pack',
                                        'bag',
                                        'bulk',
                                        'reel',
                                        're_reel',
                                        'tape',
                                        'tray',
                                        'tube',
                                        'carton',
                                    ],
                                    description: 'The packaging_v2_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ncnr: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: ncnr\n- PG type: pg_catalog.bool\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.bool',
                                    type: 'boolean',
                                    description: 'A PG bool',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: awarded_supplier_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        awarded_supplier_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_off_the_shelf_public\n- Column: awarded_supplier_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'supplier_part_number',
                        'part',
                        'origin',
                        'notes',
                        'creation_date',
                        'valid_until',
                        'offer_number',
                        'available_prices',
                        'unit_of_measurement',
                        'offer_url',
                        'attachment',
                        'supplier_and_stock_location',
                        'packaging',
                        'ncnr',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                        'awarded_supplier_id',
                        'awarded_supplier_name',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_awarded_offer_custom: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        notes: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: notes\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        creation_date: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: creation_date\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        valid_until: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: valid_until\n- PG type: pg_catalog.timestamp\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.timestamp',
                                    type: 'string',
                                    format: 'date-time',
                                    description:
                                        'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_number: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: offer_number\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        price_points: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: price_points\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        one_time_costs: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: one_time_costs\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        custom_part: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: custom_part\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        unit_of_measurement: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: unit_of_measurement\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        supplier_and_stock_location: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: supplier_and_stock_location\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_validity: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: offer_validity\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        offer_url: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: offer_url\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        price_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: price_type\n- PG type: types.custom_price_type_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.custom_price_type_enum.enum',
                                    type: 'string',
                                    enum: ['list_price', 'quote_price', 'customer_negotiated_price', 'contract_price'],
                                    description: 'The custom_price_type_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        sourcing_scenario: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: sourcing_scenario\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        origin: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: origin\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        ipn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: ipn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        specification: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: specification\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_offer_custom\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'notes',
                        'creation_date',
                        'valid_until',
                        'offer_number',
                        'price_points',
                        'one_time_costs',
                        'custom_part',
                        'unit_of_measurement',
                        'supplier_and_stock_location',
                        'offer_validity',
                        'offer_url',
                        'price_type',
                        'sourcing_scenario',
                        'origin',
                        'ipn',
                        'specification',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_awarded_part_custom: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_part_custom',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeCustom',
                                    type: 'string',
                                    const: 'custom',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        part_type: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: part_type\n- PG type: pg_catalog.jsonb\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.jsonb',
                                    type: 'object',
                                    description: 'A PG jsonb',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        description: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: description\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        reach_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: reach_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        rohs_compliant: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: rohs_compliant\n- PG type: types.compliance_status_enum\n- PG kind: enum\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'types.compliance_status_enum.enum',
                                    type: 'string',
                                    enum: [
                                        'compliant',
                                        'compliant_with_exemption',
                                        'non_compliant',
                                        'not_required',
                                        'unknown',
                                    ],
                                    description: 'The compliance_status_enum enum from the types schema',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_custom\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'part_type',
                        'description',
                        'reach_compliant',
                        'rohs_compliant',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        table_awarded_part_off_the_shelf: {
            type: 'object',
            description: 'Table details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf',
            properties: {
                columns: {
                    type: 'object',
                    properties: {
                        kind: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: kind\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'PartTypeOts',
                                    type: 'string',
                                    const: 'off_the_shelf',
                                    description: '-',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        mpn: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: mpn\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: manufacturer_id\n- PG type: pg_catalog.uuid\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.uuid',
                                    type: 'string',
                                    format: 'uuid',
                                    description: 'A PG uuid',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        manufacturer_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: manufacturer_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_name: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: award_scenario_name\n- PG type: pg_catalog.text\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.text',
                                    type: 'string',
                                    description: 'A PG text',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        award_scenario_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: award_scenario_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                        negotiation_line_item_id: {
                            type: 'object',
                            description:
                                'Column details:\n\n- Schema: luminovo\n- Table: awarded_part_off_the_shelf\n- Column: negotiation_line_item_id\n- PG type: pg_catalog.int4\n- PG kind: base\n- Nullable: undefined\n- Generated: NEVER',
                            properties: {
                                type: {
                                    id: 'pg_catalog.int4',
                                    type: 'integer',
                                    minimum: -2147483648,
                                    maximum: 2147483647,
                                    description: 'A PG int4',
                                },
                                selectable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                includable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                whereable: {
                                    type: 'boolean',
                                    const: true,
                                },
                                nullable: {
                                    type: 'boolean',
                                    const: false,
                                },
                                isPrimaryKey: {
                                    type: 'boolean',
                                    const: false,
                                },
                            },
                            required: ['type', 'selectable', 'includable', 'whereable', 'nullable', 'isPrimaryKey'],
                            additionalProperties: false,
                        },
                    },
                    required: [
                        'kind',
                        'id',
                        'mpn',
                        'manufacturer_id',
                        'manufacturer_name',
                        'award_scenario_name',
                        'award_scenario_id',
                        'negotiation_line_item_id',
                    ],
                    additionalProperties: false,
                },
            },
            required: ['columns'],
            additionalProperties: false,
        },
        'pg_catalog.text': {
            id: 'pg_catalog.text',
            type: 'string',
            description: 'A PG text',
        },
        'pg_catalog.varchar': {
            id: 'pg_catalog.varchar',
            type: 'string',
            description: 'A PG varchar',
        },
        'pg_catalog.bool': {
            id: 'pg_catalog.bool',
            type: 'boolean',
            description: 'A PG bool',
        },
        'pg_catalog.time': {
            id: 'pg_catalog.time',
            type: 'string',
            format: 'time',
            description:
                'A PG time.\nNote that values of the PG time type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript',
        },
        'pg_catalog.timetz': {
            id: 'pg_catalog.timetz',
            type: 'string',
            format: 'time',
            description:
                'A PG timetz.\nNote that values of the PG timetz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(timeString)` or `Date.parse(timeString)`',
        },
        'pg_catalog.date': {
            id: 'pg_catalog.date',
            type: 'string',
            format: 'date',
            description:
                'A PG date.\nNote that values of the PG date type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateString)` or `Date.parse(dateString)`',
        },
        'pg_catalog.timestamp': {
            id: 'pg_catalog.timestamp',
            type: 'string',
            format: 'date-time',
            description:
                'A PG timestamp.\nNote that values of the PG timestamp type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript.\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
        },
        'pg_catalog.timestamptz': {
            id: 'pg_catalog.timestamptz',
            type: 'string',
            format: 'date-time',
            description:
                'A PG timestamptz.\nNote that values of the PG timestamptz type,\nare returned as ISO 8601 strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript\nTo convert the string into a `Date` object,\nuse `new Date(dateTimeString)` or `Date.parse(dateTimeString)`',
        },
        'pg_catalog.numeric': {
            id: 'pg_catalog.numeric',
            type: 'string',
            description:
                'A PG numeric.\nNote that values of the PG numeric type,\nare returned as strings from the database.\nThis is because that is how they can be best\naccurately processed in JavaScript/TypeScript',
        },
        'pg_catalog.int2': {
            id: 'pg_catalog.int2',
            type: 'integer',
            minimum: -32768,
            maximum: 32767,
            description: 'A PG int2',
        },
        'pg_catalog.int4': {
            id: 'pg_catalog.int4',
            type: 'integer',
            minimum: -2147483648,
            maximum: 2147483647,
            description: 'A PG int4',
        },
        'pg_catalog.int8': {
            id: 'pg_catalog.int8',
            type: 'integer',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
            description: 'A PG int8',
        },
        'pg_catalog.float4': {
            id: 'pg_catalog.float4',
            type: 'number',
            description: 'A PG float4',
        },
        'pg_catalog.float8': {
            id: 'pg_catalog.float8',
            type: 'number',
            description: 'A PG float8',
        },
        'pg_catalog.tsvector': {
            id: 'pg_catalog.tsvector',
            type: 'string',
            description: 'A PG tsvector',
        },
        'pg_catalog.bpchar': {
            id: 'pg_catalog.bpchar',
            type: 'string',
            description: 'A PG bpchar',
        },
        'pg_catalog.bytea': {
            id: 'pg_catalog.bytea',
            type: 'string',
            description: 'A PG bytea',
        },
        'pg_catalog.uuid': {
            id: 'pg_catalog.uuid',
            type: 'string',
            format: 'uuid',
            description: 'A PG uuid',
        },
        'pg_catalog.json': {
            id: 'pg_catalog.json',
            type: 'object',
            description: 'A PG json',
        },
        'pg_catalog.jsonb': {
            id: 'pg_catalog.jsonb',
            type: 'object',
            description: 'A PG jsonb',
        },
        'types.activity_category_enum.enum': {
            id: 'types.activity_category_enum.enum',
            type: 'string',
            enum: ['preparation', 'execution', 'inspection', 'post_processing'],
            description: 'The activity_category_enum enum from the types schema',
        },
        'types.activity_process_enum.enum': {
            id: 'types.activity_process_enum.enum',
            type: 'string',
            enum: [
                'smt',
                'tht',
                'panel_separation',
                'ruggedizing',
                'mechanical_assembly',
                'testing',
                'cable_assembly',
                'logistics',
                'other',
            ],
            description: 'The activity_process_enum enum from the types schema',
        },
        'types.activity_process_enum20210923121501.enum': {
            id: 'types.activity_process_enum20210923121501.enum',
            type: 'string',
            enum: [
                'process_preparation',
                'smt',
                'tht',
                'panel_separation',
                'ruggedizing',
                'mechanical_assembly',
                'testing',
                'cable_assembly',
                'logistics',
                'other',
                'quality_control',
                'packaging',
                'npi',
                'hand_soldering',
                'potting',
                'inspection',
            ],
            description: 'The activity_process_enum20210923121501 enum from the types schema',
        },
        'types.assembly_type_enum.enum': {
            id: 'types.assembly_type_enum.enum',
            type: 'string',
            enum: ['Box', 'Pcba', 'Cable', 'System', 'Bom'],
            description: 'The assembly_type_enum enum from the types schema',
        },
        'types.compliance_status_enum.enum': {
            id: 'types.compliance_status_enum.enum',
            type: 'string',
            enum: ['compliant', 'compliant_with_exemption', 'non_compliant', 'not_required', 'unknown'],
            description: 'The compliance_status_enum enum from the types schema',
        },
        'types.ems_part_number_state.enum': {
            id: 'types.ems_part_number_state.enum',
            type: 'string',
            enum: ['active', 'removed'],
            description: 'The ems_part_number_state enum from the types schema',
        },
        'types.expense_level_enum.enum': {
            id: 'types.expense_level_enum.enum',
            type: 'string',
            enum: ['project', 'unit', 'batch'],
            description: 'The expense_level_enum enum from the types schema',
        },
        'types.internal_part_number_state.enum': {
            id: 'types.internal_part_number_state.enum',
            type: 'string',
            enum: ['active', 'removed'],
            description: 'The internal_part_number_state enum from the types schema',
        },
        'types.lifecycle_enum.enum': {
            id: 'types.lifecycle_enum.enum',
            type: 'string',
            enum: [
                'acquired',
                'aftermarket',
                'pre_release',
                'active',
                'not_recommended_for_new_designs',
                'end_of_life',
                'obsolete',
                'unknown',
            ],
            description: 'The lifecycle_enum enum from the types schema',
        },
        'types.manufacturingentitystatus_enum.enum': {
            id: 'types.manufacturingentitystatus_enum.enum',
            type: 'string',
            enum: ['active', 'inactive'],
            description: 'The manufacturingentitystatus_enum enum from the types schema',
        },
        'types.mounting_enum.enum': {
            id: 'types.mounting_enum.enum',
            type: 'string',
            enum: ['smt', 'tht', 'other', 'unknown'],
            description: 'The mounting_enum enum from the types schema',
        },
        'types.organization_type_enum.enum': {
            id: 'types.organization_type_enum.enum',
            type: 'string',
            enum: ['ems', 'oem'],
            description: 'The organization_type_enum enum from the types schema',
        },
        'types.origin_enum.enum': {
            id: 'types.origin_enum.enum',
            type: 'string',
            enum: ['manual', 'farnell_api', 'octopart_api', 'digikey_api'],
            description: 'The origin_enum enum from the types schema',
        },
        'types.packaging_v2_enum.enum': {
            id: 'types.packaging_v2_enum.enum',
            type: 'string',
            enum: ['ammo_pack', 'bag', 'bulk', 'reel', 're_reel', 'tape', 'tray', 'tube', 'carton'],
            description: 'The packaging_v2_enum enum from the types schema',
        },
        'types.price_type_enum.enum': {
            id: 'types.price_type_enum.enum',
            type: 'string',
            enum: [
                'list_price',
                'contract_price',
                'quote_price',
                'purchase_price',
                'customer_negotiated_price',
                'standard_price',
            ],
            description: 'The price_type_enum enum from the types schema',
        },
        'types.pricetype_enum.enum': {
            id: 'types.pricetype_enum.enum',
            type: 'string',
            enum: ['list_price', 'contract_price', 'quote_price'],
            description: 'The pricetype_enum enum from the types schema',
        },
        'types.project_industry_enum.enum': {
            id: 'types.project_industry_enum.enum',
            type: 'string',
            enum: ['Auto', 'Medical', 'Defense', 'Aero', 'Rail', 'Industrial', 'Construction', 'Other'],
            description: 'The project_industry_enum enum from the types schema',
        },
        'types.region_enum.enum': {
            id: 'types.region_enum.enum',
            type: 'string',
            enum: [
                'Unknown',
                'World',
                'Africa',
                'NorthernAfrica',
                'SubSaharanAfrica',
                'WesternAfrica',
                'EasternAfrica',
                'MiddleAfrica',
                'SouthernAfrica',
                'Americas',
                'NorthernAmerica',
                'LatinAmericaAndTheCaribbean',
                'SouthAmerica',
                'CentralAmerica',
                'Caribbean',
                'Asia',
                'EasternAsia',
                'SouthernAsia',
                'SouthEasternAsia',
                'CentralAsia',
                'WesternAsia',
                'Europe',
                'SouthernEurope',
                'EasternEurope',
                'NorthernEurope',
                'ChannelIslands',
                'WesternEurope',
                'Oceania',
                'AustraliaAndNewZealand',
                'Melanesia',
                'Micronesia',
                'Polynesia',
                'Algeria',
                'Egypt',
                'Libya',
                'Morocco',
                'Sudan',
                'Tunisia',
                'WesternSahara',
                'BritishIndianOceanTerritory',
                'Burundi',
                'Comoros',
                'Djibouti',
                'Eritrea',
                'Ethiopia',
                'FrenchSouthernTerritories',
                'Kenya',
                'Madagascar',
                'Malawi',
                'Mauritius',
                'Mayotte',
                'Mozambique',
                'Reunion',
                'Rwanda',
                'Seychelles',
                'Somalia',
                'SouthSudan',
                'Uganda',
                'UnitedRepublicOfTanzania',
                'Zambia',
                'Zimbabwe',
                'Angola',
                'Cameroon',
                'CentralAfricanRepublic',
                'Chad',
                'Congo',
                'DemocraticRepublicOfTheCongo',
                'EquatorialGuinea',
                'Gabon',
                'SaoTomeAndPrincipe',
                'Botswana',
                'Eswatini',
                'Lesotho',
                'Namibia',
                'SouthAfrica',
                'Benin',
                'BurkinaFaso',
                'CaboVerde',
                'CoteDIvore',
                'Gambia',
                'Ghana',
                'Guinea',
                'GuineaBissau',
                'Liberia',
                'Mali',
                'Mauritania',
                'Niger',
                'Nigeria',
                'SaintHelena',
                'Senegal',
                'SierraLeone',
                'Togo',
                'Anguilla',
                'AntiguaAndBarbuda',
                'Aruba',
                'Bahamas',
                'Barbados',
                'BonaireAndSintEustatiusAndSaba',
                'BritishVirginIslands',
                'CaymanIslands',
                'Cuba',
                'Curacao',
                'Dominica',
                'DominicanRepublic',
                'Grenada',
                'Guadeloupe',
                'Haiti',
                'Jamaica',
                'Martinique',
                'Montserrat',
                'PuertoRico',
                'SaintBarthelemy',
                'SaintKittsAndNevis',
                'SaintLucia',
                'SaintMartin',
                'SaintVincentAndTheGrenadines',
                'SintMaarten',
                'TrinidadAndTobago',
                'TurksAndCaicosIslands',
                'UnitedStatesVirginIslands',
                'Belize',
                'CostaRica',
                'ElSalvador',
                'Guatemala',
                'Honduras',
                'Mexico',
                'Nicaragua',
                'Panama',
                'Argentina',
                'Bolivia',
                'BouvetIsland',
                'Brazil',
                'Chile',
                'Colombia',
                'Ecuador',
                'FalklandIslands',
                'FrenchGuiana',
                'Guyana',
                'Paraguay',
                'Peru',
                'SouthGeorgiaAndTheSouthSandwichIslands',
                'Suriname',
                'Uruguay',
                'Venezuela',
                'Bermuda',
                'Canada',
                'Greenland',
                'SaintPierreAndMiquelon',
                'UnitedStatesOfAmerica',
                'Antarctica',
                'Kazakhstan',
                'Kyrgyzstan',
                'Tajikistan',
                'Turkmenistan',
                'Uzbekistan',
                'China',
                'Taiwan',
                'HongKong',
                'Macao',
                'DemocraticPeoplesRepublicOfKorea',
                'Japan',
                'Mongolia',
                'RepublicOfKorea',
                'BruneiDarussalam',
                'Cambodia',
                'Indonesia',
                'LaoPeoplesDemocraticRepublic',
                'Malaysia',
                'Myanmar',
                'Philippines',
                'Singapore',
                'Thailand',
                'TimorLeste',
                'VietNam',
                'Afghanistan',
                'Bangladesh',
                'Bhutan',
                'India',
                'Iran',
                'Maldives',
                'Nepal',
                'Pakistan',
                'SriLanka',
                'Armenia',
                'Azerbaijan',
                'Bahrain',
                'Cyprus',
                'Georgia',
                'Iraq',
                'Israel',
                'Jordan',
                'Kuwait',
                'Lebanon',
                'Oman',
                'Qatar',
                'SaudiArabia',
                'StateOfPalestine',
                'SyrianArabRepublic',
                'Turkey',
                'UnitedArabEmirates',
                'Yemen',
                'Belarus',
                'Bulgaria',
                'Czechia',
                'Hungary',
                'Poland',
                'RepublicOfMoldova',
                'Romania',
                'RussianFederation',
                'Slovakia',
                'Ukraine',
                'AlandIslands',
                'Guernsey',
                'Jersey',
                'Sark',
                'Denmark',
                'Estonia',
                'FaroeIslands',
                'Finland',
                'Iceland',
                'Ireland',
                'IsleOfMan',
                'Latvia',
                'Lithuania',
                'Norway',
                'SvalbardAndJanMayenIslands',
                'Sweden',
                'UnitedKingdomOfGreatBritainAndNorthernIreland',
                'Albania',
                'Andorra',
                'BosniaAndHerzegovina',
                'Croatia',
                'Gibraltar',
                'Greece',
                'HolySee',
                'Italy',
                'Malta',
                'Montenegro',
                'NorthMacedonia',
                'Portugal',
                'SanMarino',
                'Serbia',
                'Slovenia',
                'Spain',
                'Austria',
                'Belgium',
                'France',
                'Germany',
                'Liechtenstein',
                'Luxembourg',
                'Monaco',
                'Netherlands',
                'Switzerland',
                'Australia',
                'ChristmasIsland',
                'CocosKeelingIslands',
                'HeardIslandAndMcDonaldIslands',
                'NewZealand',
                'NorfolkIsland',
                'Fiji',
                'NewCaledonia',
                'PapuaNewGuinea',
                'SolomonIslands',
                'Vanuatu',
                'Guam',
                'Kiribati',
                'MarshallIslands',
                'FederatedStatesOfMicronesia',
                'Nauru',
                'NorthernMarianaIslands',
                'Palau',
                'UnitedStatesMinorOutlyingIslands',
                'AmericanSamoa',
                'CookIslands',
                'FrenchPolynesia',
                'Niue',
                'Pitcairn',
                'Samoa',
                'Tokelau',
                'Tonga',
                'Tuvalu',
                'WallisAndFutunaIslands',
            ],
            description: 'The region_enum enum from the types schema',
        },
        'types.resource_type_enum.enum': {
            id: 'types.resource_type_enum.enum',
            type: 'string',
            enum: ['machine', 'person'],
            description: 'The resource_type_enum enum from the types schema',
        },
        'types.rfq_status_enum.enum': {
            id: 'types.rfq_status_enum.enum',
            type: 'string',
            enum: [
                'RequestInDraft',
                'QuotationInProgress',
                'QuotationAvailable',
                'OrderPlaced',
                'OrderInProduction',
                'OrderShipped',
                'OrderConfirmed',
                'OrderInProcurement',
                'PaymentFailed',
                'NoOrder',
                'NoQuotation',
            ],
            description: 'The rfq_status_enum enum from the types schema',
        },
        'types.sources_api_enum.enum': {
            id: 'types.sources_api_enum.enum',
            type: 'string',
            enum: [
                'octopart',
                'nexar',
                'trustedparts',
                'myarrow',
                'arrow',
                'wuertheisos',
                'alelektronik',
                'chipcart',
                'avnetusa',
                'rochester',
                'blume',
                'ti',
                'lcsc',
                'eve',
                'tti',
                'samtec',
                'questcomponents',
                'rutronik',
                'buerklin',
                'mouser',
                'future',
                'schukat',
                'tme',
                'weltron',
                'farnell',
                'newark',
                'element14',
                'digikey',
                'orbweaver',
                'gudeco',
                'avnetebv',
                'avnetsilica',
                'avnetabacus',
                'wuerth',
                'ibrringler',
                'safe_pcb',
                'apct',
                'gatema',
                'alba',
                'beta_layout',
                'ibr_ringler',
                'heilind',
                'master',
                'onlinecomponents',
                'rscomponents',
                'sos',
                'sourcengine',
                'venkel',
                'avnetapac',
                'sluicebox',
            ],
            description: 'The sources_api_enum enum from the types schema',
        },
        'types.sources_api_result_enum.enum': {
            id: 'types.sources_api_result_enum.enum',
            type: 'string',
            enum: ['ok', 'too_many_requests', 'other_error'],
            description: 'The sources_api_result_enum enum from the types schema',
        },
        'types.templatestatus_enum.enum': {
            id: 'types.templatestatus_enum.enum',
            type: 'string',
            enum: ['active', 'inactive'],
            description: 'The templatestatus_enum enum from the types schema',
        },
        'types.unit_type.enum': {
            id: 'types.unit_type.enum',
            type: 'string',
            enum: ['meter', 'liter', 'gram', 'piece'],
            description: 'The unit_type enum from the types schema',
        },
        'types.assembly_origin.enum': {
            id: 'types.assembly_origin.enum',
            type: 'string',
            enum: ['Manual', 'ApiImport', 'BomImport'],
            description: 'The assembly_origin enum from the types schema',
        },
        'types.customer_type_enum.enum': {
            id: 'types.customer_type_enum.enum',
            type: 'string',
            enum: ['customer', 'test', 'trial', 'inactive'],
            description: 'The customer_type_enum enum from the types schema',
        },
        'types.solution_preference_stock_location_kind_enum.enum': {
            id: 'types.solution_preference_stock_location_kind_enum.enum',
            type: 'string',
            enum: ['preferred', 'approved', 'excluded'],
            description: 'The solution_preference_stock_location_kind_enum enum from the types schema',
        },
        'types.vote_origin_enum.enum': {
            id: 'types.vote_origin_enum.enum',
            type: 'string',
            enum: [
                'OctopartAPI',
                'TrustedPartsAPI',
                'Manual',
                'FarnellAPI',
                'DigikeyAPI',
                'Import',
                'AlElektronikAPI',
                'ChipCartAPI',
                'AvnetApacAPI',
                'SluiceboxAPI',
                'RochesterAPI',
                'QuestComponentsAPI',
                'SamtecAPI',
                'BlumeAPI',
                'TtiAPI',
                'LcscAPI',
                'ChipAssistAPI',
                'VenkelAPI',
                'SosAPI',
                'MasterAPI',
                'OnlineComponentsAPI',
                'HeilindAPI',
                'EveAPI',
                'RsComponentsAPI',
                'SourcengineAPI',
                'AvnetUsaAPI',
                'TiAPI',
                'WuerthEisosAPI',
                'DescriptionExtraction',
                'TmeAPI',
                'SchukatAPI',
                'WeltronAPI',
                'BuerklinAPI',
                'RutronikAPI',
                'GudecoAPI',
                'Element14API',
                'MouserAPI',
                'FutureAPI',
                'AvnetAPI',
                'NewarkAPI',
                'MyArrowAPI',
                'ArrowAPI',
                'IHSImport',
            ],
            description: 'The vote_origin_enum enum from the types schema',
        },
        'types.custom_price_type_enum.enum': {
            id: 'types.custom_price_type_enum.enum',
            type: 'string',
            enum: ['list_price', 'quote_price', 'customer_negotiated_price', 'contract_price'],
            description: 'The custom_price_type_enum enum from the types schema',
        },
        'types.quote_request_hidden_field_enum.enum': {
            id: 'types.quote_request_hidden_field_enum.enum',
            type: 'string',
            enum: ['customer'],
            description: 'The quote_request_hidden_field_enum enum from the types schema',
        },
        'types.quote_request_state_enum.enum': {
            id: 'types.quote_request_state_enum.enum',
            type: 'string',
            enum: ['Pending', 'Received', 'Discarded'],
            description: 'The quote_request_state_enum enum from the types schema',
        },
        'types.rohs_version_enum.enum': {
            id: 'types.rohs_version_enum.enum',
            type: 'string',
            enum: ['rohs_2002_95_ec', 'rohs2_2011_65_eu', 'rohs2_2015_863_eu'],
            description: 'The rohs_version_enum enum from the types schema',
        },
        'types.qualification_enum.enum': {
            id: 'types.qualification_enum.enum',
            type: 'string',
            enum: ['aec_q100', 'aec_q100_200', 'aec_q101', 'aec_q102', 'aec_q200', 'dla', 'rad_hard', 'space', 'ul'],
            description: 'The qualification_enum enum from the types schema',
        },
        'types.assembly_industry_enum.enum': {
            id: 'types.assembly_industry_enum.enum',
            type: 'string',
            enum: ['Auto', 'Medical', 'Defense', 'Aero', 'Rail', 'Industrial', 'Construction', 'Consumer', 'Other'],
            description: 'The assembly_industry_enum enum from the types schema',
        },
        'types.part_alternative_origin.enum': {
            id: 'types.part_alternative_origin.enum',
            type: 'string',
            enum: ['Internal', 'IHS'],
            description: 'The part_alternative_origin enum from the types schema',
        },
        'types.part_specification_type_enum.enum': {
            id: 'types.part_specification_type_enum.enum',
            type: 'string',
            enum: ['off_the_shelf', 'custom'],
            description: 'The part_specification_type_enum enum from the types schema',
        },
        'types.approval_status_enum.enum': {
            id: 'types.approval_status_enum.enum',
            type: 'string',
            enum: ['rejected', 'pending', 'approved'],
            description: 'The approval_status_enum enum from the types schema',
        },
        'types.generic_part_type_enum.enum': {
            id: 'types.generic_part_type_enum.enum',
            type: 'string',
            enum: ['capacitor', 'resistor'],
            description: 'The generic_part_type_enum enum from the types schema',
        },
        'types.history_operation_enum.enum': {
            id: 'types.history_operation_enum.enum',
            type: 'string',
            enum: [
                'auto_add',
                'auto_update',
                'manual_add',
                'manual_approved',
                'manual_remove',
                'manual_update',
                'manual_update_approval',
                'manual_resolve',
                'manual_unresolve',
                'manual_upload',
                'auto_add_via_quote_import',
                'auto_remove',
            ],
            description: 'The history_operation_enum enum from the types schema',
        },
        'types.comment_category_enum.enum': {
            id: 'types.comment_category_enum.enum',
            type: 'string',
            enum: ['internal', 'public'],
            description: 'The comment_category_enum enum from the types schema',
        },
        'types.workflow_type_enum.enum': {
            id: 'types.workflow_type_enum.enum',
            type: 'string',
            enum: ['Automatic', 'Manual', 'DontShow'],
            description: 'The workflow_type_enum enum from the types schema',
        },
        'types.monitoring_frequency_enum.enum': {
            id: 'types.monitoring_frequency_enum.enum',
            type: 'string',
            enum: ['Inactive', 'Daily', 'Weekly', 'Monthly'],
            description: 'The monitoring_frequency_enum enum from the types schema',
        },
        'types.costed_bom_column_enum.enum': {
            id: 'types.costed_bom_column_enum.enum',
            type: 'string',
            enum: [
                'designator',
                'quantity',
                'approved_parts',
                'pending_parts',
                'manufacturer_free',
                'internal_part_number',
                'manufacturer',
                'mpn',
                'part_type',
                'description',
                'package',
                'mounting',
                'pins',
                'part_warnings',
                'aggregated_quantity',
                'scrap_quantity',
                'purchase_quantity',
                'excess_material_quantity',
                'consignment',
                'supplier',
                'supplier_number',
                'supplier_part_number',
                'unit_price',
                'one_time_costs',
                'purchase_price',
                'original_purchase_price',
                'excess_material_costs',
                'original_currency',
                'currency_exchange_rate',
                'lead_time_days',
                'stock',
                'on_order',
                'std_factory_lead_time_days',
                'factory_quantity',
                'packaging',
                'moq',
                'mpq',
                'offer_number',
                'offer_notes',
                'solution_warnings',
                'price_type',
                'validity',
                'origin',
                'notes',
                'sourcing_notes',
                'last_updated',
                'customer_part_number',
                'unit_price_original_currency',
                'line_value',
                'ncnr',
                'country_of_origin',
                'eccn',
                'hts_code',
                'china_rohs',
                'prop65',
                'conflict_mineral_status',
                'reach_compliant',
                'rohs_compliant',
                'rohs_version',
                'latest_datasheet_url',
                'lifecycle_yteol',
                'lifecycle_yteol_range',
                'last_buy_date',
                'last_delivery_date',
                'svhc_cas_number',
                'origin_set_method',
                'bom_ipn',
                'bom_cpn',
                'assembly_quantity',
                'assembly_excess_material_quantity',
                'assembly_designator',
                'bom_mpn',
            ],
            description: 'The costed_bom_column_enum enum from the types schema',
        },
        'types.site_type_enum.enum': {
            id: 'types.site_type_enum.enum',
            type: 'string',
            enum: ['Inventory', 'Manufacturing', 'ManufacturingInventory'],
            description: 'The site_type_enum enum from the types schema',
        },
        'types.invited_by_entity_enum.enum': {
            id: 'types.invited_by_entity_enum.enum',
            type: 'string',
            enum: ['customer', 'organization'],
            description: 'The invited_by_entity_enum enum from the types schema',
        },
        'types.pnp_file_state_enum.enum': {
            id: 'types.pnp_file_state_enum.enum',
            type: 'string',
            enum: ['Created', 'Uploaded', 'Parsed'],
            description: 'The pnp_file_state_enum enum from the types schema',
        },
        'types.pnp_side_enum.enum': {
            id: 'types.pnp_side_enum.enum',
            type: 'string',
            enum: ['Top', 'Bottom'],
            description: 'The pnp_side_enum enum from the types schema',
        },
        'types.site_tag_enum.enum': {
            id: 'types.site_tag_enum.enum',
            type: 'string',
            enum: ['Inventory', 'Manufacturing', 'Sourcing'],
            description: 'The site_tag_enum enum from the types schema',
        },
        'types.internal_part_number_type.enum': {
            id: 'types.internal_part_number_type.enum',
            type: 'string',
            enum: ['off_the_shelf', 'custom', 'assembly'],
            description: 'The internal_part_number_type enum from the types schema',
        },
        'types.conflict_mineral_status_enum.enum': {
            id: 'types.conflict_mineral_status_enum.enum',
            type: 'string',
            enum: ['compliant', 'unknown'],
            description: 'The conflict_mineral_status_enum enum from the types schema',
        },
        'types.eccn_governance_enum.enum': {
            id: 'types.eccn_governance_enum.enum',
            type: 'string',
            enum: ['EAR', 'ITAR'],
            description: 'The eccn_governance_enum enum from the types schema',
        },
        'types.demand_scenario_type.enum': {
            id: 'types.demand_scenario_type.enum',
            type: 'string',
            enum: ['prototype', 'series'],
            description: 'The demand_scenario_type enum from the types schema',
        },
        'types.additional_service_target_enum.enum': {
            id: 'types.additional_service_target_enum.enum',
            type: 'string',
            enum: ['TopLevelAssembly', 'AllAssemblies'],
            description: 'The additional_service_target_enum enum from the types schema',
        },
        'types.prototype_demand_scenario_target_date_type.enum': {
            id: 'types.prototype_demand_scenario_target_date_type.enum',
            type: 'string',
            enum: ['as_soon_as_possible', 'date'],
            description: 'The prototype_demand_scenario_target_date_type enum from the types schema',
        },
        'types.bom_export_column_enum.enum': {
            id: 'types.bom_export_column_enum.enum',
            type: 'string',
            enum: [
                'designator',
                'quantity',
                'unit',
                'dnp',
                'consignment',
                'internal_part_number',
                'ipn_description',
                'customer_part_number',
                'mpn',
                'manufacturer',
                'manufacturer_free',
                'part_type',
                'description',
                'package',
                'mounting',
                'pins',
                'qualifications',
                'rohs_compliance',
                'rohs_version',
                'reach_compliance',
                'lifecycle_status',
                'lifecycle_yteol',
                'lifecycle_yteol_range',
                'notes',
                'origin',
                'country_of_origin',
                'eccn',
                'hts_code',
                'china_rohs',
                'prop65',
                'conflict_mineral_status',
                'latest_datasheet_url',
                'last_time_buy_date',
                'last_time_delivery_date',
                'svhc_cas_numbers',
                'copy_from_original_bom',
                'bom_ipn',
                'bom_cpn',
                'approval_status',
            ],
            description: 'The bom_export_column_enum enum from the types schema',
        },
        'types.pcb_sources_api_enum.enum': {
            id: 'types.pcb_sources_api_enum.enum',
            type: 'string',
            enum: ['wuerth', 'beta_layout', 'safe_pcb', 'ibr_ringler', 'apct', 'gatema', 'alba'],
            description: 'The pcb_sources_api_enum enum from the types schema',
        },
        'types.supplier_part_type_enum.enum': {
            id: 'types.supplier_part_type_enum.enum',
            type: 'string',
            enum: ['OffTheShelf', 'Pcb', 'Custom', 'Assembly'],
            description: 'The supplier_part_type_enum enum from the types schema',
        },
        'types.supplier_type_enum.enum': {
            id: 'types.supplier_type_enum.enum',
            type: 'string',
            enum: ['Distributor', 'Manufacturer', 'Ems'],
            description: 'The supplier_type_enum enum from the types schema',
        },
        'types.supplier_origin_enum.enum': {
            id: 'types.supplier_origin_enum.enum',
            type: 'string',
            enum: ['System', 'Erp', 'Manual'],
            description: 'The supplier_origin_enum enum from the types schema',
        },
        'types.user_role.enum': {
            id: 'types.user_role.enum',
            type: 'string',
            enum: ['admin', 'member'],
            description: 'The user_role enum from the types schema',
        },
        'types.index_part_task_type.enum': {
            id: 'types.index_part_task_type.enum',
            type: 'string',
            enum: ['index', 'index_used_part', 'index_used_ipn'],
            description: 'The index_part_task_type enum from the types schema',
        },
        'types.demand_type_enum.enum': {
            id: 'types.demand_type_enum.enum',
            type: 'string',
            enum: ['gross', 'net'],
            description: 'The demand_type_enum enum from the types schema',
        },
        'types.ipn_origin.enum': {
            id: 'types.ipn_origin.enum',
            type: 'string',
            enum: ['import', 'user_defined', 'manual'],
            description: 'The ipn_origin enum from the types schema',
        },
        'types.email_type_enum.enum': {
            id: 'types.email_type_enum.enum',
            type: 'string',
            enum: ['quote_request'],
            description: 'The email_type_enum enum from the types schema',
        },
        'types.year.domain': {
            id: 'pg_catalog.int4',
            title: 'types.year.domain',
            type: 'integer',
            description: 'The year domain from the types schema',
        },
    },
};

import * as r from 'runtypes';

/**
 * A Driver calculation follows this format
 * Condition: Attribute Reference, operator, Attribute reference option
 */

// Condition
export const ConditionValuesRuntype = r.Union(r.Literal('Or'), r.Literal('And'));
export type ConditionValues = r.Static<typeof ConditionValuesRuntype>;

// Attribute references
export const NumberAttributeRuntype = r.Literal('PartNumberOfPins');
export const MonetaryAttributeRuntype = r.Literal('MaterialPrice');
export const EnumAttributeReferenceRuntype = r.Union(
    r.Literal('Package'),
    r.Literal('CustomPartType'),
    r.Literal('OfferType'),
    r.Literal('PnpSide'),
    r.Literal('Packaging'),
);

// Attribute reference
export const AttributeReferenceRuntype =
    NumberAttributeRuntype.Or(MonetaryAttributeRuntype).Or(EnumAttributeReferenceRuntype);

export type AttributeReference = r.Static<typeof AttributeReferenceRuntype>;
export const AttributeReferenceForWarningsRuntype = r.Union(
    r.Literal('PartPackageMounting'),
    r.Literal('CustomPartType'),
    r.Literal('PartNumberOfPins'),
    r.Literal('PartPackageName'),
    r.Literal('PartPackage'),
);
export type AttributeReferenceForWarnings = r.Static<typeof AttributeReferenceForWarningsRuntype>;
export type DesignItemsMissingManufacturingData = Record<string, AttributeReferenceForWarnings[]>;

// Operator
export const EnumFilterOperatorRuntype = r.Union(r.Literal('Is'), r.Literal('IsNot'));
export const NumberFilterOperatorRuntype = r.Union(
    r.Literal('Lesser'),
    r.Literal('LesserOrEqual'),
    r.Literal('Equal'),
    r.Literal('NotEqual'),
    r.Literal('GreaterOrEqual'),
    r.Literal('Greater'),
);
export type EnumFilterOperator = r.Static<typeof EnumFilterOperatorRuntype>;
export const ENUM_OPERATORS = EnumFilterOperatorRuntype.alternatives.map((alternative) => alternative.value);

export type NumberFilterOperator = r.Static<typeof NumberFilterOperatorRuntype>;
export const NUMBER_OPERATORS = NumberFilterOperatorRuntype.alternatives.map((alternative) => alternative.value);

export type FilterOperator = EnumFilterOperator | NumberFilterOperator;

// Attribute reference options
export const MountingPublicRuntype = r.Union(r.Literal('SMT'), r.Literal('THT'), r.Literal('Other'));
export type MountingPublic = r.Static<typeof MountingPublicRuntype>;

export const PartTypeRuntype = r.Union(
    r.Literal('PCB'),
    r.Literal('Mechanical'),
    r.Literal('Cable'),
    r.Literal('Label'),
    r.Literal('Plastic'),
    r.Literal('Packaging'),
    r.Literal('Other'),
);
export type PartTypePublic = r.Static<typeof PartTypeRuntype>;

export const OfferTypeRuntype = r.Union(
    r.Literal('Inventory'),
    r.Literal('CustomPart'),
    r.Literal('Consigned'),
    r.Literal('Other'),
);
export type OfferTypePublic = r.Static<typeof OfferTypeRuntype>;

import { Trans } from '@lingui/macro';
import { colorSystem, StatusChip, Tooltip } from '@luminovo/design-system';
import { LifecycleEnum } from '@luminovo/http-client';

interface LifecycleLabel {
    color: keyof typeof colorSystem;
    label: JSX.Element;
    tooltip?: string | JSX.Element;
}

export const LifecycleChipWithTooltip = ({
    status,
    disabled = false,
    disabledTooltip = false,
}: {
    status: LifecycleEnum;
    disabled?: boolean;
    disabledTooltip?: boolean;
}): JSX.Element => {
    const { color, label, tooltip } = lifecycleKeyWithLabelValue[status];
    return (
        <Tooltip title={disabledTooltip ? '' : tooltip ?? ''}>
            <span>
                <StatusChip color={color} label={label} disabled={disabled} />
            </span>
        </Tooltip>
    );
};

const lifecycleKeyWithLabelValue: Record<LifecycleEnum, LifecycleLabel> = {
    [LifecycleEnum.Acquired]: { color: 'neutral', label: <Trans>Acquired</Trans> },
    [LifecycleEnum.Active]: { color: 'green', label: <Trans>Active</Trans> },
    [LifecycleEnum.Aftermarket]: { color: 'red', label: <Trans>OBS</Trans>, tooltip: <Trans>Aftermarket</Trans> },
    [LifecycleEnum.Obsolete]: { color: 'red', label: <Trans>OBS</Trans>, tooltip: <Trans>Obsolete</Trans> },
    [LifecycleEnum.EndOfLife]: { color: 'red', label: <Trans>EOL</Trans>, tooltip: <Trans>End of life</Trans> },
    [LifecycleEnum.NotRecommendedForNewDesigns]: {
        color: 'yellow',
        label: <Trans>NRND</Trans>,
        tooltip: <Trans>Not recommended for new designs</Trans>,
    },
    [LifecycleEnum.PreRelease]: { color: 'green', label: <Trans>PRE</Trans>, tooltip: <Trans>Pre-release</Trans> },
    [LifecycleEnum.Unknown]: { color: 'neutral', label: <Trans>Unknown</Trans> },
};

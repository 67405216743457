import { AuthorizationParams } from '@auth0/auth0-react';
import { BACKEND_BASE } from '@luminovo/http-client';
import { getCustomerPortalTenant } from './permissions/tenants';

/**
 * Can pass around state when routing using location's "state" attribute.
 * Define a type for all things that we pass around for better support with typescript.
 * E.g: const history = useHistory<RoutesHistoryStateProps | undefined>
 *     can then use history.location.state.fromUrl
 */
export interface RoutesHistoryStateProps {
    fromSourcing?: boolean;
    rfqId?: string;
    sourcingScenarioId?: string;
    previousUrl?: string;
}

export const ORIGIN = window.location.origin;
export const FRONTEND_APP_FRONTEND_FRIENDLY_ERROR_VERSION: 'true' | 'false' = 'false';

export const AUTH0_AUTHORIZATION_PARAMS: AuthorizationParams = {
    audience: 'https://luminovo.ai/api',
    redirect_uri: window.location.origin + '/callback', // callback configured at https://manage.auth0.com/dashboard/eu/luminovo/applications/49APfyeNsIPNBAb2nMCUnmsh4Lu6epkM/settings
    organization: getCustomerPortalTenant()?.auth0OrgId,
};

export const PART_SEARCH_MPN = BACKEND_BASE + '/parts/off-the-shelf/search/mpn';

export const NOT_FOUND_TEXT = 'Not Found';

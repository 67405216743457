import {
    defaultDropAnimationSideEffects,
    DndContext,
    DragOverlay,
    DropAnimation,
    KeyboardSensor,
    MeasuringStrategy,
    MouseSensor,
    TouchSensor,
    useDroppable,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { t } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import { PCBFileTypes } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { createPortal } from 'react-dom';
import { transEnum } from '../../../../components/localization/TransEnum';
import { FileGroupColumn, LayerGroupColumn, LayerGroupHeader } from './components/GroupColumns';
import LayersFileUpload from './components/LayersFileUpload';
import { LayerAssignmentDraggable } from './LayerAssignmentDraggable';
import { LayerAssignmentFileCard } from './LayerAssignmentFileCard';
import { LayerAssignmentFilePreview } from './LayerAssignmentFilePreview';
import { useLayerAssignment } from './useLayerAssignment';
import { GERBER_LAYERS, GerberFileTypes, gerberLayerColors } from './utils/gerberLayerUtils';
import { gerberLayerTranslations } from './utils/i18n';
import {
    ContainersWithFiles,
    PCBFileWithId,
    StateFileType,
    UpdateContainersWithFiles,
} from './utils/layerAssignmentsUtils';

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

export const LayerAssignment: React.FunctionComponent<{
    items: ContainersWithFiles;
    pcbId: string;
    isEditable: boolean;
    handleOnChange: UpdateContainersWithFiles;
    allowFileUpload: boolean;
}> = ({ items, pcbId, isEditable, handleOnChange, allowFileUpload }) => {
    // To delay the activation of drag to give room for drop down to work
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
        useSensor(KeyboardSensor, {}),
    );

    const {
        handleDragCancel,
        handleDragEnd,
        handleDragOver,
        handleOnDropDownChange,
        handleSetPreviewClick,
        collisionDetectionStrategy,
        handleDragStart,
        activeFile,
        previewFile,
        dropDownFile,
    } = useLayerAssignment({
        items,
        handleOnChange,
        isEditable,
    });

    return (
        <DndContext
            sensors={sensors}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            collisionDetection={collisionDetectionStrategy}
        >
            <Flexbox
                style={{
                    padding: '16px',
                    flexDirection: 'row',
                    gap: '16px',
                    minHeight: '100%',
                    height: 'max-content',
                }}
            >
                <LayerGroupColumn style={{ paddingInline: 0, height: 'max-content', minWidth: 'fit-content' }}>
                    <LayerGroupHeader
                        // eslint-disable-next-line spellcheck/spell-checker
                        id={'gerber-files-column-header'}
                        style={{ paddingInline: '16px' }}
                        name={t`Gerber files`}
                        description={t`Drag and drop Gerber files up and down to change it's layer. Drop it into other columns in order to change it's category.`}
                    />
                    <Flexbox height={'100%'} flexDirection={'column'} style={{ marginTop: '20px' }}>
                        {GERBER_LAYERS.map((layer) => (
                            <GerberLayer
                                disabled={!isEditable}
                                activeFile={activeFile}
                                key={layer}
                                layer={layer}
                                items={items[layer]}
                                dropDownFile={dropDownFile}
                                onDropDownChange={handleOnDropDownChange}
                                itemOnClick={(file) => handleSetPreviewClick(file)}
                                previewFile={previewFile}
                            />
                        ))}
                    </Flexbox>
                </LayerGroupColumn>

                <LayerGroupColumn
                    style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                    }}
                >
                    <Flexbox flexDirection={'column'} gap={'16px'} style={{ height: '100%' }}>
                        <FileGroupColumn>
                            <LayerGroupHeader
                                id={'mechanical-files-column-header'}
                                name={t`Mechanical files`}
                                description={t`Drag and drop Mechanical files into other columns in order to change it's category.`}
                            />
                            <Flexbox height={'100%'} flexDirection={'column'} style={{ marginTop: '16px' }}>
                                <Droppable
                                    items={items[StateFileType.MechanicalFiles]}
                                    key={StateFileType.MechanicalFiles}
                                    id={StateFileType.MechanicalFiles}
                                >
                                    <Flexbox
                                        style={{
                                            height: '100%',
                                        }}
                                        flexDirection="column"
                                        gap="8px"
                                    >
                                        {items[StateFileType.MechanicalFiles].map((file: PCBFileWithId) => (
                                            <LayerAssignmentDraggable
                                                key={file.name}
                                                id={file.name}
                                                disabled={!isEditable}
                                            >
                                                <LayerAssignmentFileCard
                                                    disabled={!isEditable}
                                                    isActive={file.id === activeFile?.id}
                                                    file={file}
                                                    onDropDownChange={handleOnDropDownChange}
                                                    openDropDown={file.id === dropDownFile?.id}
                                                    onClick={() => handleSetPreviewClick(file)}
                                                    isClicked={file.id === previewFile?.id}
                                                />
                                            </LayerAssignmentDraggable>
                                        ))}
                                    </Flexbox>
                                </Droppable>
                            </Flexbox>
                        </FileGroupColumn>
                        <FileGroupColumn>
                            <LayerGroupHeader
                                id={'stackup-files-column-header'}
                                name={t`Stackup files`}
                                description={t`Drag and drop stackup documentation files into this section. Please note that this will not allow for instant prices.`}
                            />
                            <Flexbox height={'100%'} flexDirection={'column'} style={{ marginTop: '16px' }}>
                                <Droppable
                                    items={items[StateFileType.StackupFiles]}
                                    key={StateFileType.StackupFiles}
                                    id={StateFileType.StackupFiles}
                                >
                                    <Flexbox style={{ height: '100%' }} flexDirection="column" gap="8px">
                                        {items[StateFileType.StackupFiles].map((file: PCBFileWithId) => (
                                            <LayerAssignmentDraggable
                                                key={file.name}
                                                id={file.name}
                                                disabled={!isEditable}
                                            >
                                                <LayerAssignmentFileCard
                                                    disabled={!isEditable}
                                                    isActive={file.id === activeFile?.id}
                                                    file={file}
                                                    onDropDownChange={handleOnDropDownChange}
                                                    openDropDown={file.id === dropDownFile?.id}
                                                    onClick={() => handleSetPreviewClick(file)}
                                                    isClicked={file.id === previewFile?.id}
                                                />
                                            </LayerAssignmentDraggable>
                                        ))}
                                    </Flexbox>
                                </Droppable>
                            </Flexbox>
                        </FileGroupColumn>
                    </Flexbox>
                </LayerGroupColumn>

                <LayerGroupColumn>
                    <LayerGroupHeader
                        name={t`Miscellaneous files`}
                        description={t`Drag and drop Miscellaneous files into other columns in order to change it's category.`}
                    />
                    <Flexbox height={'100%'} flexDirection={'column'} style={{ marginTop: '32px' }}>
                        <Droppable
                            items={items[StateFileType.MiscellaneousFiles]}
                            key={StateFileType.MiscellaneousFiles}
                            id={StateFileType.MiscellaneousFiles}
                        >
                            <Flexbox
                                style={{
                                    height: '100%',
                                }}
                                flexDirection="column"
                                gap="8px"
                            >
                                {items[StateFileType.MiscellaneousFiles].map((file: PCBFileWithId) => (
                                    <LayerAssignmentDraggable key={file.name} id={file.name} disabled={!isEditable}>
                                        <LayerAssignmentFileCard
                                            disabled={!isEditable}
                                            isActive={file.id === activeFile?.id}
                                            file={file}
                                            onDropDownChange={handleOnDropDownChange}
                                            openDropDown={file.id === dropDownFile?.id}
                                            onClick={() => handleSetPreviewClick(file)}
                                            isClicked={file.id === previewFile?.id}
                                        />
                                    </LayerAssignmentDraggable>
                                ))}
                            </Flexbox>
                        </Droppable>
                    </Flexbox>
                </LayerGroupColumn>

                <LayerGroupColumn
                    style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        minWidth: '380px',
                        maxWidth: '380px',
                    }}
                >
                    <Flexbox flexDirection={'column'} gap={'16px'} style={{ height: '100%' }}>
                        <FileGroupColumn style={{ flexGrow: 2 }}>
                            <LayerGroupHeader name={t`File preview`} />
                            <LayerAssignmentFilePreview file={previewFile} />
                        </FileGroupColumn>
                        <FileGroupColumn style={{ flexGrow: 1, border: 'none', background: 'none', padding: 0 }}>
                            <LayersFileUpload pcbId={pcbId} disabled={!allowFileUpload} />
                        </FileGroupColumn>
                    </Flexbox>
                </LayerGroupColumn>
            </Flexbox>

            {createPortal(
                <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
                    {activeFile && (
                        <LayerAssignmentFileCard
                            style={{ cursor: 'move' }}
                            isClicked={false}
                            isActive={false}
                            file={activeFile}
                        />
                    )}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
};

const GerberLayer: React.FunctionComponent<{
    activeFile: PCBFileWithId | null;
    disabled?: boolean;
    previewFile: PCBFileWithId | null;
    dropDownFile: PCBFileWithId | null;
    layer: GerberFileTypes;
    items: Array<PCBFileWithId>;
    onDropDownChange?: (fileType: PCBFileTypes, currentFile: PCBFileWithId) => void;
    itemOnClick?: (currentFile: PCBFileWithId) => void;
}> = ({ activeFile, previewFile, disabled = false, dropDownFile, layer, items, onDropDownChange, itemOnClick }) => {
    const layerStyle = gerberLayerColors[layer].color;

    return (
        <Box
            display={'flex'}
            style={{
                gap: '8px',
                padding: '12px 16px 13px 24px',
                minHeight: '56px',
                minWidth: '450px',
                height: '100%',
                borderWidth: '1px',
                borderColor: colorSystem.neutral[4],
                borderBottomStyle: layer !== StateFileType.PasteBottom ? `dashed` : undefined,
            }}
        >
            <span
                style={{
                    width: '8px',
                    backgroundColor: colorSystem[layerStyle][7],
                    borderRadius: '4px',
                    ...(layerStyle === 'red'
                        ? {
                              border: `1px solid ${colorSystem.neutral[5]}`,
                              background: colorSystem.neutral.white,
                          }
                        : {}),
                }}
            />
            <Flexbox flexDirection={'column'} justifyContent={'center'}>
                <Typography
                    variant="body1"
                    lang={getLocale()}
                    style={{
                        color: colorSystem.neutral[9],
                        fontWeight: '600',
                        wordBreak: 'break-word',
                        hyphens: 'auto',
                        width: '110px',
                        boxSizing: 'border-box',
                        paddingRight: '14px',
                    }}
                >
                    {transEnum(layer, gerberLayerTranslations)}
                </Typography>
            </Flexbox>
            <Droppable items={items} key={layer} id={layer}>
                <Flexbox
                    style={{
                        height: '100%',
                    }}
                    flexDirection="column"
                    gap="8px"
                >
                    {items.map((file: PCBFileWithId) => (
                        <LayerAssignmentDraggable key={file.name} id={file.name} disabled={disabled}>
                            <LayerAssignmentFileCard
                                disabled={disabled}
                                isClicked={file.id === previewFile?.id}
                                isActive={file.id === activeFile?.id}
                                file={file}
                                openDropDown={file.id === dropDownFile?.id}
                                onDropDownChange={onDropDownChange}
                                onClick={() => itemOnClick && itemOnClick(file)}
                            />
                        </LayerAssignmentDraggable>
                    ))}
                </Flexbox>
            </Droppable>
        </Box>
    );
};

const Droppable = ({
    children,
    id,
    items,
}: {
    children: React.ReactNode;
    id: string;
    items: Array<PCBFileWithId>;
}): JSX.Element => {
    const { setNodeRef } = useDroppable({
        id: id,
        data: {
            type: 'container',
            children: items,
        },
    });
    const style: React.CSSProperties = {
        height: '100%',
        minHeight: '20px',
        width: '100%',
    };

    return (
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <div ref={setNodeRef} style={style}>
                {children}
            </div>
        </SortableContext>
    );
};

import { assertPresent, isPresent } from '@luminovo/commons';
import { TableRow as MuiTableRow, TableCell } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import React from 'react';
import { TableComponents } from 'react-virtuoso';
import { Text } from '../Text';
import { TanStackTableContext } from './type';
import { getCommonPinningStyles } from './utils';

export const DefaultTableRow: TableComponents<unknown, TanStackTableContext>['TableRow'] = ({
    style,
    context,
    ...props
}) => {
    const { table, TableRow = MuiTableRow } = assertPresent(context);
    const { rows } = table.getRowModel();
    const handelRowClick = table.options.meta?.onRowClick;
    const row = rows[props['data-index']];

    const cells = row
        ?.getVisibleCells()
        .map((cell) => <InnerTableCell key={cell.id} cell={cell} sharedContext={table.options.meta?.sharedContext} />);

    return (
        <TableRow
            {...props}
            onClick={() => handelRowClick?.(row, table)}
            style={{ cursor: isPresent(handelRowClick) ? 'pointer' : 'default' }}
        >
            {cells}
        </TableRow>
    );
};

export function InnerTableCell<TData, TSharedContext>({
    cell,
    sharedContext,
}: {
    cell: Cell<TData, unknown>;
    sharedContext: TSharedContext;
}): JSX.Element {
    const context = {
        ...cell.getContext(),
        sharedContext,
        TableCellProps: {
            variant: 'body' as const,
            style: {
                ...getCommonPinningStyles(cell.column),
                textAlign: cell.column.columnDef.meta?.align,
            },
            onClick: (e: React.MouseEvent<HTMLDivElement>) => {
                if (cell.column.columnDef.meta?.enableOnRowClick === false) {
                    e.stopPropagation();
                }
            },
        },
    };

    if (cell.column.columnDef.meta?.disableTableCell) {
        return flexRender(cell.column.columnDef.cell, context) as JSX.Element;
    }

    const renderType = cell.column.columnDef.meta?.renderType ?? cell.column.columnDef.meta?.dataType ?? 'generic';

    switch (renderType) {
        case 'text':
        case 'number':
        case 'date':
        case 'monetaryValue':
            return (
                <TableCell key={cell.id} {...context.TableCellProps}>
                    <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block' }}>
                        {flexRender(cell.column.columnDef.cell, context)}
                    </Text>
                </TableCell>
            );
        case 'enum':
        case 'array':
        case 'generic':
            return (
                <TableCell key={cell.id} {...context.TableCellProps}>
                    {flexRender(cell.column.columnDef.cell, context)}
                </TableCell>
            );
    }
}

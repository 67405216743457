import { RecipientDemandSummaryDTO, SupplierDemandSummaryDTO } from '@luminovo/http-client';

export function getParticipantNumber(participantSummary: SupplierDemandSummaryDTO | RecipientDemandSummaryDTO): string {
    let participant;
    if ('supplier' in participantSummary) {
        participant = participantSummary.supplier;
    } else {
        participant = participantSummary.recipient;
    }
    return participant.kind === 'internal' ? participant.site_number : participant.supplier_number;
}

import { t, Trans } from '@lingui/macro';
import {
    assertUnreachable,
    Dictionary,
    formatDays,
    formatDecimal,
    formatMonetaryValue,
    groupBy,
    isPresent,
    transEnum,
    uniq,
    uniqBy,
} from '@luminovo/commons';
import {
    Chip,
    compareByNumber,
    createColumnHelper,
    DEFAULT_SELECT_COLUMN_ID,
    Tag as DesignSystemTag,
    Flexbox,
    Tag,
    TagGroup,
    TanStackTable,
    Text,
    TextField,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    ColumnName,
    formatCandidateCpn,
    formatColumnName,
    isCandidateCpn,
    isCustomComponentFull,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    OfferOriginEnum,
    Packaging,
    PartSpecificationTypes,
    PriceType,
    QuoteTrackingLight,
    SolutionStatus,
    SolutionTag,
    SourcingBreadcrumbItemsDTO,
} from '@luminovo/http-client';
import {
    AvailabilityChip,
    extractAccumulatedOneTimeCosts,
    extractAdjustedUnitPrice,
    extractExcessMaterial,
    extractMoq,
    extractMpq,
    extractQuoteReceivedStatus,
    extractScrapQuantity,
    extractSolutionStatus,
    extractSolutionTagColor,
    findSolutionTag,
    formatOfferOrigin,
    formatPart,
    formatQuoteReceivedStatus,
    formatSolutionStatus,
    hasSolutionTag,
    InventorySiteChip,
    isConsignedSolution,
    isStandardPartOffer,
    LabelPart,
    leadTimeDaysExtractor,
    QuantityUnitRecordSingular,
    QuoteReceivedStatusChip,
    Solution,
    SolutionErrorTags,
    SolutionNotificationTags,
    SolutionStatusInformation,
    SolutionStatusTransitionIcon,
    solutionTagTranslations,
    SolutionWarningTags,
    SupplierAndStockLocationChip,
    UnitOfMeasurementTag,
} from '@luminovo/sourcing-core';
import { Notes } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';
import { PartCategoryTag } from '../../../../components/PartCategory';
import { useCustomComponentDrawer } from '../../../../components/partSpecificationCards/CustomComponent/useCustomComponentDrawer';
import { useIpnDetailsDrawer } from '../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { formatPackaging, priceTypeTranslations } from '../../../../resources/offer/i18n';
import { colorSystem } from '../../../../themes';
import { route } from '../../../../utils/routes';
import { getQuantityUnitString } from '../../../../utils/stringFunctions';
import { OriginCell } from '../../../SolutionManager/components/columns';
import {
    ApprovedPartOptionLabel,
    SectionApprovedParts,
} from '../../../SolutionManager/components/Sidebar/SectionApprovedParts';
import { SolutionManagerCommentsButton } from '../../../SolutionManager/components/Toolbar/SolutionManagerCommentsButton';
import { ScrapDetails } from '../Formula/ScrapDetails';
import { DesignatorsPopover } from './DesignatorsPopover';
import { SolutionConfigurationsTableContext, SolutionConfigurationsTableData } from './solutionConfigurationTypes';

const StyledScrapQuantityInput = styled(TextField)({
    boxSizing: 'border-box',
});

const NotesIcon: React.FunctionComponent<{ notes: string | null | undefined }> = ({ notes }) => {
    if (!Boolean(notes) || !isPresent(notes)) {
        return <></>;
    }

    return (
        <Flexbox justifyContent={'center'}>
            <Tooltip
                variant={'white'}
                title={
                    <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Notes</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[8]}>
                            {notes}
                        </Text>
                    </Flexbox>
                }
            >
                <Notes
                    style={{
                        color: colorSystem.neutral[6],
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        borderRadius: '24px',
                        padding: '2px 8px',
                        fontSize: '16px',
                    }}
                />
            </Tooltip>
        </Flexbox>
    );
};

const RenderScrapQuantity: React.FunctionComponent<{
    data: SolutionConfigurationsTableData;
    sharedContext: SolutionConfigurationsTableContext;
}> = ({ data, sharedContext }): JSX.Element => {
    const { solutionConfigurationSourcing, solution } = data;
    const { getScrapQuantity, setScrapQuantity, isEditable } = sharedContext.scrapQuantityHandlers;

    const scrapTag = solution && findSolutionTag(solution, SolutionTag.ScrapQuantity);
    const scrapQuantity = isPresent(solution)
        ? extractScrapQuantity({ solution })
        : solutionConfigurationSourcing.scrap_quantity?.amount ?? 0;
    const scrapQuantityAmount = getScrapQuantity(solutionConfigurationSourcing.id) ?? scrapQuantity;

    const handleScrapQuantityChange = React.useCallback(
        (e) => {
            const newScrapQuantityAmount = parseFloat(e.target.value);
            setScrapQuantity(solutionConfigurationSourcing.id, newScrapQuantityAmount);
        },
        [setScrapQuantity, solutionConfigurationSourcing],
    );

    return (
        <>
            {isEditable ? (
                <StyledScrapQuantityInput
                    type="number"
                    variant="outlined"
                    value={scrapQuantityAmount}
                    onChange={handleScrapQuantityChange}
                    size="small"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            ) : (
                <Tooltip variant="white" title={scrapTag ? <ScrapDetails scrapType={scrapTag.content.type} /> : ''}>
                    <Text variant={scrapTag?.content.type.type === 'manual' ? 'body-small-semibold' : 'body-small'}>
                        {scrapQuantity ? formatDecimal(scrapQuantity) : '-'}
                    </Text>
                </Tooltip>
            )}
        </>
    );
};

function getModifedSolution(item: SolutionConfigurationsTableData): Pick<Solution, 'solutionTags'> {
    switch (item.itemType) {
        case 'marketOffer':
        case 'customPart':
        case 'inventoryOffer':
        case 'consigned':
        case 'solutionOnly':
            return item.solution;
        case 'unavailable':
            return {
                solutionTags: [{ tag: SolutionTag.Unavailable }],
            };
        case 'unknown':
            return {
                solutionTags: [{ tag: SolutionTag.NoApprovedParts }],
            };
    }
}

function extractSelectedPartOptions(item: SolutionConfigurationsTableData) {
    const { selectedPart } = item;
    if (!isPresent(selectedPart)) {
        return [];
    }

    return item.standardPartOptions.filter((part) => {
        if (isOtsFullPart(part)) {
            return part.id === selectedPart.id;
        }
        if (isGenericFullPart(part)) {
            return part.matches.some((match) => match.id === selectedPart.id);
        }
        if (isOtsComponentFull(part) && isOtsComponentFull(selectedPart)) {
            return part.id === selectedPart.id;
        }
        if (isOtsComponentFull(part)) {
            return part.matches.some((match) => match.part.data.id === selectedPart.id);
        }
        return false;
    });
}

function extractPartOptions(item: SolutionConfigurationsTableData) {
    if (item.standardPartOptions.length > 0) {
        return item.standardPartOptions.flatMap((part) => {
            if (isOtsFullPart(part)) {
                return [part];
            }
            if (isGenericFullPart(part)) {
                return part.matches;
            }
            if (isOtsComponentFull(part)) {
                return part.matches.map((match) => match.part.data);
            }

            return [];
        });
    }

    if (item.customPartOptions.length > 0) {
        return item.customPartOptions;
    }

    return [];
}

function extractFallbackSelectedPart(item: SolutionConfigurationsTableData) {
    if (isPresent(item.selectedPart)) {
        return item.selectedPart;
    }

    if (item.standardPartOptions.length === 1) {
        return item.standardPartOptions[0];
    }

    if (item.customPartOptions.length === 1) {
        return item.customPartOptions[0];
    }

    return undefined;
}

function extractBomOrigins(
    item: SolutionConfigurationsTableData,
    columnFilter: ColumnName | undefined = undefined,
): string[] {
    const specification = item.solutionConfigurationSourcing?.specification;

    // Check if it's a standard part (OffTheShelf)
    if (specification?.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }

    const origins = specification.data?.part_options?.map((option) => option.origin);

    if (!origins || origins.length === 0) {
        return [];
    }

    return uniq(
        origins.flatMap((origin) => {
            if (!isPresent(origin)) {
                return [];
            }

            const { column, candidate } = origin;

            if (!isPresent(column)) {
                return [];
            }

            const suffix = isCandidateCpn(candidate) ? formatCandidateCpn(candidate) : candidate;

            if (!isPresent(suffix)) {
                return [];
            }

            if (isPresent(columnFilter)) {
                if (columnFilter !== column) {
                    return [];
                }
                return [suffix];
            }

            return [`${formatColumnName(column)}: ${suffix}`];
        }),
    );
}

const columnHelper = createColumnHelper<SolutionConfigurationsTableData, SolutionConfigurationsTableContext>();

const columns = [
    columnHelper.text((row) => formatPart(extractFallbackSelectedPart(row)), {
        id: 'selectedPart',
        size: 170,
        label: () => t`Selected part`,
        cell: ({ row, sharedContext }) => {
            const fallbackSelectedPart = extractFallbackSelectedPart(row.original);
            if (isPresent(fallbackSelectedPart)) {
                return (
                    <Tooltip
                        variant={'white'}
                        title={
                            <Flexbox flexDirection={'column'}>
                                <SectionApprovedParts
                                    rfqId={sharedContext.rfqId}
                                    solutionConfigurationId={row.original.solutionConfigurationSourcing.id}
                                />
                            </Flexbox>
                        }
                    >
                        <span>
                            <LabelPart
                                part={fallbackSelectedPart}
                                assemblyNameForCustomPart={
                                    row.original.itemType === 'customPart' ? row.original.assemblyName : undefined
                                }
                            />
                        </span>
                    </Tooltip>
                );
            }

            const numberOfApprovedPartOptions =
                row.original.standardPartOptions.length + row.original.customPartOptions.length;

            if (numberOfApprovedPartOptions > 1) {
                return (
                    <Tooltip
                        variant={'white'}
                        title={
                            <SectionApprovedParts
                                rfqId={sharedContext.rfqId}
                                solutionConfigurationId={row.original.solutionConfigurationSourcing.id}
                            />
                        }
                    >
                        <Text
                            color={colorSystem.neutral[7]}
                        >{t`${numberOfApprovedPartOptions} approved part options`}</Text>
                    </Tooltip>
                );
            }

            return <DesignSystemTag attention={'low'} color="red" label={t`No approved parts`} />;
        },
    }),

    columnHelper.text((row) => row.designators.join(', '), {
        id: 'designators',
        size: 100,
        label: () => t`Designators`,
        cell: ({ row, sharedContext, getValue }) => {
            const groupedDesignators: Dictionary<SourcingBreadcrumbItemsDTO[]> = groupBy(
                row.original.solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items,
                (breadcrumbs) => breadcrumbs.parent_assemblies.map((assembly) => assembly.id).join(','),
            );

            return (
                <DesignatorsPopover
                    uniqueDesignators={getValue()}
                    groupedDesignators={groupedDesignators}
                    rfqId={sharedContext.rfqId}
                />
            );
        },
    }),
    columnHelper.array(
        (row) =>
            uniqBy(
                row.solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items.flatMap((d) =>
                    isPresent(d.parent_assemblies[1]) ? [d.parent_assemblies[1]] : [],
                ),
                (d) => d.id,
            ),
        {
            id: 'assembly',
            size: 200,
            initialVisibility: false,
            label: () => t`Assembly`,
            getOptionLabel: (opt) => opt.name,
            getOptionKey: (opt) => opt.id,
            enableOnRowClick: false,
            cell: ({ getValue }) => (
                <TagGroup
                    options={getValue()}
                    getOptionLabel={(opt) => opt.name}
                    limit={1}
                    onOptionClick={(opt) => {
                        window.open(
                            route('/assemblies/:assemblyId/dashboard', { assemblyId: opt.id }),
                            '_blank',
                            'noopener noreferrer',
                        );
                    }}
                />
            ),
        },
    ),
    columnHelper.number((row) => row.solutionConfigurationSourcing.aggregated_quantity.quantity, {
        id: 'quantity',
        label: () => t`Quantity`,
        size: 100,
        cell: ({ row }) => getQuantityUnitString(row.original.solutionConfigurationSourcing.aggregated_quantity),
    }),
    columnHelper.enum((row) => (isPresent(row.offer) && isStandardPartOffer(row.offer) ? row.offer.packaging : null), {
        id: 'packaging',
        label: () => t`Packaging`,
        size: 100,
        options: [...Object.values(Packaging), null],
        getOptionLabel: (opt) => formatPackaging(opt),
        cell: ({ row, getValue }) => {
            if (!isPresent(row.original.offer) || !isStandardPartOffer(row.original.offer)) {
                return '-';
            }

            if (!isPresent(getValue())) {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return <Tag color={'neutral'} attention={'low'} label={formatPackaging(getValue())} />;
        },
    }),
    columnHelper.number((row) => row.solutionConfigurationSourcing.scrap_quantity?.amount, {
        id: 'scrap-quantity',
        label: () => t`Scrap`,
        size: 80,
        cell: ({ row, sharedContext }) => <RenderScrapQuantity data={row.original} sharedContext={sharedContext} />,
    }),
    columnHelper.enum('supplierName', {
        id: 'supplier',
        label: () => t`Supplier`,
        size: 140,
        getOptionLabel: (opt) => opt,
        cell: ({ row, sharedContext }) => {
            if (row.original.itemType === 'unknown') {
                return '';
            }

            if (row.original.itemType === 'consigned') {
                return <DesignSystemTag attention={'low'} color="green" label={t`Consigned`} />;
            }

            if (row.original.itemType === 'unavailable') {
                return <DesignSystemTag attention={'low'} color="red" label={t`No offers`} />;
            }

            if (row.original.itemType === 'inventoryOffer') {
                return (
                    <InventorySiteChip
                        isPreferred={hasSolutionTag(row.original.solution, SolutionTag.SupplierPreferred)}
                        isApproved={hasSolutionTag(row.original.solution, SolutionTag.SupplierApproved)}
                        site={row.original.inventorySite}
                        displaySiteName={sharedContext.displaySiteName}
                    />
                );
            }

            if (row.original.itemType === 'solutionOnly') {
                return <Chip color="neutral" label={t`Unknown`} />;
            }

            if (row.original.itemType === 'marketOffer' || row.original.itemType === 'customPart') {
                if (!isPresent(row.original.supplierAndStockLocation)) {
                    return <Chip color="neutral" label={t`Unknown`} />;
                }

                return (
                    <SupplierAndStockLocationChip
                        isPreferred={hasSolutionTag(row.original.solution, SolutionTag.SupplierPreferred)}
                        isApproved={hasSolutionTag(row.original.solution, SolutionTag.SupplierApproved)}
                        supplier={row.original.supplierAndStockLocation}
                    />
                );
            }

            assertUnreachable(row.original);
        },
    }),
    columnHelper.number(
        (row) => {
            if (!isPresent(row.solution)) return null;
            const shippingTimeInDays = row.solution.availability?.shipping_time_in_days ?? 0;
            return leadTimeDaysExtractor(row.solution.availability) + shippingTimeInDays;
        },
        {
            id: 'lead-time',
            label: () => t`Lead time`,
            size: 100,
            cell: ({ row }) => {
                if (!isPresent(row.original.solution)) {
                    return '-';
                }

                return <AvailabilityChip solution={row.original.solution} />;
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (isPresent(row.solution) ? extractAdjustedUnitPrice({ solution: row.solution }) : null),
        {
            id: 'unit-price',
            label: () => t`Unit price`,
            size: 100,
            formatAs: 'unit-price',
            cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' }),
        },
    ),
    columnHelper.monetaryValue(
        (row) => (isPresent(row.solution) ? extractExcessMaterial({ solution: row.solution }) : null),
        {
            id: 'excess',
            label: () => t`Excess`,
            size: 100,
            cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '' }),
        },
    ),
    columnHelper.monetaryValue('solution.totalPrice.effective_total_price', {
        id: 'total-price',
        label: () => t`Total price`,
        size: 100,
        cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '' }),
    }),

    columnHelper.enum(
        ({ solution }) => {
            if (
                isPresent(solution) &&
                hasSolutionTag(solution, SolutionTag.Selected) &&
                !isConsignedSolution(solution)
            ) {
                return SolutionTag.Selected;
            }
            if (
                isPresent(solution) &&
                hasSolutionTag(solution, SolutionTag.AutoSelected) &&
                !isConsignedSolution(solution)
            ) {
                return SolutionTag.AutoSelected;
            }
            return null;
        },
        {
            id: 'Selection',
            label: () => t`Selection`,
            size: 100,
            getOptionLabel: (opt) => {
                switch (opt) {
                    case SolutionTag.Selected:
                        return t`Manual`;
                    case SolutionTag.AutoSelected:
                        return t`Automatic`;
                    case null:
                        return t`Unknown`;
                }
            },
            cell: (item) => {
                switch (item.getValue()) {
                    case SolutionTag.Selected:
                        return <Tag color="neutral" label={t`Manual`} attention="low" />;
                    case SolutionTag.AutoSelected:
                        return <Tag color="neutral" label={t`Automatic`} attention="low" />;
                    case null:
                        return '-';
                }
            },
        },
    ),

    columnHelper.enum((row) => extractQuoteReceivedStatus(row.quotes).state, {
        id: 'quoteReceived',
        label: () => t`Quote received`,
        size: 130,
        getOptionLabel: (opt) => formatQuoteReceivedStatus(opt),
        cell: ({ row, sharedContext }) => (
            <QuoteReceivedStatusChip
                quotes={row.original.quotes}
                supplierAndStockLocations={sharedContext.supplierAndStockLocations}
                onQuoteTrackingClick={(quote: QuoteTrackingLight) => {
                    sharedContext.history.push(
                        route(
                            '/rfqs/:rfqId/sourcing/quote-tracking',
                            {
                                rfqId: quote.rfq,
                            },
                            {
                                quoteTrackingId: quote.id,
                            },
                        ),
                    );
                }}
            />
        ),
    }),
    columnHelper.enum((row) => extractSolutionStatus(getModifedSolution(row), 'effective'), {
        id: 'status',
        label: () => t`Status`,
        size: 80,
        align: 'center',
        initialPinning: 'right',
        enableHiding: false,
        options: Object.values(SolutionStatus),
        getOptionLabel: (opt) => formatSolutionStatus(opt),
        cell: ({ row }) => (
            <Tooltip variant="white" title={<SolutionStatusInformation solution={getModifedSolution(row.original)} />}>
                <span>
                    <SolutionStatusTransitionIcon solution={getModifedSolution(row.original)} />
                </span>
            </Tooltip>
        ),
        quickFilters: [
            {
                label: () => t`Only warnings and errors`,
                value: [SolutionStatus.Warning, SolutionStatus.Error],
            },
        ],
    }),
    columnHelper.array((row) => getModifedSolution(row).solutionTags.map((t) => t.tag), {
        id: 'solutionTags',
        label: () => t`Solution tags`,
        size: 100,
        enableHiding: false,
        enableGlobalFilter: false,
        initialVisibility: false,
        options: ({ facetedValues }) =>
            [
                SolutionTag.Inventory,
                SolutionTag.SupplierPreferred,
                SolutionTag.SupplierApproved,
                ...SolutionErrorTags,
                ...SolutionWarningTags,
                ...SolutionNotificationTags,
            ].filter((tag) => facetedValues.includes(tag)),
        renderOption: (tag) => (
            <Chip
                color={extractSolutionTagColor({ solutionTag: tag })}
                label={transEnum(tag, solutionTagTranslations)}
            />
        ),
        getOptionLabel: (tag) => transEnum(tag, solutionTagTranslations),
        cell: () => {
            throw new Error('Not implemented');
        },
    }),
    columnHelper.text(
        (row) =>
            extractPartOptions(row)
                .map((part) => formatPart(part))
                .join(' '),
        {
            id: 'approved parts',
            label: () => t`Approved parts`,
            size: 150,
            enableHiding: false,
            initialVisibility: false,
            cell: () => {
                throw new Error('Not implemented');
            },
        },
    ),
    columnHelper.text(
        (row) =>
            [...row.standardPartOptions, ...row.customPartOptions]
                .flatMap((part) => (isOtsComponentFull(part) || isCustomComponentFull(part) ? [part] : []))
                .map((part) => formatPart(part))
                .join(', '),
        {
            id: 'approvedIpns',
            label: () => t`Approved IPNs`,
            size: 150,
            initialVisibility: false,
            enableOnRowClick: false,
            cell: function Cell({ row, sharedContext }) {
                const { openDrawer: openOtsComponent } = useIpnDetailsDrawer();
                const { openDrawer: openCustomComponent } = useCustomComponentDrawer();

                return (
                    <TagGroup
                        options={[...row.original.standardPartOptions, ...row.original.customPartOptions].flatMap(
                            (part) => (isOtsComponentFull(part) || isCustomComponentFull(part) ? [part] : []),
                        )}
                        getOptionLabel={(part) => part.id}
                        limit={1}
                        onOptionClick={(part) => {
                            if (isOtsComponentFull(part)) {
                                openOtsComponent({
                                    ipnId: part.id,
                                    rfqContext: { type: 'WithinRfQ', rfq_id: sharedContext.rfqId },
                                });
                            } else if (isCustomComponentFull(part)) {
                                openCustomComponent({
                                    componentId: part.id,
                                    rfqContext: { type: 'WithinRfQ', rfq_id: sharedContext.rfqId },
                                });
                            }
                        }}
                    />
                );
            },
        },
    ),
    columnHelper.text(
        (row) =>
            [...row.standardPartOptions, ...row.customPartOptions]
                .flatMap((part) => (isOtsComponentFull(part) || isCustomComponentFull(part) ? part.cpns : []))
                .map((cpn) => cpn.value)
                .join(', '),
        {
            id: 'approvedCpns',
            label: () => t`Approved CPNs`,
            size: 150,
            initialVisibility: false,
            cell: function Cell({ row }) {
                return (
                    <TagGroup
                        options={[...row.original.standardPartOptions, ...row.original.customPartOptions].flatMap(
                            (part) => (isOtsComponentFull(part) || isCustomComponentFull(part) ? part.cpns : []),
                        )}
                        getOptionLabel={(cpn) => cpn.value}
                        limit={1}
                    />
                );
            },
        },
    ),

    columnHelper.text((row) => extractBomOrigins(row, ColumnName.IPN).join(', '), {
        id: 'bomIPNs',
        label: () => t`BOM IPNs`,
        size: 200,
        initialVisibility: false,
        cell: ({ row }) => (
            <TagGroup
                options={extractBomOrigins(row.original, ColumnName.IPN)}
                getOptionLabel={(opt) => opt}
                limit={1}
            />
        ),
    }),

    columnHelper.text((row) => extractBomOrigins(row, ColumnName.CPN).join(', '), {
        id: 'bomCPNs',
        label: () => t`BOM CPNs`,
        size: 200,
        initialVisibility: false,
        cell: ({ row }) => (
            <TagGroup
                options={extractBomOrigins(row.original, ColumnName.CPN)}
                getOptionLabel={(opt) => opt}
                limit={1}
            />
        ),
    }),

    columnHelper.text((row) => extractBomOrigins(row).join(', '), {
        id: 'bomOrigins',
        label: () => t`BOM origins`,
        size: 200,
        initialVisibility: false,
        cell: ({ row }) => (
            <TagGroup options={extractBomOrigins(row.original)} getOptionLabel={(opt) => opt} limit={1} />
        ),
    }),

    columnHelper.text(
        (row) =>
            extractSelectedPartOptions(row)
                .map((part) => formatPart(part))
                .join(' '),
        {
            id: 'selectedPartOptions',
            label: () => t`Part option`,
            description: () => t`The origin part option of the selected part`,
            size: 150,
            renderType: 'generic',
            initialVisibility: false,
            cell: ({ row, sharedContext }) => {
                const selectedPartOptions = extractSelectedPartOptions(row.original);
                if (selectedPartOptions.length === 0) {
                    return t`Unknown`;
                }

                return (
                    <Tooltip
                        variant={'white'}
                        title={
                            <Flexbox flexDirection={'column'} gap={4}>
                                <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                                    {selectedPartOptions.map((p) => (
                                        <ApprovedPartOptionLabel key={p.id} rfqId={sharedContext.rfqId} part={p} />
                                    ))}
                                </ul>
                            </Flexbox>
                        }
                    >
                        <span>
                            <Flexbox gap={4}>
                                <span
                                    style={{
                                        overflow: 'hidden',
                                        overflowWrap: 'break-word',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    <LabelPart part={selectedPartOptions[0]} />
                                </span>
                                {selectedPartOptions.length > 1 && (
                                    <Text color={colorSystem.neutral[7]}>{`+${selectedPartOptions.length - 1}`}</Text>
                                )}
                            </Flexbox>
                        </span>
                    </Tooltip>
                );
            },
        },
    ),
    columnHelper.array((row) => row.partCategories.map((partCategory) => partCategory), {
        id: 'part-category',
        label: () => t`Part category`,
        size: 150,
        initialVisibility: false,
        enableGlobalFilter: false,
        options: ({ facetedValues }) => Array.from(facetedValues).sort(compareByNumber((a) => a.depth)),
        getOptionLabel: (opt) => opt.name,
        cell: ({ row }) => {
            // Improvement: Consider using extractFallbackSelectedPart during data creation to eliminate this check and to utilize it in the filters.
            const fallbackSelectedPart = extractFallbackSelectedPart(row.original);
            if (
                !isPresent(fallbackSelectedPart) ||
                !isOtsFullPart(fallbackSelectedPart) ||
                !isPresent(fallbackSelectedPart.part_category)
            ) {
                return '-';
            }

            return <PartCategoryTag partCategory={fallbackSelectedPart.part_category.part_category} />;
        },
    }),
    columnHelper.text('offer.supplier_part_number', {
        id: 'supplierPartNumber',
        label: () => t`SKU`,
        size: 100,
        initialVisibility: false,
        cell: (info) => info.getValue() ?? '-',
    }),
    columnHelper.number((row) => extractMoq(row), {
        id: 'moq',
        label: () => t`MOQ`,
        size: 60,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number((row) => extractMpq(row), {
        id: 'mpq',
        label: () => t`MPQ`,
        size: 60,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.enum((row) => row.offer?.origin.origin, {
        id: 'origin',
        size: 130,
        label: () => t`Origin`,
        enableOnRowClick: false,
        initialVisibility: false,
        options: ({ facetedValues }) => [OfferOriginEnum.Manual, ...facetedValues],
        getOptionLabel: (origin) => (isPresent(origin) ? formatOfferOrigin({ origin }) : t`Unknown`),
        cell: ({ row }) =>
            isPresent(row.original.offer) ? <OriginCell key={Math.random()} offer={row.original.offer} /> : <></>,
    }),
    columnHelper.monetaryValue('lineValue', {
        id: 'line-value',
        label: () => t`Line value`,
        size: 100,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '' }),
    }),
    columnHelper.enum((row) => extractAdjustedUnitPrice(row, 'scaled', 'original')?.currency, {
        id: 'currency',
        label: () => t`Currency`,
        size: 90,
        initialVisibility: false,
        getOptionLabel: (opt) => opt ?? t`Unknown`,
        cell: (item) => {
            const currency = item.getValue();
            if (!currency) {
                return '-';
            }
            return <Tag color={'neutral'} attention={'low'} label={currency} />;
        },
    }),
    columnHelper.enum('offer.price_type', {
        id: 'priceType',
        label: () => t`Price type`,
        size: 90,
        initialVisibility: false,
        options: Object.values(PriceType),
        getOptionLabel: (option) => (option ? transEnum(option, priceTypeTranslations) : t`Unknown`),
        cell: (item) => (isPresent(item.getValue()) ? transEnum(item.getValue(), priceTypeTranslations) : '-'),
        quickFilters: [
            {
                label: () => t`Only inventory offers`,
                value: [PriceType.PurchasePrice, PriceType.StandardPrice],
            },
        ],
    }),
    columnHelper.number('offer.unit_of_measurement.quantity_unit.quantity', {
        id: 'unitOfMeasurement',
        label: () => t`Unit of measurement`,
        size: 90,
        initialVisibility: false,
        renderType: 'generic',
        cell: ({ row }) =>
            isPresent(row.original.offer) ? (
                <UnitOfMeasurementTag unitOfMeasurement={row.original.offer.unit_of_measurement} />
            ) : (
                <></>
            ),
    }),
    columnHelper.enum((row) => row.solutionConfigurationSourcing.aggregated_quantity.unit, {
        id: 'unit',
        label: () => t`Unit`,
        size: 90,
        initialVisibility: false,
        enableHiding: false,
        renderType: 'generic',
        getOptionLabel: (opt) => transEnum(opt, QuantityUnitRecordSingular),
        cell: (item) => transEnum(item.getValue(), QuantityUnitRecordSingular),
    }),
    columnHelper.number(
        (row) =>
            isPresent(row.offer) && isStandardPartOffer(row.offer)
                ? row.offer.available_prices.factory_lead_time_days
                : null,
        {
            id: 'factoryLeadTime',
            label: () => t`Standard factory lead time`,
            size: 90,
            initialVisibility: false,
            cell: (item) => formatDays(item.getValue()),
        },
    ),
    columnHelper.monetaryValue((row) => extractAdjustedUnitPrice(row, 'scaled', 'original'), {
        id: 'unitPriceOriginalCurrency',
        label: () => t`Unit price (original currency)`,
        size: 130,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue('solution.totalPrice.original_currency_total_price', {
        id: 'totalPriceOriginalCurrency',
        label: () => t`Total price (original currency)`,
        size: 160,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue(
        (row) => extractAccumulatedOneTimeCosts({ solution: row.solution, includeManualCosts: true }),
        {
            id: 'oneTimeCost',
            label: () => t`One-time costs`,
            size: 120,
            initialVisibility: false,
            cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '-' }),
        },
    ),
    columnHelper.text('solutionConfigurationSourcing.notes', {
        id: 'notes',
        label: () => t`Notes`,
        size: 60,
        initialVisibility: false,
        enableGlobalFilter: false,
        cell: (item) => <NotesIcon notes={item.getValue()} />,
    }),
    columnHelper.generic(() => null, {
        id: 'comments',
        label: () => t`Comments`,
        size: 100,
        initialVisibility: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableOnRowClick: false,
        align: 'center',
        cell: ({ row, sharedContext }) => (
            <SolutionManagerCommentsButton
                rfqId={sharedContext.rfqId}
                solutionConfigurationSourcing={row.original.solutionConfigurationSourcing}
                size={'small'}
                iconButtonOnly={true}
            />
        ),
    }),
];

export function useSolutionConfigurationTableState({
    rfqId,
    sourcingScenarioId,
    data,
    sharedContext,
}: {
    rfqId: string;
    sourcingScenarioId: string;
    data: SolutionConfigurationsTableData[] | undefined;
    sharedContext: SolutionConfigurationsTableContext;
}) {
    return useTanStackTable({
        columns,
        data,
        sharedContext,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enablePersistentRowSelection: `sourcingTable:${sourcingScenarioId}`,
        enableSaveAsDefault: `rfq:${rfqId}`,
        enablePersistentScrollPosition: true,
        enableSelection: true,
        initialState: {
            columnPinning: {
                left: [DEFAULT_SELECT_COLUMN_ID],
                right: ['status'],
            },
        },
        onRowClick: (row) => {
            if (sharedContext.scrapQuantityHandlers.isEditable) {
                return;
            }
            sharedContext.history.push(
                route('/rfqs/:rfqId/sourcing/:solutionConfigurationId', {
                    rfqId: sharedContext.rfqId,
                    solutionConfigurationId: row.original.solutionConfigurationSourcing.id,
                }),
            );
        },
    });
}

export function SolutionConfigurationMiniTable({
    data,
    sharedContext,
}: {
    data: SolutionConfigurationsTableData[] | undefined;
    sharedContext: SolutionConfigurationsTableContext;
}) {
    const { table } = useTanStackTable({
        columns: columns.filter((column) =>
            ['selectedPart', 'supplier', 'designators', 'unit-price', 'total-price', 'status'].includes(
                column.id ?? '',
            ),
        ),
        data,
        sharedContext,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        onRowClick: (row) => {
            sharedContext.history.push(
                route('/rfqs/:rfqId/sourcing/:solutionConfigurationId', {
                    rfqId: sharedContext.rfqId,
                    solutionConfigurationId: row.original.solutionConfigurationSourcing.id,
                }),
            );
        },
    });

    return <TanStackTable table={table} size={'small'} />;
}
